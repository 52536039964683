import * as yup from "yup";

export function CreateProjectYupValidation() {
  return yup
    .object({
      solution: yup.string().required("required").typeError("required"),
      projectName: yup.string().required("required").typeError("required"),
      technology: yup.string().required("required").typeError("required"),
      environment: yup.string().required("required").typeError("required"),
      paymentEngineTypes: yup
        .array()
        .min(1)
        .required("required")
        .typeError("required"),
      ecronTerminal: yup.bool().required("required").typeError("required"),
      segments: yup.array().min(1).required("required").typeError("required"),
      terminals: yup.array().min(1).required("required").typeError("required"),
      integrationType: yup.string().required("required").typeError("required"),
    })
    .required();
}
