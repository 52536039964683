export class OidcClientLogger {
  source;
  logging = true;

  debug(message, ...params) {
    if (this.logging) console.debug(this.formatMessage("DBG", message), ...params)
  }

  info(message, ...params) {
    if (this.logging) console.info(this.formatMessage("INF", message), ...params)
  }

  warn(message, ...params) {
    if (this.logging) console.warn(this.formatMessage("WRN", message), ...params)
  }

  error(message, ...params) {
    if (this.logging) console.error(this.formatMessage("ERR", message), ...params)
  }

  formatMessage(level, message) {
    return `[${this.source}] ${level}: ${message}`
  }
}
