import * as yup from "yup";
import FormatService from "../services/FormatService";

export function UpdateIncompletePermissionsYupValidation(permissions) {
  let schema = {};
  permissions?.forEach((permission) => {
    if (permission.name) {
      schema[FormatService.getPermissionValidationKey(permission.name)] = yup
        .string()
        .default(permission.reason || "")
        .required("required")
        .typeError("required");
    }
  });

  return yup.object(schema).required();
}
