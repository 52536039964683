import React from "react";
import Selector from "../../form/Selector";
import { CcvText } from "@ccv-oc-myccv/ccv-react-components";

function EditableSelect({
  selectInfo,
  translate,
  formControl,
  isCustomInput,
  editActive,
}) {
  return editActive && selectInfo.data ? (
    <Selector
      testId={selectInfo.name}
      registerName={selectInfo.name}
      options={selectInfo.options}
      initialSelectedItem={{
        id: selectInfo.data,
        text: isCustomInput(selectInfo.data),
      }}
      setSelectedResult={selectInfo.setValue}
      isOtherOptionSelected={selectInfo.data === "OTHER"}
      maxLength={selectInfo.maxLength}
      translate={translate}
      control={formControl}
    />
  ) : (
    <CcvText
      size="medium"
      color="dark-grey"
      testId={
        selectInfo.initialValue +
        (selectInfo.initialValue?.includes("_OTHER") && "option_other")
      }
    >
      {isCustomInput(selectInfo.initialValue)}
    </CcvText>
  );
}

export default EditableSelect;
