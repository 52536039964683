import React from "react";
import { CcvText } from "@ccv-oc-myccv/ccv-react-components";
import FormValidation from "../../domain/FormValidation";
import ValidationError from "../form/ValidationError";
import { ShowForRole } from "../ShowForRole";
import TextArea from "../form/Textarea";
import { Controller } from "react-hook-form";

function SecurityscanFeedbackResponseArea({
  hasLastFeedbackResponse,
  releaseActiveStatus,
  translate,
  setValue,
  isResponseAreaOpen,
  control,
}) {
  function isResponseInputFieldRequired() {
    return (
      isResponseAreaOpen ||
      (!hasLastFeedbackResponse &&
        ["SECURITYSCAN_MORE_INFO"]?.includes(releaseActiveStatus))
    );
  }

  function renderResponseInputField() {
    return (
      <ShowForRole permission="Developer">
        {isResponseInputFieldRequired() && (
          <div className="feedback-response-container">
            <CcvText
              size="medium"
              color="dark-grey"
              testId={"feedback_response_label"}
            >
              {translate("FEEDBACK_RESPONSE_LABEL")}
            </CcvText>
            <Controller
              control={control}
              name="feedbackResponse"
              render={({ field: { onChange, value } }) => (
                <TextArea
                  translate={translate}
                  field="feedbackResponse"
                  testId={"feedback_response"}
                  className="textarea-small"
                  classNameBlock={"no-spacing"}
                  maxLength={FormValidation.MAX_FEEDBACK_LENGTH_MEDIUM}
                  setValue={setValue}
                  register={onChange}
                  defaultInputData={value}
                />
              )}
            />
          </div>
        )}
      </ShowForRole>
    );
  }
  return renderResponseInputField();
}

export default SecurityscanFeedbackResponseArea;
