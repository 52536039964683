import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../config/AppContext";
import Role from "../domain/Role";

function ShowForRoleComponent(props) {
  const { authService } = useContext(AppContext);
  const [roles, setRoles] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    authService.getRoles().then((response) => {
      setRoles(response);
    });
  }, []);

  function couldShow() {
    if (roles && roles.includes(Role[props.permission])) {
      return props.children ? props.children : <></>;
    } else {
      if (props.redirect) {
        navigate(props.redirect);
      }
      return null;
    }
  }
  return couldShow();
}

ShowForRoleComponent.propTypes = {
  permission: PropTypes.string.isRequired,
};

export const ShowForRole = ShowForRoleComponent;
