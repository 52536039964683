import { CcvButton, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import { DialogActions, DialogContent } from "@mui/material";
import React, { useEffect } from "react";

function UploadedToStoreBody({
  translate,
  register,
  disabled,
  submit,
  onDisagree,
  setValue,
}) {
  useEffect(() => {
    setValue("status", "AVAILABLE_IN_STORE");
  }, []);

  return (
    <>
      <DialogContent>
        <form></form>
      </DialogContent>
      <DialogActions>
        <CcvButton
          type="tertiary"
          size="normal"
          text={translate("CANCEL_LABEL")}
          onClick={onDisagree}
          disabled={disabled}
          testId="cancel_status_update_button"
        />
        <CcvButton
          type="primary"
          size="normal"
          text={translate("SAVE_LABEL")}
          onClick={submit}
          disabled={disabled}
          testId="update_status_button"
        />
      </DialogActions>
    </>
  );
}

export default UploadedToStoreBody;
