import React, { useContext, useEffect, useState } from "react";
import SmartTable from "../../components/tables/Smarttable/SmartTable";
import { AppContext } from "../../config/AppContext";
import { ReleaseService } from "../../services/ReleaseService";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import {
  CcvButton,
  CcvHeading,
  CcvMessage,
} from "@ccv-oc-myccv/ccv-react-components";
import EmptyIconBlock from "../../components/emptyBlocks/EmptyIconBlock";
import emptyStateImage from "../../assets/images/empty-actions.svg";
import { useTranslation } from "react-i18next";
import SpinnerService from "../../services/SpinnerService";
import FormatService from "../../services/FormatService";
import { jsonToCSV } from "react-papaparse";
import multiDownload from "multi-download";

function TracebillityComponent(props) {
  const [t] = useTranslation();
  const { setTitle, authService } = useContext(AppContext);
  const { promiseInProgress } = usePromiseTracker({ area: "traceability" });
  let releaseService = new ReleaseService(authService);
  const [traceabilityData, setTraceabilityData] = useState();

  function getTraceAbillityData() {
    return trackPromise(
      SpinnerService.errorSpinner(
        releaseService
          .getTraceabillityList()
          .then((response) => {
            setTraceabilityData(response.data);
          })
          .catch((error) => {
            throw error;
          }),
        t
      ),
      "traceability"
    );
  }

  function formatExportData() {
    return traceabilityData.map((row) => ({
      ...row,
      functionalTestingDate: FormatService.getDateWithTimeFormat(
        row.functionalTestingDate
      ),
      securityScanDate: FormatService.getDateWithTimeFormat(
        row.securityScanDate
      ),
      signingDate: FormatService.getDateWithTimeFormat(row.signingDate),
      uploadToStoreDate: FormatService.getDateWithTimeFormat(
        row.uploadToStoreDate
      ),
      creationDate: FormatService.getDateWithTimeFormat(row.creationDate),
    }));
  }

  function exportToCSV() {
    const blob = new Blob([jsonToCSV(formatExportData())], {
      type: "text/csv;charset=utf-8;",
    });
    multiDownload([URL.createObjectURL(blob)], {
      rename: ({ url, index, urls }) =>
        "traceability_report_" +
        FormatService.replaceSpecialChar(
          FormatService.getMediumDateFormat(Date.now(), "nl-NL")
        )?.toLowerCase() +
        ".csv",
    });
  }

  useEffect(() => {
    setTitle(t(props.title));
    getTraceAbillityData();
  }, []);

  function isTraceabilityUnavailableOrEmpty() {
    return (
      !promiseInProgress && (!traceabilityData || traceabilityData.length === 0)
    );
  }

  return (
    <div className="table-block">
      {isTraceabilityUnavailableOrEmpty() ? (
        <div className={"info-block "}>
          <CcvHeading
            text={t("TRACEABILITY_TITLE")}
            size="small"
            testId={"empty_traceability"}
          />
          <EmptyIconBlock
            image={emptyStateImage}
            emptyText={"EMPTY_TRACEABILITY_LABEL"}
            translate={t}
          />
        </div>
      ) : (
        <>
          <div
            className="btn-block floating_button"
            data-testid="btnCreateProject"
          >
            <CcvButton
              testId="export_csv_button"
              text={t("Exporteer naar excel")}
              icon="file_upload"
              type="secondary"
              size="normal"
              disabled={promiseInProgress}
              onClick={() => exportToCSV()}
            />
          </div>
          <div className="message-block progress-block">
            <CcvMessage
              text={t("TRACEBILLITY_INFO_MESSAGE")}
              type="info"
              testId="create_release_message"
            />
          </div>
          <div className="info-block">
            <SmartTable
              data={traceabilityData}
              promiseArea={"traceability"}
              title={""}
              filterFields={[
                "creationDate",
                "projectName",
                "bupaName",
                "revisionNumber",
                "functionalTestingUser",
                "securityScanUser",
                "signingUser",
                "releaseStatus",
              ]}
              isNavigationDisabled={true}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default TracebillityComponent;
