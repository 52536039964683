import { CcvButton, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React, { useEffect, useState } from "react";
import SpinnerService from "../../../../../services/SpinnerService";
import { SignerService } from "../../../../../services/SignerService";
import FailedBody from "./FailedBody";
import SuccessBody from "./SuccessBody";
import { DialogActions, DialogContent } from "@mui/material";
import RadioButtonBody from "../RadioButtonBody";
import ReleaseStatus from "../../../../../domain/ReleaseStatus";

function SignedBody({
  translate,
  register,
  setValue,
  authService,
  setDialogTitle,
  dialogTitle,
  control,
  onDisagree,
  disabled,
  submit,
  validate,
  resetForm,
  isValid,
}) {
  const [signerGroups, setSignerGroups] = useState();
  const [signingStatus, setSigningStatus] = useState();
  const [isLastStep, setIsLastStep] = useState(false);
  const [isContractSigned, setIsContractSigned] = useState(false);
  let signerService = new SignerService(authService);

  async function fetchSignerGroups() {
    return SpinnerService.errorSpinner(
      signerService
        .getSignersByGroup()
        .then((response) => setSignerGroups(response.data)),
      translate
    );
  }

  useEffect(() => {
    fetchSignerGroups();
  }, []);

  useEffect(() => {
    setDialogTitle(
      dialogTitle +
        " " +
        translate(ReleaseStatus[signingStatus]?.name).toLowerCase() +
        " (" +
        translate("STEP_LABEL") +
        (signingStatus ? " 2" : " 1") +
        "/2)"
    );
    !isLastStep && !signingStatus && resetForm();
    !isLastStep && setIsContractSigned(false);
  }, [isLastStep]);

  return (
    <>
      <DialogContent>
        <form className="dialog-body-fit dialog-body">
          {!isLastStep && (
            <RadioButtonBody
              setValue={setSigningStatus}
              translate={translate}
              name={"status"}
              control={control}
              selectedOption={signingStatus}
              required={true}
              options={[
                {
                  id: "success",
                  value: "SIGNING_SUCCESS",
                  text: "SUCCESS_TEXT",
                  icon: "done",
                  color: "green",
                  testid: "signing_success_option",
                },
                {
                  id: "failed",
                  value: "SIGNING_FAILED",
                  text: "FAILED_TEXT",
                  tooltipText: "SIGNING_FAILED_INFO_LABEL",
                  icon: "clear",
                  color: "red",
                  testid: "signing_failed_option",
                },
              ]}
              title={"RESULT_LABEL"}
            />
          )}
          {isLastStep &&
            ("SIGNING_SUCCESS" === signingStatus ? (
              <SuccessBody
                translate={translate}
                control={control}
                setValue={setValue}
                register={register}
                signerGroups={signerGroups}
                validate={validate}
                setIsContractSigned={setIsContractSigned}
                isContractSigned={isContractSigned}
              />
            ) : (
              <FailedBody
                translate={translate}
                setValue={setValue}
                validate={validate}
              />
            ))}
        </form>
      </DialogContent>
      <DialogActions>
        <CcvButton
          type="tertiary"
          size="normal"
          text={isLastStep ? translate("BACK") : translate("CANCEL_LABEL")}
          onClick={
            !isLastStep
              ? onDisagree
              : () => {
                  setIsLastStep(false);
                }
          }
          disabled={disabled}
          testId="cancel_status_update_button"
        />
        <CcvButton
          type="primary"
          size="normal"
          text={
            isLastStep
              ? translate("RELEASESTATUS_" + ReleaseStatus[signingStatus]?.key)
              : translate("NEXT")
          }
          onClick={isLastStep ? submit : () => setIsLastStep(true)}
          disabled={disabled || !signingStatus || (isLastStep && !isValid)}
          testId={isLastStep ? "update_status_button" : "next_step_button"}
        />
      </DialogActions>
    </>
  );
}

export default SignedBody;
