import axios from "axios";
import { useContext } from "react";
import { AppContext } from "../config/AppContext";

const PROJECT_REST_API_URL = process.env.REACT_APP_BACKEND_HOST + "/project";

export class ProjectService {
  authService;

  constructor(authService) {
    this.authService = authService;
  }

  getProjects() {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(PROJECT_REST_API_URL, {
          headers: { Authorization: token },
        });
      });
  }

  createProject(data) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.post(PROJECT_REST_API_URL, data, {
          headers: { Authorization: token },
        });
      });
  }

  getProjectHistory(projectId) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(PROJECT_REST_API_URL + "/" + projectId + "/history", {
          headers: { Authorization: token },
        });
      });
  }

  getProjectById(projectId) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(PROJECT_REST_API_URL + "/" + projectId, {
          headers: { Authorization: token },
        });
      });
  }

  getProjectByIdAndBupaId(projectId, bupaId) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(PROJECT_REST_API_URL + "/" + projectId, {
          params: { bupaId: bupaId },
          headers: { Authorization: token },
        });
      });
  }

  getProjectsByBupaId(bupaId) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(PROJECT_REST_API_URL, {
          params: { bupaId: bupaId },
          headers: { Authorization: token },
        });
      });
  }

  updateProjectinfo(projectId, data) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.patch(
          PROJECT_REST_API_URL + "/" + projectId + "/projectInfo",
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        );
      });
  }

  getDownloadLink(projectId, fileType, filename) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(PROJECT_REST_API_URL + "/downloadLink", {
          params: {
            projectId: projectId,
            filename: filename,
            fileType: fileType,
          },
          headers: {
            Authorization: token,
          },
        });
      });
  }

  getDocumentationDownloadLink(filename) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(PROJECT_REST_API_URL + "/documentationLink", {
          params: {
            filename: filename,
          },
          headers: {
            Authorization: token,
          },
        });
      });
  }
}
