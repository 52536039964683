import { CcvText } from "@ccv-oc-myccv/ccv-react-components";

function FormatAndSignatureBody({ translate }) {
  return (
    <>
      <CcvText size="medium" color="medium-grey">
        <span>
          {translate("FORMAT_AND_SIGNATURE_TEXT_1")}&nbsp;
          <a
            className="catalogue-link"
            href="https://lessons.livecode.com/m/4069/l/32674-how-do-i-create-a-self-signed-certificate-for-an-android-app"
            target="_blank"
          >
            {translate("FORMAT_AND_SIGNATURE_LINK")}
          </a>
          .
        </span>
      </CcvText>
      <CcvText size="medium" color="medium-grey">
        {translate("FORMAT_AND_SIGNATURE_TEXT_2")}
      </CcvText>
      <CcvText size="medium" color="medium-grey">
        {translate("FORMAT_AND_SIGNATURE_TEXT_3")}
      </CcvText>
    </>
  );
}
export default FormatAndSignatureBody;
