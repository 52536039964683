import {
  CcvHeading,
  CcvMaterialIcon,
  CcvText,
  CcvButton,
} from "@ccv-oc-myccv/ccv-react-components";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogActions, DialogTitle, Icon } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { AppContext } from "../../config/AppContext";
import FormatService from "../../services/FormatService";
import { ReleaseService } from "../../services/ReleaseService";
import SpinnerService from "../../services/SpinnerService";
import "../../style/Dialog.scss";
import "../../style/Labels.scss";
import AssignToMeBody from "./DialogBodies/updateBodies/AssignToMeBody";
import UploadedToStoreBody from "./DialogBodies/updateBodies/UploadedToStoreBody";
import FunctionalTestingBody from "./DialogBodies/updateBodies/functionalTestingBodies/FunctionalTestingBody";
import SignedBody from "./DialogBodies/updateBodies/signingBodies/SignedBody";
import DownloadTypes from "../../domain/DownloadType";
import { FunctionalTestingYupValidation } from "../../yupValidation/FunctionalTestingYupValidation";
import { SigningYupValidation } from "../../yupValidation/SigningYupValidation";
import { UploadToStoreYupValidation } from "../../yupValidation/UploadToStoreYupValidation";
import { AssignToYupValidation } from "../../yupValidation/AssignToYupValidation";
import { trackPromise } from "react-promise-tracker";

function UpdateDialog({
  releaseId,
  popupTitle,
  open,
  onDisagree,
  bodyKey,
  updating,
  setUpdating,
  downloadApkInfo,
  release,
  assignedInfo,
  downloadFileType,
}) {
  const [t] = useTranslation();
  const [dialogTitle, setDialogTitle] = useState("");
  const { authService } = useContext(AppContext);
  let releaseService = new ReleaseService(authService);
  const {
    register,
    handleSubmit,
    setValue,
    control,
    trigger,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(getFormFields()?.required()),
  });
  const [promiseErrorText, setPromiseErrorText] = useState();

  useEffect(() => {
    setDialogTitle(popupTitle);
  }, [popupTitle]);

  function getFormFields() {
    switch (bodyKey) {
      case "CREATED":
        return FunctionalTestingYupValidation();
      case "SECURITYSCAN_SUCCESS":
        return SigningYupValidation();
      case "SIGNING_SUCCESS":
        return UploadToStoreYupValidation();
      case "ASSIGN_TO":
        return AssignToYupValidation();
    }
  }

  function getUpdateFunction(data) {
    switch (bodyKey) {
      case "CREATED":
        return releaseService
          .patchFunctionalTestingStatus(
            releaseId,
            getFunctionalTestingFormData(data)
          )
          .then((response) => {
            reset();
            onDisagree();
          });
      case "SECURITYSCAN_SUCCESS":
        return releaseService
          .patchReleaseSignedStatus(releaseId, getSignedUpdateFormData(data))
          .then((response) => {
            reset();
            onDisagree();
          });
      case "SIGNING_SUCCESS":
        return releaseService
          .patchReleaseUploadToStoreStatus(
            releaseId,
            getUplaodToStoreFormData(data)
          )
          .then((response) => {
            reset();
            onDisagree();
          });
      case "ASSIGN_TO":
        return releaseService
          .patchReleaseInfo(releaseId, {
            lastEditedBy: release.lastEditedBy,
            lastUpdated: release.lastUpdated,
            assignedTo: data.assignTo,
          })
          .then((response) => {
            downloadApkInfo(downloadFileType);
            onDisagree();
          });
    }
  }

  function getSuccesText(data) {
    if ("ASSIGN_TO" === bodyKey) {
      return data.assignTo != "NOT_ASSIGNED" && "TOAST_RELEASE_ASSIGNED_TO";
    }
    return "TOAST_RELEASE_UPDATED";
  }

  function onSubmit(data) {
    if (releaseId) {
      setPromiseErrorText();
      setUpdating(true);
      trackPromise(
        SpinnerService.loadingAndSuccessSpinner(
          getUpdateFunction(data),
          t,
          "TOAST_PROCESSING",
          getSuccesText(data),
          setPromiseErrorText,
          setUpdating
        )
      );
    }
  }

  function getFunctionalTestingFormData(data) {
    const formData = new FormData();
    getGeneralFormData(data);
    data.report?.length > 0 &&
      formData.append(
        "file",
        new File(
          [data.report[0]],
          FormatService.replaceSpecialChar(data.report[0].name),
          {
            type: data.report[0].type,
          }
        )
      );
    formData.append(
      "functionalTestingRequest",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    return formData;
  }

  function getSignedUpdateFormData(data) {
    const formData = new FormData();
    getGeneralFormData(data);
    data.file?.length > 0 &&
      formData.append(
        "file",
        new File(
          [data.file[0]],
          FormatService.replaceSpecialChar(data.file[0].name),
          {
            type: data.file[0].type,
          }
        )
      );
    data.report?.length > 0 &&
      formData.append(
        "file",
        new File(
          [data.report[0]],
          FormatService.replaceSpecialChar(data.report[0].name),
          {
            type: data.report[0].type,
          }
        )
      );
    formData.append(
      "signedRequest",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    return formData;
  }

  function getUplaodToStoreFormData(data) {
    getGeneralFormData(data);
    return data;
  }

  function getGeneralFormData(data) {
    data.lastUpdated = Date.now();
    data.lastEditedBy = authService.getEmail();
  }

  function handleDisagree() {
    onDisagree();
    setPromiseErrorText();
    setUpdating(false);
  }

  function goBack() {
    setPromiseErrorText();
    setUpdating(false);
  }

  function getBodyByKey() {
    switch (bodyKey) {
      case "CREATED":
        return (
          <FunctionalTestingBody
            translate={t}
            control={control}
            setValue={setValue}
            isValid={isValid}
            onDisagree={handleDisagree}
            disabled={updating}
            submit={handleSubmit(onSubmit)}
            register={register}
            dialogTitle={popupTitle}
            setDialogTitle={setDialogTitle}
            resetForm={reset}
            validate={trigger}
          />
        );
      case "SECURITYSCAN_SUCCESS":
        return (
          <SignedBody
            translate={t}
            register={register}
            setDialogTitle={setDialogTitle}
            dialogTitle={popupTitle}
            authService={authService}
            setValue={setValue}
            control={control}
            onDisagree={handleDisagree}
            disabled={updating}
            submit={handleSubmit(onSubmit)}
            validate={trigger}
            resetForm={reset}
            isValid={isValid}
          />
        );
      case "SIGNING_SUCCESS":
        return (
          <UploadedToStoreBody
            translate={t}
            register={register}
            onDisagree={handleDisagree}
            disabled={updating}
            submit={handleSubmit(onSubmit)}
            setValue={setValue}
          />
        );
      case "ASSIGN_TO":
        return (
          <AssignToMeBody
            authService={authService}
            translate={t}
            onDisagree={handleDisagree}
            disabled={updating}
            assignFunction={handleSubmit(onSubmit)}
            downloadFromSharepoint={() => downloadApkInfo(downloadFileType)}
            release={release}
            control={control}
            assignedInfo={assignedInfo}
            required={true}
          />
        );
      default:
        return <></>;
    }
  }

  function getFailedBody() {
    return (
      <>
        <CcvMaterialIcon iconName="error_outline" size="extra-large" />
        <CcvText
          size="medium"
          color="dark-grey"
          textStyle="bold"
          testId={"signing_failed_label"}
        >
          {t("RELEASE_UPDATE_FAILED_LABEL")}
        </CcvText>
        <CcvText
          size="medium"
          color="dark-grey"
          testId={promiseErrorText?.toLowerCase()}
        >
          <div
            className="loading-dialog-error-text"
            dangerouslySetInnerHTML={{
              __html: t(promiseErrorText),
            }}
          />
        </CcvText>
      </>
    );
  }

  return (
    <Dialog open={open} onClose={handleDisagree} className="dialog">
      <DialogTitle>
        <CcvHeading
          text={dialogTitle}
          size="small"
          testId={dialogTitle.toLowerCase()}
        />
        <Icon onClick={handleDisagree} data-testid="cancel_status_icon_button">
          <CcvMaterialIcon iconName="clear" size="large" />
        </Icon>
      </DialogTitle>
      <div className="spacing-top" hidden={promiseErrorText}>
        {getBodyByKey()}
      </div>
      {promiseErrorText && (
        <div className={"loading-dialog loading-dialog-error"}>
          {getFailedBody()}
        </div>
      )}
      {promiseErrorText && (
        <DialogActions data-testid="button-block-info">
          <CcvButton
            size="normal"
            type="primary"
            text="Ok"
            onClick={() => goBack()}
            testId="ok_loading_dialog_button"
          />
        </DialogActions>
      )}
    </Dialog>
  );
}

export default UpdateDialog;
