import React, { useContext } from "react";
import TechnologyTypes from "../../domain/TechnologyType";
import IntegrationType from "../../domain/IntegrationType";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "../../style/Table.scss";
import { useNavigate } from "react-router-dom";
import ReleaseStatus from "../../domain/ReleaseStatus";
import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import { ShowForRole } from "../ShowForRole";
import FormatService from "../../services/FormatService";
import SpinnerService from "../../services/SpinnerService";
import Segment from "../../domain/Segment";
import { AppContext } from "../../config/AppContext";
import { ReleaseService } from "../../services/ReleaseService";
import ButtonWithIcons from "../common/button/ButtonWithIcons";
import DownloadTypes from "../../domain/DownloadType";

function BasicTable({
  waitingForBackendResponse,
  data,
  showDownloadUnsignedButton,
  showDownloadSignedButton,
  navigationPage,
  filter,
}) {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { authService } = useContext(AppContext);
  let releaseService = new ReleaseService(authService);

  function downloadApkButton(rowinfo, type) {
    return SpinnerService.defaultSpinner(
      releaseService
        .getDownloadLink(rowinfo.id, type)
        .then((response) =>
          FormatService.downloadFileWithNewFilename(
            response.data.downloadUrl,
            FormatService.getApkFileNameWithVersion(
              rowinfo.version,
              response.data.fileName
            )
          )
        ),
      t,
      "TOAST_DOWNLOADING",
      "TOAST_DOWNLOAD_SUCCEEDED",
      null
    );
  }

  function getFormattedData(field) {
    switch (field[0].toLowerCase()) {
      case "creationdate":
      case "lastupdated":
        return (
          <TableCell type="date" key={field[1]}>
            <CcvText size="medium" color="dark-grey">
              {FormatService.getDateWithTimeFormat(field[1])}
            </CcvText>
          </TableCell>
        );
      case "latestreleasestatus":
      case "releasestatus":
        return (
          <TableCell key={field[1]}>
            <CcvText size="medium" color="dark-grey">
              {t(ReleaseStatus[field[1]].description)}
            </CcvText>
          </TableCell>
        );
      case "technology":
        return (
          <TableCell key={field[1]}>
            <CcvText size="medium" color="dark-grey">
              {TechnologyTypes[field[1]].description}
            </CcvText>
          </TableCell>
        );
      case "integrationtype":
        return (
          <TableCell key={field[1]}>
            <CcvText size="medium" color="dark-grey">
              {IntegrationType[field[1]].description}
            </CcvText>
          </TableCell>
        );
      case "segments":
        return (
          <TableCell key={field[1]}>
            <CcvText size="medium" color="dark-grey">
              {field[1]
                .map((segment) => t(Segment[segment].description))
                .join(", ")}
            </CcvText>
          </TableCell>
        );
      case "bupaname":
        return (
          <ShowForRole permission="Employee">
            <TableCell key={field[1]}>
              <CcvText size="medium" color="dark-grey">
                {field[1]}
              </CcvText>
            </TableCell>
          </ShowForRole>
        );
      case "version":
        return (
          <TableCell key={field[1]}>
            <CcvText size="medium" color="dark-grey">
              <span className="break-word">{field[1]}</span>
            </CcvText>
          </TableCell>
        );
      case null:
        return (
          <TableCell key={"-"}>
            <CcvText size="medium" color="dark-grey">
              -
            </CcvText>
          </TableCell>
        );
      default:
        return (
          <TableCell key={field[1]}>
            <CcvText size="medium" color="dark-grey">
              {field[1]}
            </CcvText>
          </TableCell>
        );
    }
  }

  function getTableColumns() {
    return Object.keys(data[0])
      .filter((item) => filter.includes(item))
      .map((field) =>
        field === "bupaName" ? (
          <ShowForRole permission="Employee">
            <TableCell key={field}>
              <CcvText size="medium" color="dark-grey" textStyle="bold">
                {t("BASICTABLE_" + field.toUpperCase() + "_LABEL")}
              </CcvText>
            </TableCell>
          </ShowForRole>
        ) : (
          <TableCell key={field}>
            <CcvText size="medium" color="dark-grey" textStyle="bold">
              {t("BASICTABLE_" + field.toUpperCase() + "_LABEL")}
            </CcvText>
          </TableCell>
        )
      );
  }

  function getDownloadTableCell(type, text, isAvailable, rowInfo) {
    return (
      <TableCell>
        {isAvailable ? (
          <ButtonWithIcons
            iconNames={[
              {
                iconName: "file_download",
                onClick: (e) => {
                  e.stopPropagation();
                  downloadApkButton(rowInfo, type);
                },
              },
            ]}
            buttonText={text}
            translate={t}
            iconClassName="icon-blue"
            textClassName={"download-icon-link"}
            testid={text?.toLowerCase()}
          />
        ) : (
          <CcvText
            size="medium"
            color="dark-grey"
            testId="signed_apk_not_available_text"
          >
            {t("BASICTABLE_NOT_SIGNED_YET")}
          </CcvText>
        )}
      </TableCell>
    );
  }

  function getTableData() {
    return Object.values(data).map((row) => (
      <TableRow
        className={navigationPage && "row-clickable"}
        id={navigationPage || "no-detail"}
        onClick={() =>
          navigationPage
            ? navigate("/" + navigationPage + "/detail/" + row.id, {
                state: { detail: row },
              })
            : null
        }
      >
        {Object.entries(row)
          .filter((item) => filter.includes(item[0]))
          .map((item) => getFormattedData(item))}
        {showDownloadUnsignedButton &&
          getDownloadTableCell(
            DownloadTypes.UNSIGNED_APK,
            "BASICTABLE_UNSIGNED_APK",
            true,
            row
          )}
        {showDownloadSignedButton &&
          getDownloadTableCell(
            DownloadTypes.SIGNED_APK,
            "BASICTABLE_SIGNED_APK",
            row.signing?.signedApk?.sha256,
            row
          )}
        {navigationPage && (
          <TableCell>
            <CcvMaterialIcon iconName="navigate_next" />
          </TableCell>
        )}
      </TableRow>
    ));
  }

  return (
    <TableContainer>
      {waitingForBackendResponse ? (
        <LoadingBlock />
      ) : (
        <Table className="basic-table">
          <TableHead>
            <TableRow>
              {getTableColumns()}
              {showDownloadUnsignedButton && (
                <TableCell key="download">
                  <CcvText size="medium" color="dark-grey" textStyle="bold">
                    {t("BASICTABLE_UNSIGNED_APK")}
                  </CcvText>
                </TableCell>
              )}
              {showDownloadSignedButton && (
                <TableCell key="download">
                  <CcvText size="medium" color="dark-grey" textStyle="bold">
                    {t("BASICTABLE_SIGNED_APK")}
                  </CcvText>
                </TableCell>
              )}
              {navigationPage && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>{getTableData()}</TableBody>
        </Table>
      )}
    </TableContainer>
  );
}

export default BasicTable;
