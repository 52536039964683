import { CcvButton } from "@ccv-oc-myccv/ccv-react-components";
import { DialogActions, DialogContent } from "@mui/material";
import React, { useEffect, useState } from "react";
import RadioButtonBody from "../RadioButtonBody";
import TestingFinishedBody from "./TestingFinishedBody";
import TestingSkippedBody from "./TestingSkippedBody";

function FunctionalTestingBody({
  translate,
  register,
  setValue,
  setDialogTitle,
  dialogTitle,
  control,
  onDisagree,
  disabled,
  submit,
  resetForm,
  isValid,
  validate,
}) {
  const [functionalTestingStatus, setFunctionalTestingStatus] = useState();
  const [isFunctionalTestingDone, setIsFunctionalTestingDone] = useState();
  const [isLastStep, setIsLastStep] = useState(false);
  const [isContractSigned, setIsContractSigned] = useState(false);
  const [functionalTestReason, setFunctionalTestReason] = useState();

  useEffect(() => {
    setDialogTitle(
      dialogTitle +
        (isFunctionalTestingDone
          ? ": " + translate(isFunctionalTestingDone).toUpperCase()
          : "") +
        " (" +
        translate("STEP_LABEL") +
        (isLastStep ? " 2" : " 1") +
        "/2)"
    );
    !isLastStep && !functionalTestingStatus && resetForm();
    !isLastStep && setIsContractSigned(false);
  }, [isLastStep]);

  useEffect(() => {
    functionalTestingStatus &&
      setValue("status", functionalTestingStatus, { shouldValidate: true });
    functionalTestReason === "OVERRULED"
      ? setValue("overruler", "", { shouldValidate: true })
      : setValue("overruler", "NOT_OVERRULED", { shouldValidate: true });
  }, [functionalTestingStatus, functionalTestReason]);

  useEffect(() => {
    setIsContractSigned(false);
    setValue("contract", false, { shouldValidate: true });
  }, [functionalTestingStatus]);

  return (
    <>
      <DialogContent>
        <form className="dialog-body">
          {!isLastStep && (
            <RadioButtonBody
              setValue={setIsFunctionalTestingDone}
              name={"status"}
              translate={translate}
              control={control}
              isRegisterDisabled={true}
              required={true}
              selectedOption={isFunctionalTestingDone}
              options={[
                {
                  id: "yes",
                  value: "YES_TEXT",
                  text: "YES_TEXT",
                  testid: "functional_testing_yes_option",
                },
                {
                  id: "no",
                  value: "NO_TEXT",
                  text: "NO_TEXT",
                  testid: "functional_testing_no_option",
                },
              ]}
              title={"FUNCTIONAL_TESTING_DIALOG_SUBTITLE"}
            />
          )}
          {isLastStep &&
            (isFunctionalTestingDone === "YES_TEXT" ? (
              <TestingFinishedBody
                setStatus={setFunctionalTestingStatus}
                status={functionalTestingStatus}
                translate={translate}
                control={control}
                setValue={setValue}
                validate={validate}
                setIsContractSigned={setIsContractSigned}
                isContractSigned={isContractSigned}
                setFunctionalTestReason={setFunctionalTestReason}
              />
            ) : (
              <TestingSkippedBody
                translate={translate}
                setIsContractSigned={setIsContractSigned}
                register={register}
                setValue={setValue}
                setStatus={setFunctionalTestingStatus}
                control={control}
                validate={validate}
                isContractSigned={isContractSigned}
                functionalTestReason={functionalTestReason}
                setFunctionalTestReason={setFunctionalTestReason}
              />
            ))}
        </form>
      </DialogContent>
      <DialogActions>
        <CcvButton
          type="tertiary"
          size="normal"
          text={isLastStep ? translate("BACK") : translate("CANCEL_LABEL")}
          onClick={
            !isLastStep
              ? onDisagree
              : () => {
                  setFunctionalTestingStatus();
                  setIsLastStep(false);
                }
          }
          disabled={disabled}
          testId="cancel_status_update_button"
        />
        <CcvButton
          type="primary"
          size="normal"
          text={
            isLastStep
              ? translate("FUNCTIONAL_TESTING_DONE_BUTTON")
              : translate("NEXT")
          }
          onClick={isLastStep ? submit : () => setIsLastStep(true)}
          disabled={
            !isFunctionalTestingDone || disabled || (isLastStep && !isValid)
          }
          testId={isLastStep ? "update_status_button" : "next_step_button"}
        />
      </DialogActions>
    </>
  );
}

export default FunctionalTestingBody;
