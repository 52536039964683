import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import FormValidation from "../../../../../domain/FormValidation";
import { FunctionalTestingFeedback } from "../../../../../domain/FunctionalTestingFeedback";
import ContractCheckbox from "../../../../form/ContractCheckbox";
import InputLabel from "../../../../form/InputLabel";
import RadioButtonList from "../../../../form/RadioButtonList";
import TextInput from "../../../../form/TextInput";

function TestingSkippedBody({
  translate,
  setStatus,
  setIsContractSigned,
  control,
  setValue,
  isContractSigned,
  setFunctionalTestReason,
  functionalTestReason,
  validate,
}) {
  useEffect(() => {
    setStatus("FUNCTIONAL_TESTING_SKIPPED");
  });

  return (
    <form>
      <InputLabel
        labelText={"WHY_NOT_LABEL"}
        translate={translate}
        bold={true}
        required={true}
      />
      <Controller
        control={control}
        name={"feedback"}
        render={({ field: { onChange } }) => (
          <RadioButtonList
            register={onChange}
            name={"feedback"}
            direction={"column"}
            options={FunctionalTestingFeedback}
            validate={validate}
            setValue={setFunctionalTestReason}
          />
        )}
      />
      {functionalTestReason === FunctionalTestingFeedback.OVERRULED.key && (
        <TextInput
          setValue={setValue}
          field={"overruler"}
          placeholder={"OVER_RULER_PLACEHOLDER"}
          maxLength={FormValidation.MAX_OVERRULER_NAME_LENGTH}
          shouldValidateOnInput={true}
        />
      )}
      <ContractCheckbox
        translate={translate}
        testId={"functional_testing_authorize"}
        setChecked={setIsContractSigned}
        text={"FUNCTIONAL_TESTING_SKIPPED_AUTHORIZE_LABEL"}
        isChecked={isContractSigned}
        registerName={"contract"}
        setValue={setValue}
      />
    </form>
  );
}

export default TestingSkippedBody;
