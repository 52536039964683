import React, { useEffect } from "react";
import { CcvMaterialIcon } from "@ccv-oc-myccv/ccv-react-components";
import Textarea from "../form/Textarea";
import { useTranslation } from "react-i18next";
import ValidationError from "../form/ValidationError";
import FormatService from "../../services/FormatService";
import FormValidation from "../../domain/FormValidation";
import Switch from "./Switch";
import { ShowForRole } from "../ShowForRole";

function PermissionInput({
  permission,
  clearErrors,
  errors,
  setValue,
  disabled,
  textAreaClassName,
  editIncompletePermissionList,
  incompletePermissionList,
  isPermissionUpdateEnabled,
  releaseStatus,
  setEditPermissionFormOpen,
  setEditButton,
  statusLabels,
  permissionTypeLabels,
}) {
  const [t] = useTranslation();

  useEffect(() => {
    setEditButtonBody();
  }, []);

  function setEditButtonBody() {
    setEditButton(
      [
        "SECURITYSCAN_FAILED",
        "SECURITYSCAN_MORE_INFO",
        "FUNCTIONAL_TESTING_SUCCESS",
        "FUNCTIONAL_TESTING_SKIPPED",
      ].includes(releaseStatus) && (
        <ShowForRole permission="Employee">
          <span
            onClick={() => setEditPermissionFormOpen(true)}
            data-testid={"edit_reason_permission_button"}
            className="edit_reason_permission_button"
          >
            <CcvMaterialIcon
              iconName="edit"
              size="medium"
              iconStyle="outline"
            />
          </span>
        </ShowForRole>
      )
    );
  }

  function setIsMoreInfoNeeded(isMoreInfoNeeded) {
    editIncompletePermissionList(permission, isMoreInfoNeeded);
  }

  return (
    <>
      <Textarea
        className={textAreaClassName}
        classNameBlock="no-spacing"
        translate={t}
        labelText="REASON_LABEL"
        statusLabels={statusLabels}
        permissionTypeLabels={permissionTypeLabels}
        showForRole="Employee"
        field={FormatService.getPermissionValidationKey(permission.name)}
        defaultInputData={permission?.reason}
        setValue={setValue}
        required={true}
        disabled={disabled}
        clearErrors={clearErrors}
        shouldValidateOnInput={false}
        maxLength={FormValidation.MAX_REASON_INPUT_LENGTH}
        validationError={
          errors && (
            <ValidationError
              error={
                errors[
                  FormatService.getPermissionValidationKey(permission.name)
                ]
              }
              errorText={t("PERMISSION_ADD_REASON")}
              testId="permission_reason_error"
            />
          )
        }
      />
      {isPermissionUpdateEnabled && (
        <ShowForRole permission="Employee">
          <Switch
            translatedSwitchText={t("PERMISSION_NEED_MORE_INFO_LABEL")}
            setValue={setIsMoreInfoNeeded}
            testId="switch_more_info"
            isChecked={incompletePermissionList?.includes(permission.name)}
          />
        </ShowForRole>
      )}
    </>
  );
}

export default PermissionInput;
