const Segment = {
  EV_CHARGING: { key: "EV_CHARGING", description: "EV_CHARGING_VALUE" },
  PUBLIC_TRANSPORT: {
    key: "PUBLIC_TRANSPORT",
    description: "PUBLIC_TRANSPORT_PARKING_VALUE",
  },
  VENDING: { key: "VENDING", description: "VENDING_VALUE" },
  SERVICE_STATIONS: {
    key: "SERVICE_STATIONS",
    description: "SERVICE_STATIONS_VALUE",
  },
  RETAIL: { key: "RETAIL", description: "RETAIL_VALUE" },
};

export default Segment;
