const PaymentEngine = {
  PAYGEAR: {
    key: "PAYGEAR",
    description: "Paygear",
    selectValue: { id: "PAYGEAR", text: "Paygear" },
    versions: [
      { id: "14.4.02.01", text: "14.4.02.01" },
      { id: "14.4.02.00", text: "14.4.02.00" },
      { id: "14.4.01.00", text: "14.4.01.00" },
      { id: "14.4.00.00", text: "14.4.00.00" },
      { id: "14.3.05.01", text: "14.3.05.01" },
      { id: "OTHER", text: "Other" },
      { id: "NOT_FILLED_IN", text: "I don't know" },
    ],
  },
  ACCEPT: {
    key: "ACCEPT",
    description: "ApasPP - acCEPT (Fusion)",
    selectValue: { id: "ACCEPT", text: "ApasPP - acCEPT (Fusion)" },
    versions: [
      { id: "25.26", text: "25.26" },
      { id: "25.25", text: "25.25" },
      { id: "25.21", text: "25.21" },
      { id: "25.19", text: "25.19" },
      { id: "25.17", text: "25.17" },
      { id: "OTHER", text: "Other" },
      { id: "NOT_FILLED_IN", text: "I don't know" },
    ],
  },
  SECPOS: {
    key: "SECPOS",
    description: "SECpos",
    selectValue: { id: "SECPOS", text: "SECpos" },
    versions: [
      { id: "03.0070.09.01	", text: "03.0070.09.01	" },
      { id: "03.0070.08.01	", text: "03.0070.08.01	" },
      { id: "03.0070.06.01	", text: "03.0070.06.01	" },
      { id: "03.0070.05.01", text: "03.0070.05.01" },
      { id: "03.0070.04.01", text: "03.0070.04.01" },
      { id: "OTHER", text: "Other" },
      { id: "NOT_FILLED_IN", text: "I don't know" },
    ],
  },
};

export default PaymentEngine;
