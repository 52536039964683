import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import RowTable from "../../../components/tables/rowTable/RowTable";
import Environment from "../../../domain/Environment";
import Terminal from "../../../domain/Terminal";
import PaymentEngine from "../../../domain/PaymentEngine";
import IntegrationType from "../../../domain/IntegrationType";
import TechnologyType from "../../../domain/TechnologyType";
import Segment from "../../../domain/Segment";
import Role from "../../../domain/Role";
import ProjectSolution from "../../../domain/ProjectSolution";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import { AppContext } from "../../../config/AppContext";

function GeneralInfoComponent({ project, roles }) {
  const [t] = useTranslation();
  const { authService } = useContext(AppContext);

  return (
    <div className="projectinfo">
      {project && (
        <Accordion
          className="accordion-wrapper projectinfo-accordion"
          disableGutters={true}
        >
          <AccordionSummary
            expandIcon={
              <span data-testid="project_info_button">
                <CcvMaterialIcon iconName="expand_more" size="large" />
              </span>
            }
            className="projectinfo-accordion-header"
          >
            <RowTable
              translate={t}
              data={[
                {
                  columnName: "PROJECTS_INTEGRATOR_CREATED_DATE_LABEL",
                  data: project.creationDate,
                  type: "date",
                },
              ]}
            />
            <RowTable
              translate={t}
              data={[
                {
                  columnName: "INTEGRATION_SOLUTION_LABEL",
                  data: ProjectSolution[project.solution]?.description,
                  type: "text",
                },
              ]}
            />
          </AccordionSummary>
          <AccordionDetails>
            <div className="projectinfo-accordion-body">
              <RowTable
                translate={t}
                data={[
                  {
                    columnName: "CCV_TERMINAL_LABEL",
                    data: project.ecronTerminal ? "YES_TEXT" : "NO_TEXT",
                    type: "text",
                  },
                  {
                    columnName: "TERMINALS_ARE_LABEL",
                    data: Environment[project.environment]?.description,
                    type: "text",
                  },
                  {
                    columnName: "TERMINAL_TYPE_LABEL",
                    data: project.terminals,
                    options: Terminal,
                    type: "list",
                  },
                  {
                    columnName: "PAYMENT_ENGINE_LABEL",
                    data:
                      project.paymentEngineTypes?.length > 0
                        ? project.paymentEngineTypes
                        : "NOT_FILLED_IN",
                    options: PaymentEngine,
                    type:
                      project.paymentEngineTypes?.length > 0 ? "list" : "text",
                  },
                ]}
              />

              <RowTable
                translate={t}
                data={[
                  {
                    columnName: "BASICTABLE_INTEGRATIONTYPE_LABEL",
                    data:
                      IntegrationType[project.integrationType]?.type === "LOCAL"
                        ? t("LOCAL_INTEGRATION_LABEL") +
                          " - " +
                          IntegrationType[project.integrationType]?.description
                        : IntegrationType[project.integrationType]?.description,
                    type: "text",
                  },
                  {
                    columnName: "INTEGRATION_TECHNOLOGY_LABEL",
                    data: TechnologyType[project.technology]?.description,
                    type: "text",
                  },
                  {
                    columnName: "SEGMENT_LABEL",
                    data: project.segments,
                    options: Segment,
                    type: "list",
                  },
                  authService.isEmployee(roles) && {
                    columnName: "BASICTABLE_BUPANAME_LABEL",
                    data: project.bupaName + " - " + project.paNumber,
                    type: "text",
                  },
                ]}
              />
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </div>
  );
}

export default GeneralInfoComponent;
