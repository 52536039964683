import * as yup from "yup";

export function SecurityscanYupValidation(securityscan) {
  return yup.object({
    status: yup.string().required("required").typeError("required"),
    report: yup
      .array()
      .when(() =>
        isMobsfReportInputNeeded()
          ? yup.array().min(1).max(1).required("required")
          : yup.array().min(0).notRequired()
      ),
    mobsfUrl: yup
      .string()
      .transform((value) => (value === "" ? null : value))
      .url()
      .matches(/^https?:\/\/[^\/]+\/.+$/)
      .notRequired(),
    jottiUrl: yup.string().when(() => {
      return isJottiInputNeeded()
        ? yup
            .string()
            .url()
            .matches(/^https?:\/\/[^\/]+\/.+$/)
            .required("required")
            .typeError("required")
        : yup
            .string()
            .url()
            .matches(/^https?:\/\/[^\/]+\/.+$/)
            .notRequired();
    }),
    feedback: yup
      .string()
      .when("status", ([status]) =>
        status === "SECURITYSCAN_MORE_INFO" || status === "SECURITYSCAN_FAILED"
          ? yup.string().required("required").typeError("required")
          : yup.string().notRequired()
      ),
    contract: yup
      .string()
      .when("status", ([status]) =>
        status === "SECURITYSCAN_SUCCESS"
          ? yup
              .boolean()
              .required("required")
              .oneOf([true], "The terms and conditions must be accepted.")
          : yup.boolean().notRequired()
      ),
  });

  function isApiCallFailed(progress) {
    return ![
      "FINISHED",
      "RUNNING",
      "JOTTI_DISABLED",
      "EXECUTED_MANUALLY",
    ].includes(progress);
  }

  function isApiCallFinished(progress) {
    return !["RUNNING", "JOTTI_DISABLED"].includes(progress);
  }

  function isJottiResultAvailable() {
    return securityscan?.jotti?.scanJobIdUrl;
  }

  function isMobsfReportAvailable() {
    return securityscan?.reportFileAvailable;
  }

  function isMobsfReportInputNeeded() {
    return (
      (isApiCallFailed(securityscan?.mobsf?.progress) &&
        !isMobsfReportAvailable()) ||
      (isApiCallFinished(securityscan?.mobsf?.progress) &&
        !isMobsfReportAvailable())
    );
  }

  function isJottiInputNeeded() {
    return (
      (isApiCallFailed(securityscan?.jotti?.progress) &&
        !isJottiResultAvailable()) ||
      (isApiCallFinished(securityscan?.jotti?.progress) &&
        !isJottiResultAvailable())
    );
  }
}
