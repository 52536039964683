import { CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";

function ValidationError({ error, errorText, testId }) {
  return error ? (
    <CcvText size="small" color="red" testId={testId}>
      {errorText}
    </CcvText>
  ) : (
    <></>
  );
}

export default ValidationError;
