import React from "react";
import {
  CcvHeading,
  CcvMaterialIcon,
  CcvMessage,
} from "@ccv-oc-myccv/ccv-react-components";
import { useTranslation } from "react-i18next";
import PermissionListItem from "../../../../components/common/PermissionListItem";
import ValidationError from "../../../../components/form/ValidationError";
import FormatService from "../../../../services/FormatService";

function PermissionFormComponent({
  permissions,
  clearErrors,
  errors,
  setValue,
}) {
  const [t] = useTranslation();
  function sortPermissions(permissions) {
    return permissions?.sort((a, b) => {
      const priority = { undefined: 1, default: 3 };

      const statusA = priority[a.status] || priority.default;
      const statusB = priority[b.status] || priority.default;

      return statusA - statusB;
    });
  }

  return (
    <>
      <CcvHeading
        text={
          permissions?.length
            ? permissions?.length + " " + t("PERMISSIONS_INFO_HEADING")
            : t("NO_PERMISSIONS_INFO_HEADING")
        }
        size="medium"
        testId="PERMISSIONS_HEADING"
      />
      <div className="message-block message-block-permissions spacing-top spacing-bottom">
        {!permissions?.length ? (
          <CcvMessage
            text={
              <span className="message-block-text">
                <CcvMaterialIcon iconName="check" size="medium" />
                {t("NO_DANGEROUS_OR_UNKNOWN_PERMISSIONS_MESSAGE")}
              </span>
            }
            type="success"
            testId="no_dangerous_or_unknown_permissions_message"
          />
        ) : (
          <CcvMessage
            text={
              <span className="message-block-text">
                <CcvMaterialIcon iconName="error" size="medium" />
                {t("DANGEROUS_OR_UNKNOWN_PERMISSIONS_MESSAGE")}
              </span>
            }
            type="alert"
            testId="dangerous_or_unknown_permissions_message"
          />
        )}
      </div>
      <div className="two-column-container">
        {sortPermissions(permissions)?.map((permission) => (
          <div className="permission_input" key={permission.name}>
            <PermissionListItem
              textAreaClassName="textarea-medium no-resize-textarea"
              permission={permission}
              clearErrors={clearErrors}
              errors={errors}
              setValue={setValue}
            />
          </div>
        ))}
      </div>
      <ValidationError
        error={Object.keys(errors).length > 0 && permissions?.length > 4}
        errorText={
          t("PERMISSIONS_REQUIRED_LABEL") +
          " " +
          Object.keys(errors)
            .map((key) => FormatService.getPermissionName(key))
            .join(", ")
        }
        testId={"permission_input_is_required_text"}
      />
    </>
  );
}

export default PermissionFormComponent;
