import { CcvHeading, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import IntegrationCategory from "../../../domain/IntegrationCategory";
import IntegrationType from "../../../domain/IntegrationType";
import RadioButtonList from "../../form/RadioButtonList";
import ValidationError from "../../form/ValidationError";

function IntegrationTypeSection({
  control,
  setResult,
  registerName,
  translate,
  errorText,
  errors,
  handleDialogOpen,
  setIntegrationValue,
  defaultInputData,
  validate,
  required,
}) {
  const [integrationCategory, setIntegrationCategory] = useState(
    defaultInputData && IntegrationType[defaultInputData.integrationType].type
  );

  useEffect(() => {
    defaultInputData &&
      setIntegrationTypeResult(defaultInputData.integrationType);
  }, []);

  function setIntegrationCategoryResult(integrationCategory) {
    if (integrationCategory === "CLOUD") {
      setIntegrationValue(registerName, integrationCategory);
      setIntegrationTypeResult(integrationCategory);
    } else {
      setResult(null);
      setIntegrationValue(registerName, null);
    }
    setIntegrationCategory(integrationCategory);
    errors && validate("integratinoType");
  }

  function setIntegrationTypeResult(value) {
    setResult(
      <>
        {value !== "CLOUD" && (
          <>
            <CcvText size="small" color="dark-grey" textStyle="bold">
              {IntegrationCategory[integrationCategory].description}
            </CcvText>
            <CcvText size="small" color="dark-grey">
              &nbsp;
              {translate("USING_TEXT")}
              &nbsp;
            </CcvText>
          </>
        )}
        <CcvText size="small" color="dark-grey" textStyle="bold">
          {IntegrationType[value].description}
        </CcvText>
      </>
    );
  }

  return (
    <>
        <table className={"integration-catalogue"}>
          <tr
            className="multi-side-block catalogue-heading-result clickable"
            onClick={handleDialogOpen}
          >
            <th className="left">
              <CcvHeading
                text={
                  translate("PROJECT_COMMUNICATION_TYPE_LABEL") +
                  (required ? " *" : "")
                }
                size="extra-small"
                testId="project_integration_type_label"
              />
            </th>
            <th className="right">
              <CcvHeading
                text={
                  integrationCategory === "LOCAL" &&
                  translate("CCV_INTEGRATION_TYPE_LABEL") +
                    (required ? " *" : "")
                }
                size="extra-small"
                testId="ccv_integration_type_label"
              />
            </th>
          </tr>
          <tr className="multi-side-block catalogue-body">
            <td className=" left">
              <RadioButtonList
                name="integrationCategory"
                options={IntegrationCategory}
                setValue={setIntegrationCategoryResult}
                direction={"column"}
                initialSelectedValue={integrationCategory}
              />
              {!integrationCategory && (
                <ValidationError
                  errorText={translate(errorText)}
                  error={errors[registerName]}
                  testId="integration_category_error"
                />
              )}
            </td>
            {integrationCategory === "LOCAL" && (
              <td className="right">
                <Controller
                  control={control}
                  name={registerName}
                  render={({ field: { onChange } }) => (
                    <RadioButtonList
                      name={registerName}
                      options={
                        IntegrationCategory[integrationCategory].integrationType
                      }
                      register={onChange}
                      setValue={setIntegrationTypeResult}
                      direction={"column"}
                      initialSelectedValue={
                        defaultInputData && defaultInputData.integrationType
                      }
                    />
                  )}
                />
                <ValidationError
                  errorText={translate(errorText)}
                  error={errors[registerName]}
                  testId="integration_category_error"
                />
              </td>
            )}
          </tr>
        </table>
    </>
  );
}

export default IntegrationTypeSection;
