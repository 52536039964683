import { CcvCheckbox, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React, { useEffect, useState } from "react";
import ValidationError from "./ValidationError";

function CheckBoxList({
  label,
  options,
  registerName,
  defaultChecked,
  disabled,
  onInput,
  register,
  translate,
  errors,
  errorText,
  triggerValidation,
}) {
  const [selectedValues, setSelectedValues] = useState(
    defaultChecked ? defaultChecked : []
  );

  function onCheckboxChange(checkboxId) {
    if (selectedValues.includes(checkboxId)) {
      setSelectedValues(selectedValues.filter((item) => item != checkboxId));
      onInput && onInput(checkboxId, false);
    } else {
      onInput && onInput(checkboxId, true);
      setSelectedValues([...selectedValues, checkboxId]);
    }
  }

  useEffect(() => {
    register(registerName, selectedValues, { shouldDirty: true });
    errors && selectedValues.length > 0 && triggerValidation(registerName);
  }, [selectedValues]);

  return (
    <>
      {label && (
        <CcvText size="medium" color="medium-grey">
          {translate(label)}
        </CcvText>
      )}
      <div
        className={
          "checkbox-list " +
          (Object.entries(options).length > 4 && "two-row-checkbox ")
        }
      >
        {Object.entries(options).map((item) => (
          <span
            className="checkbox"
            data-testid={item[1].key.toLowerCase() + "_option"}
          >
            <CcvCheckbox
              id={item[0]}
              disabled={disabled || disabled}
              checked={selectedValues.includes(item[0])}
              value={item[0]}
              labelText={translate(item[1].description)}
              testId={item[1].description.toLowerCase()}
              onChange={(selectedValue) => {
                onCheckboxChange(selectedValue);
              }}
            />
          </span>
        ))}
      </div>
      {errors && (
        <ValidationError
          errorText={translate(errorText)}
          error={errors[registerName]}
          testId={registerName.toLowerCase() + "_error"}
        />
      )}
    </>
  );
}

export default CheckBoxList;
