import { CcvHeading, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import EmptyIconBlock from "../../../../components/emptyBlocks/EmptyIconBlock";
import RowTable from "../../../../components/tables/rowTable/RowTable";
import ReleaseStatus from "../../../../domain/ReleaseStatus";
import DownloadTypes from "../../../../domain/DownloadType";
import { ShowForRole } from "../../../../components/ShowForRole";
import FormatService from "../../../../services/FormatService";
import multiDownload from "multi-download";

function SigningInfoComponent({
  release,
  releaseHistory,
  downloadFromSharepoint,
  dialogRevisionInfo,
  showFeedbackDialog,
}) {
  const [t] = useTranslation();

  function downloadSha256ValueAsText() {
    multiDownload(
      [
        URL.createObjectURL(
          new Blob([release?.unsignedApk?.sha256], {
            type: "text/plain",
          })
        ),
      ],
      {
        rename: ({ url, index, urls }) =>
          "DC_" +
          release.project.projectName?.replaceAll(" ", ".") +
          "_" +
          release.project?.apkInfo?.packageName +
          "_" +
          release.version +
          "_unsigned.sha256",
      }
    );
  }

  function getFeedbackDialogButton(buttonText) {
    if (["SIGNING_FAILED"].includes(dialogRevisionInfo?.status)) {
      return (
        <CcvText size="medium" color="medium-grey">
          <span
            data-testid="show_feedback_button_signing"
            className="download-link no-wrap"
            onClick={() => {
              showFeedbackDialog(true);
            }}
          >
            {t(buttonText || "SHOW_FEEDBACK")}
          </span>
        </CcvText>
      );
    }
    return null;
  }

  function getHistoryRowBody(rowInfo, emptyMessage, user, children) {
    if (rowInfo) {
      return (
        <div className="multi-side-block row-table-security-scan">
          <div>
            <ShowForRole permission="Employee">
              <span className="no-wrap">
                <CcvText
                  size="medium"
                  color="dark-grey"
                  testId="securityscan_user"
                >
                  {user ? user : rowInfo.user}
                </CcvText>
              </span>
            </ShowForRole>
            <CcvText
              size="medium"
              color="dark-grey"
              testId={rowInfo.status?.toLowerCase() + "_info"}
            >
              {rowInfo.creationDate &&
                FormatService.getDateWithTimeFormat(rowInfo.creationDate)}
            </CcvText>
          </div>
          {children && children}
        </div>
      );
    } else {
      return (
        <CcvText size="medium" color="grey" testId={emptyMessage.toLowerCase()}>
          {t(emptyMessage)}
        </CcvText>
      );
    }
  }

  return (
    <>
      <span className="spacing-bottom">
        <CcvHeading
          text={t("SIGNING_INFO_HEADING")}
          size="small"
          testId="signing_info_heading"
        />
      </span>
      {release ? (
        <div className="multi-side-block gap">
          <div className="left">
            <RowTable
              translate={t}
              data={[
                {
                  columnName: "RELEASE_APK_LABEL",
                  data: "DOWNLOAD_UNSIGNED_LABEL",
                  function: () =>
                    downloadFromSharepoint(DownloadTypes.UNSIGNED_APK),
                  type: "download",
                },
                {
                  columnName: "RELEASESTATUS_SIGNED_STEPNAME",
                  data:
                    releaseHistory &&
                    getHistoryRowBody(
                      dialogRevisionInfo,
                      "NOT_SIGNED_YET",
                      release.signing?.signers?.join(", "),
                      getFeedbackDialogButton()
                    ),
                  type: "element",
                },
                {
                  columnName: "FILE_NAME_LABEL",
                  data: release.unsignedApkName,
                  type: "text_copy_download",
                  fileName:
                    "DC_" +
                    release.project.projectName?.replaceAll(" ", ".") +
                    "_" +
                    release.project.apkInfo?.packageName +
                    "_" +
                    release.version +
                    "_filename",
                },
                {
                  columnName: "PACKAGE_NAME_LABEL",
                  data: release.project?.apkInfo?.packageName,
                  type: "text_copy_download",
                  fileName:
                    "DC_" +
                    release.project.projectName?.replaceAll(" ", ".") +
                    "_" +
                    release.project.apkInfo?.packageName +
                    "_" +
                    release.version +
                    "_packageName",
                },
                {
                  columnName: "SHA256",
                  data: release.unsignedApk.sha256,
                  type: "text_copy_download",
                  donwloadFunction: downloadSha256ValueAsText,
                },
                {
                  columnName: "SHA256_SIGNED_LABEL",
                  data: release.signing?.signedApk
                    ? release.signing.signedApk.sha256
                    : t("EMPTY_SIGNED_SHA256"),
                  type: release?.signing?.signedApk?.sha256
                    ? "text_copy_download"
                    : "unavailable",
                  fileName:
                    "SHA256Signed_" +
                    release.project?.paNumber +
                    "_" +
                    release.version,
                  unavailableData:
                    Object.values(releaseHistory).some(
                      (revision) =>
                        revision.status === ReleaseStatus.SIGNING_SUCCESS.key
                    ) && !release.signing?.signedApk?.sha256,
                },
              ]}
            />
          </div>

          <div className="right">
            <RowTable
              translate={t}
              data={[
                {
                  columnName: "PA_LABEL",
                  data: release.project?.paNumber,
                },
                {
                  columnName: "REVISION_NUMBER_LABEL",
                  data: release.revisionNumber?.toString(),
                },
                {
                  columnName: "REVISION_DATE_LABEL",
                  data: release.creationDate,
                  type: "date",
                },
              ]}
            />
          </div>
        </div>
      ) : (
        <EmptyIconBlock emptyText="RELEASE_INFO_TEXT_NO_INFO" translate={t} />
      )}
    </>
  );
}

export default SigningInfoComponent;
