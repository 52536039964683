import { CcvText } from "@ccv-oc-myccv/ccv-react-components";

function OperationInfoBody({ translate }) {
  return (
    <>
      <CcvText size="medium" color="medium-grey">
        {translate("OPERATION_INFO_TEXT_1")}
      </CcvText>
      <ul className="catalogue-list">
        <li>
          <CcvText size="medium" color="medium-grey">
            {translate("OPERATION_INFO_TEXT_2")}
          </CcvText>
        </li>
        <li>
          <CcvText size="medium" color="medium-grey">
            {translate("OPERATION_INFO_TEXT_3")}
          </CcvText>
        </li>
        <li>
          <CcvText size="medium" color="medium-grey">
            {translate("OPERATION_INFO_TEXT_4")}
          </CcvText>
        </li>
      </ul>
      <CcvText size="medium" color="medium-grey">
        <span>
          {translate("OPERATION_INFO_TEXT_5")}&nbsp;
          <a
            className="catalogue-link"
            href="https://www.ccv.eu/en/solutions/developer-portal/"
            target="_blank"
          >
            CCV Developer Portal
          </a>
        </span>
      </CcvText>
    </>
  );
}

export default OperationInfoBody;
