import { CcvHeading, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React, { useState, useContext } from "react";
import { AppContext } from "../../../../config/AppContext";
import { useTranslation } from "react-i18next";
import RowTable from "../../../../components/tables/rowTable/RowTable";
import { ShowForRole } from "../../../../components/ShowForRole";
import FormatService from "../../../../services/FormatService";
import EmptyIconBlock from "../../../../components/emptyBlocks/EmptyIconBlock";
import DownloadTypes from "../../../../domain/DownloadType";
import ResultLabel from "../../../../components/common/ResultLabel";
import DownloadLink from "../../../../components/common/button/DownloadLink";

function SecurityInfoComponent({
  release,
  downloadFromSharepoint,
  releaseHistory,
  roles,
  dialogRevisionInfo,
  showFeedbackDialog,
}) {
  const [t] = useTranslation();
  const { authService } = useContext(AppContext);

  function isPermissionCheckAvailable() {
    const validationsReleaseDate = new Date(
      Number(process.env.REACT_APP_VALIDATIONS_RELEASE_DATE)
    );
    if (validationsReleaseDate) {
      return new Date(release?.creationDate) > validationsReleaseDate;
    }
  }

  function isPermissionDataAvailable() {
    return release?.project?.apkInfo?.permissions?.length;
  }

  function isJottiResultAvailable() {
    return release?.securityscan?.jotti?.scanJobIdUrl;
  }

  function isMobsfResultAvailable() {
    return (
      release?.securityscan?.mobsf?.hashUrl &&
      release?.securityscan?.reportFileAvailable
    );
  }

  function getFeedbackDialogButton(buttonText) {
    return (
      <CcvText size="medium" color="medium-grey">
        <span
          data-testid="show_feedback_button_security"
          className="download-link no-wrap"
          onClick={() => {
            showFeedbackDialog(true);
          }}
        >
          {t(buttonText ? buttonText : "SHOW_FEEDBACK")}
        </span>
      </CcvText>
    );
  }

  function getJottiBody(progress) {
    switch (progress) {
      case "RUNNING":
        return getResultLabel(
          "SCAN_ONGOING_LABEL",
          "ongoing",
          "jotti_scan_result_label_"
        );
      case "FINISHED":
        return getFinishedJottiBody(
          release?.securityscan?.jotti?.scannerResults
        );
      case "JOTTI_DISABLED":
        return getResultLabel(
          "JOTTI_DISABLED_LABEL",
          "warning",
          "jotti_scan_result_label_"
        );
      case "EXECUTED_MANUALLY":
        if (isJottiResultAvailable()) {
          return getResultLabel(
            "MANUALLY_EXECUTED_LABEL",
            "inactive",
            "jotti_scan_result_label_"
          );
        }
      default:
        return getApiCallFailedText("jotti_scan_failed");
    }
  }

  function getFinishedJottiBody(scannerResults) {
    if (
      scannerResults &&
      release?.securityscan?.jotti?.scannerResults?.filter(
        (ScannerResult) => ScannerResult.packageName
      ).length == 0
    ) {
      return getResultLabel("OK", "success", "jotti_scan_result_label_");
    }
    return getResultLabel("FAILED_TEXT", "failed", "jotti_scan_result_label_");
  }

  function getPermissionsBody(permissions) {
    return getResultLabel(
      permissions?.length + " " + t("USED_TEXT"),
      "warning",
      "permissions_found_amount"
    );
  }

  function getApiCallFailedText(testId) {
    return (
      <CcvText size="medium" color="red" testId={testId}>
        <ShowForRole permission="Employee">
          {t("API_CALL_FAILED_EMPLOYEE_TEXT")}
        </ShowForRole>
        <ShowForRole permission="Developer">
          {t("API_CALL_FAILED_TEXT")}
        </ShowForRole>
      </CcvText>
    );
  }

  function getMobsfBody(progress) {
    switch (progress) {
      case "RUNNING":
        return getResultLabel(
          "SCAN_ONGOING_LABEL",
          "ongoing",
          "mobsf_scan_result_label_"
        );
      case "SCAN_FAILED":
      case "REPORT_FAILED":
      case "UPLOAD_FAILED":
        return getApiCallFailedText("mobsf_scan_failed");
      case "EXECUTED_MANUALLY":
        if (!isMobsfResultAvailable()) {
          return getApiCallFailedText("mobsf_api_failed");
        }
      default:
        return release?.securityscan?.reportFileAvailable ? (
          <DownloadLink
            testId="download_mobsf_report"
            translatedText={t("DOWNLOAD_RAPPORT_LABEL")}
            downloadFunction={() =>
              downloadFromSharepoint(DownloadTypes.SECURITYSCAN_REPORT)
            }
          />
        ) : (
          <CcvText size="normal" color="grey">
            {t("REPORT_NOT_AVAILABLE_TEXT")}
          </CcvText>
        );
    }
  }

  function getResultLabel(text, status, testId) {
    return (
      <ResultLabel
        status={status}
        translatedText={t(text).toLocaleUpperCase()}
        testId={testId + status}
      />
    );
  }

  function getScannerResultList() {
    return release?.securityscan?.jotti?.scannerResults?.map(
      (scannerResult) => {
        return {
          name: scannerResult.name,
          description: scannerResult.unknownResultReason
            ? t("SCAN_RESULT_UNKNOWN_LABEL")
            : scannerResult.packageName,
          status: scannerResult.unknownResultReason ? "UNKNOWN" : "FAILED",
        };
      }
    );
  }

  function getHistoryRowBody(rowInfo, emptyMessage, user, children) {
    if (rowInfo) {
      return (
        <div className="multi-side-block row-table-security-scan">
          <div>
            <ShowForRole permission="Employee">
              <span className="no-wrap">
                <CcvText
                  size="medium"
                  color="dark-grey"
                  testId="securityscan_user"
                >
                  {user ? user : rowInfo.user}
                </CcvText>
              </span>
            </ShowForRole>
            <CcvText
              size="medium"
              color="dark-grey"
              testId={rowInfo.status?.toLowerCase() + "_info"}
            >
              {rowInfo.creationDate &&
                FormatService.getDateWithTimeFormat(rowInfo.creationDate)}
            </CcvText>
          </div>
          {children && children}
        </div>
      );
    } else {
      return (
        <CcvText size="medium" color="grey" testId={emptyMessage.toLowerCase()}>
          {t(emptyMessage)}
        </CcvText>
      );
    }
  }

  return (
    <>
      <span className="spacing-bottom">
        <CcvHeading
          text={t("SECURITY_INFO_HEADING")}
          size="small"
          testId="security_info_heading"
        />
      </span>
      {release ? (
        <div className="multi-side-block gap">
          <div className="left">
            <RowTable
              translate={t}
              data={[
                {
                  columnName: "RELEASE_APK_LABEL",
                  data: "DOWNLOAD_UNSIGNED_LABEL",
                  function: () =>
                    downloadFromSharepoint(DownloadTypes.UNSIGNED_APK),
                  type: "download",
                },
                {
                  columnName: "JOTTI_SCAN_RESULT_LABEL",
                  data: release?.securityscan?.jotti,
                  url:
                    [
                      "FINISHED",
                      "EXECUTED_MANUALLY",
                      "REPORT_FAILED",
                    ]?.includes(release?.securityscan?.jotti?.progress) &&
                    release?.securityscan?.jotti?.scanJobIdUrl,
                  isButton: true,
                  isExternalLink: true,
                  testId: "jotti_scan_result",
                  resultList: release?.securityscan?.jotti,
                  list: getScannerResultList(),
                  element: getJottiBody(release?.securityscan?.jotti?.progress),
                  maxEntries: 3,
                  maxEntriesInfoMessage: t("JOTTI_MAX_ENTRIES_INFO_TEXT"),
                  type: "scanner_result_with_list",
                },
                {
                  columnName: "MOBSF_RAPPORT_LABEL",
                  data: release?.securityscan,
                  url:
                    isMobsfResultAvailable() &&
                    release?.securityscan?.mobsf?.hashUrl,
                  isButton: true,
                  isExternalLink: true,
                  testId: "mobsf_scan_result",
                  element: getMobsfBody(release?.securityscan?.mobsf?.progress),
                  type: "scanner_result_with_list",
                },
              ]}
            />
          </div>
          <div className="right">
            <RowTable
              translate={t}
              data={[
                {
                  columnName: "APK_VALIDATION_LABEL",
                  data: release?.project?.apkInfo,
                  unavailableData: !isPermissionCheckAvailable(),
                  url: "/release/detail/security-checklist",
                  isButton: true,
                  isExternalLink: false,
                  buttonText: "VIEW_LIST_LABEL",
                  testId: "apk_validation_label",
                  element: getResultLabel(
                    "OK",
                    "success",
                    "apk_validation_result_label"
                  ),
                  type: "scanner_result_with_list",
                },
                {
                  columnName: "NO_PERMISSIONS_INFO_HEADING",
                  data: release,
                  unavailableData: !isPermissionDataAvailable(),
                  testId: "detail_permission_list",
                  url: `/release/detail/${release.id}/permissions`,
                  isButton: true,
                  isExternalLink: false,
                  buttonText: "VIEW_LIST_LABEL",
                  testId: "view_list_of_permission_button",
                  element: getPermissionsBody(
                    release?.project?.apkInfo?.permissions
                  ),
                  type: "scanner_result_with_list",
                  emptyMessage:
                    !isPermissionDataAvailable() &&
                    isPermissionCheckAvailable() &&
                    t("NO_DANGEROUS_OR_UNKNOWN_PERMISSIONS_MESSAGE"),
                },
                {
                  columnName: "RELEASESTATUS_SECURITYSCAN_STEPNAME",
                  data:
                    releaseHistory &&
                    getHistoryRowBody(
                      dialogRevisionInfo,
                      "NOT_SCANNED_YET",
                      null,
                      getFeedbackDialogButton()
                    ),
                  type: "element",
                },
              ]}
            />
          </div>
        </div>
      ) : (
        <EmptyIconBlock emptyText="RELEASE_INFO_TEXT_NO_INFO" translate={t} />
      )}
    </>
  );
}

export default SecurityInfoComponent;
