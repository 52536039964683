const ECROnTerminal = {
  ECR_ON_CCV_TERMINAL: {
    key: "ECR_ON_CCV_TERMINAL",
    description: "ECR_ON_TERMINAL_VALUE",
    value: true,
  },
  ECR_NOT_ON_CCV_TERMINAL: {
    key: "ECR_NOT_ON_CCV_TERMINAL",
    description: "ECR_NOT_ON_TERMINAL_VALUE",
    value: false,
  },
};

export default ECROnTerminal;
