import axios from "axios";

const SIGNER_REST_API_URL = process.env.REACT_APP_BACKEND_HOST + "/signer";

export class SignerService {
  authService;

  constructor(authService) {
    this.authService = authService;
  }

  getSignersByGroup() {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(SIGNER_REST_API_URL, {
          headers: { Authorization: token },
        });
      });
  }
}
