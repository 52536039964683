import React, { Component, useEffect, useReducer, useState } from "react";
import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import TooltipText from "../../../domain/TooltipText";
import InfoTextWithIcon from "../../common/InfoTextWithIcon";

function ScreenshotDropzoneWithFiles({
  disabled,
  registerName,
  removeFile,
  changeFile,
  openFileExplorer,
  maxFiles,
  translate,
  infoMessage,
  isDragActive,
  files,
  moveImage,
  isImageFromSharepoint,
}) {
  return (
    <>
      <div className="screenshots-block">
        {files.map((file, key) => (
          <div className="accepted-screenshots" data-testid="screenshots">
            <img
              data-testid={file.name}
              key={key}
              src={file.preview}
              alt="app screenshots"
              className="accepted-screenshots dropzone-previews"
            />
            {!disabled && (
              <div
                className={
                  "dropzone-edit " +
                  (isImageFromSharepoint ? "dropzone-edit-icon" : "")
                }
              >
                <span
                  onClick={() => key != 0 && moveImage(key, key - 1)}
                  hidden={isImageFromSharepoint}
                  data-testid={"move_image_down_" + key}
                >
                  <CcvMaterialIcon
                    iconName="keyboard_arrow_left"
                    size="medium"
                  />
                </span>
                <span
                  onClick={() => {
                    changeFile(key);
                  }}
                  data-testid={"edit_image_" + key}
                >
                  <CcvMaterialIcon iconName="edit" size="medium" />
                </span>
                <span
                  onClick={() => {
                    key != files.length - 1 && moveImage(key, key + 1);
                  }}
                  hidden={isImageFromSharepoint}
                  data-testid={"move_image_up_" + key}
                >
                  <CcvMaterialIcon
                    iconName="keyboard_arrow_right"
                    size="medium"
                  />
                </span>
                <span
                  className="dropzone-delete"
                  onClick={() => removeFile(key)}
                  data-testid={"delete_screenshot_button_" + key}
                >
                  <CcvMaterialIcon iconName="clear" size="medium" />
                </span>
              </div>
            )}
          </div>
        ))}
        {files.length < maxFiles && !disabled && (
          <div
            className={"add-extra " + (isDragActive ? " dropzone-drag" : "")}
            onClick={() => openFileExplorer()}
          >
            <CcvMaterialIcon iconName="add" iconStyle="filled" />
          </div>
        )}
      </div>
      {!isImageFromSharepoint && (
        <InfoTextWithIcon
          translatedInfoText={translate(
            TooltipText.SCREENSHOT_ORDER_INFORMATION
          )}
          testId={"application_info_tooltip"}
        />
      )}
      {infoMessage && (
        <CcvText
          size="small"
          color="medium-grey"
          testId={infoMessage.toLowerCase()}
        >
          {translate(infoMessage)}
        </CcvText>
      )}
    </>
  );
}

function AppIconDropzoneWithFiles({
  files,
  openFileExplorer,
  registerName,
  disabled,
  isDragActive,
  infoMessage,
  translate,
}) {
  return files.map((file) => (
    <>
      {" "}
      <div className="accepted-app-icon">
        <img
          data-testid={registerName}
          key={file}
          src={file.preview}
          alt="app icon"
          className="accepted-app-icon"
        />
        {!disabled && (
          <div
            className={
              "dropzone-edit dropzone-edit-icon" +
              (isDragActive ? " dropzone-drag" : "")
            }
            onClick={() => {
              openFileExplorer();
            }}
          >
            {!isDragActive && <CcvMaterialIcon iconName="edit" size="medium" />}
          </div>
        )}
      </div>
      {infoMessage && (
        <CcvText
          size="small"
          color="medium-grey"
          testId={infoMessage.toLowerCase()}
        >
          {translate(infoMessage)}
        </CcvText>
      )}
    </>
  ));
}

function ImageDropzoneWithoutFiles({
  openFileExplorer,
  registerName,
  isDragActive,
}) {
  return (
    <div
      className={
        (registerName === "appIcon" ? "accepted-app-icon " : "") +
        (isDragActive ? "dropzone-drag " : "") +
        "add-extra"
      }
      onClick={() => openFileExplorer}
    >
      <CcvMaterialIcon iconName="add" iconStyle="filled" />
    </div>
  );
}

export {
  ScreenshotDropzoneWithFiles,
  AppIconDropzoneWithFiles,
  ImageDropzoneWithoutFiles,
};
