const IntegrationType = {
  MAPI: {
    key: "MAPI",
    description: "Android SDK (mAPI)",
    lookupValue: "Android SDK (mAPI)",
    type: "LOCAL",
    documentationUrls: [
      "https://developer.myccv.eu/",
      "https://developer.myccv.eu/documentation/android_sdk/",
    ],
    versions: [
      { id: "1.20", text: "1.20" },
      { id: "1.19", text: "1.19" },
      { id: "1.18", text: "1.18" },
      { id: "1.17", text: "1.17" },
      { id: "1.16", text: "1.16" },
      { id: "OTHER", text: "Other" },
      { id: "NOT_FILLED_IN", text: "I don't know" },
    ],
  },
  PI_REST_API: {
    key: "PI_REST_API",
    description: "Android REST API (PayProxy)",
    lookupValue: "PI REST API",
    type: "LOCAL",
    documentationUrls: [
      "https://developer.myccv.eu/",
      "https://developer.myccv.eu/documentation/android_rest_beta_api/",
    ],
  },
  OPI: {
    key: "OPI",
    description: "OPI",
    lookupValue: "OPI",
    type: "LOCAL",
    documentationUrls: ["https://developer.myccv.eu/"],
  },
  TWO_STEP: {
    key: "TWO_STEP",
    description: "2 STEP",
    lookupValue: "2 STEP",
    type: "LOCAL",
    documentationUrls: ["https://developer.myccv.eu/"],
  },
  OTHER: {
    key: "OTHER",
    description: "Other (ZVT,MDB)",
    lookupValue: "Other",
    type: "LOCAL",
    documentationUrls: ["https://developer.myccv.eu/"],
  },
  CLOUD: {
    key: "CLOUD",
    description: "CCV Cloud Integration",
    lookupValue: "Cloud",
    type: "CLOUD",
    documentationUrls: ["https://developer.myccv.eu/"],
  },
};

export default IntegrationType;
