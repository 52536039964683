import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../config/AppContext";
import { useTranslation } from "react-i18next";
import RequirementTestCatalogue from "../../components/catalogue/RequirementTestCatalogue";
import EmptyIconBlock from "../../components/emptyBlocks/EmptyIconBlock";

function TestCatalogueComponent(props) {
  const { setTitle } = useContext(AppContext);
  const { register } = useForm();
  const location = useLocation();
  const [t] = useTranslation();

  useEffect(() => {
    setTitle(props.title);
  }, []);

  return location?.state?.requirementTest ? (
    <RequirementTestCatalogue
      DialogClosedOnDefault={true}
      register={register}
      defaultCompleted={true}
      requirementTest={location.state.requirementTest}
    />
  ) : (
    <EmptyIconBlock emptyText="EMPTY_CATALOGUE_TEXT" translate={t} />
  );
}

export default TestCatalogueComponent;
