import { convertToHTML } from "draft-convert";
import { convertFromRaw, convertToRaw } from "draft-js";
import i18next from "i18next";
import multiDownload from "multi-download";

function getLocaleLanguage() {
  return (
    (localStorage.getItem("i18nextLng") &&
      localStorage.getItem("i18nextLng") != undefined) ||
    "en-GB"
  );
}

// 26 October 2022
function getMediumDateFormat(date) {
  return new Date(date).toLocaleDateString(getLocaleLanguage(), {
    year: "numeric",
    month: "long",
    day: "2-digit",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
}

//27-10-2022
function getShortDateFormat(date, locale) {
  return new Date(date)?.toLocaleDateString(locale || getLocaleLanguage(), {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
}

//donderdag 27 oktober 2022
function getLongDateFormat(date) {
  return new Date(date)?.toLocaleDateString(getLocaleLanguage(), {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "2-digit",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
}

function getTimeFormat(date) {
  return new Date(date).toLocaleTimeString(getLocaleLanguage(), {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
}

function getDateWithTimeFormat(date) {
  return date && getMediumDateFormat(date) + " - " + getTimeFormat(date);
}

function getHTMLFormat(rawText) {
  return (
    rawText && convertToHTML(convertFromRaw(JSON.parse(rawText))).toString()
  );
}

function getRawFormat(htmlText) {
  return JSON.stringify(convertToRaw(htmlText));
}

function replaceSpecialChar(text, replacementChar) {
  return text.replace(
    /[&\/\\#, +()$~%|'`":;*?<>{}§!]/g,
    replacementChar ? replacementChar : "_"
  );
}

function downloadFileWithNewFilename(link, fileName) {
  fetch(link)
    .then((response) => response.blob())
    .then((response) =>
      multiDownload([URL.createObjectURL(response)], {
        rename: ({ url, index, urls }) => fileName,
      })
    );
}

function getUnsignedFileName(fileName, packageName, version, projectName) {
  return (
    "DC_" +
    projectName?.replaceAll(" ", ".") +
    "_" +
    (packageName ? packageName : fileName.split(".apk")[0]) +
    "_" +
    version +
    "_unsigned.apk"
  );
}

function getApkFileNameWithVersion(version, filename) {
  return filename?.split(".apk")[0] + "_" + version + ".apk";
}

function getColumnsForExport(data, t) {
  return Object.keys(data[0]).map((column) => {
    return new Object({
      title: t("SMARTTABLE_" + column?.toUpperCase() + "_LABEL"),
      field: column,
    });
  });
}

function getFilenameWithDate(filename) {
  return filename + getMediumDateFormat(Date.now()).replaceAll(" ", "_");
}

function onlyUnique(value, index, array) {
  return value && array.indexOf(value) === index;
}

function extractIdFromUrl(url) {
  const pathSegments = new URL(url).pathname
    .split("/")
    .filter((segment) => segment !== "");
  return pathSegments[pathSegments.length - 1];
}

function getPermissionValidationKey(value) {
  return value?.replace(/[.\s]/g, "_") + "_reason";
}

function getPermissionName(key) {
  return key
    .match(/[A-Z_]+/g)
    ?.join("")
    .replace(/^_+|_+$/g, "");
}

export default {
  getShortDateFormat,
  getLongDateFormat,
  getTimeFormat,
  getDateWithTimeFormat,
  getHTMLFormat,
  getRawFormat,
  getMediumDateFormat,
  replaceSpecialChar,
  downloadFileWithNewFilename,
  getApkFileNameWithVersion,
  getUnsignedFileName,
  getColumnsForExport,
  getFilenameWithDate,
  onlyUnique,
  extractIdFromUrl,
  getPermissionValidationKey,
  getPermissionName,
};
