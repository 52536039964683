import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../config/AppContext";
import BasicTable from "../../components/tables/BasicTable";
import { useLocation } from "react-router-dom";
import SpinnerService from "../../services/SpinnerService";
import EmptyIconBlock from "../../components/emptyBlocks/EmptyIconBlock";
import { ReleaseService } from "../../services/ReleaseService";
import LoadingBlock from "../../components/Loading/LoadingBlock";

function AllReleasesComponent(props) {
  const { setTitle, authService } = useContext(AppContext);
  let releaseService = new ReleaseService(authService);
  const [t] = useTranslation();
  const location = useLocation();
  const [releases, setReleases] = useState();
  const [waitingForBackendResponse, setWaitingForBackendResponse] =
    useState(false);

  function fetchReleaseData() {
    SpinnerService.errorSpinner(
      releaseService
        .getReleasesByProjectId(location?.state?.projectId)
        .then((response) => {
          setReleases(response.data.content);
        }),
      t,
      setWaitingForBackendResponse
    );
  }

  useEffect(() => {
    setWaitingForBackendResponse(true);
    setTitle(t(props.title) + " " + (location?.state?.projectName || ""));
    fetchReleaseData();
  }, []);

  return (
    <div className="home info-block" data-testid="all_releases_table">
      {waitingForBackendResponse ? (
        <div className="empty-block">
          <LoadingBlock />
        </div>
      ) : releases ? (
        <BasicTable
          data={releases}
          waitingForBackendResponse={waitingForBackendResponse}
          title={"release"}
          filter={["creationDate", "version", "lastUpdated", "releaseStatus"]}
          showDownloadSignedButton={true}
          showDownloadUnsignedButton={true}
          navigationPage="release"
        />
      ) : (
        <EmptyIconBlock
          emptyText="PROJECT_INFO_TEXT_NO_RELEASES"
          translate={t}
        />
      )}
    </div>
  );
}

export default AllReleasesComponent;
