import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../config/AppContext";
import HistoryTable from "../../components/tables/HistoryTable";
import "../../style/Detail.scss";
import UpdateDialog from "../../components/dialogs/UpdateDialog";
import {
  CcvButton,
  CcvHeading,
  CcvMaterialIcon,
  CcvMessage,
  CcvText,
} from "@ccv-oc-myccv/ccv-react-components";
import InfoDialog from "../../components/dialogs/InfoDialog";
import StepIndicator from "../../components/progressbars/StepIndicator";
import ReleaseStatus from "../../domain/ReleaseStatus";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ShowForRole } from "../../components/ShowForRole";
import ReleaseInfoComponent from "./navigatorComponents/infoReleaseComponents/ReleaseInfoComponent";
import SpinnerService from "../../services/SpinnerService";
import TabNavigator from "../../components/TabNavigator";
import FunctionalTestingInfoComponent from "./navigatorComponents/infoReleaseComponents/FunctionalTestingInfoComponent";
import SigningInfoComponent from "./navigatorComponents/infoReleaseComponents/SigningInfoComponent";
import SecurityInfoComponent from "./navigatorComponents/infoReleaseComponents/SecurityInfoComponent";
import ApplicationInfoComponent from "./navigatorComponents/infoReleaseComponents/ApplicationInfoComponent";
import { ReleaseService } from "../../services/ReleaseService";
import { ProjectService } from "../../services/ProjectService";
import multiDownload from "multi-download";
import Role from "../../domain/Role";
import { CcvStoreInfoService } from "../../services/CcvStoreInfoService";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { Spinner } from "../../components/Loading/Spinner";
import { toast } from "react-toastify";
import FormatService from "../../services/FormatService";
import ContractCheckbox from "../../components/form/ContractCheckbox";
import DownloadTypes from "../../domain/DownloadType";

function ReleaseDetailComponent({ setIncompletePermissionList, ...props }) {
  const { setTitle, setProjectInfo, authService } = useContext(AppContext);
  let releaseService = new ReleaseService(authService);
  let ccvStoreService = new CcvStoreInfoService(authService);
  let projectService = new ProjectService(authService);
  const [roles, setRoles] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const releaseId = useParams().id;
  const [release, setRelease] = useState();
  const [releaseHistory, setReleaseHistory] = useState();
  const [securityScanRevisions, setSecurityScanRevisions] = useState([]);
  const [ccvStoreInfo, setCcvStoreInfo] = useState();
  const [activeSection, setActiveSection] = useState("GENERAL_INFO");
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [isUpdatingRelease, setIsUpdatingRelease] = useState(false);
  const [t] = useTranslation();
  const [paymentEngineVersions, setPaymentEngineVersions] = useState();
  const [downloadFileType, setDownloadFileType] = useState(
    DownloadTypes.UNSIGNED_APK
  );
  const [activeBodyInfo, setActiveBodyInfo] = useState();
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const [activeRevisionInfo, setActiveRevisionInfo] = useState(null);
  let securityScanRevisionInfo = getHistoryInfo([
    "SECURITYSCAN_SUCCESS",
    "SECURITYSCAN_MORE_INFO",
    "SECURITYSCAN_FAILED",
  ]);
  let signingRevisionInfo = getHistoryInfo([
    "SIGNING_SUCCESS",
    "SIGNING_FAILED",
  ]);
  let functionalTestingRevisionInfo = getHistoryInfo([
    "FUNCTIONAL_TESTING_SKIPPED",
    "FUNCTIONAL_TESTING_FAILED",
    "FUNCTIONAL_TESTING_SUCCESS",
  ]);

  function getHistoryInfo(filter) {
    return releaseHistory
      ?.filter((revision) => filter.includes(revision.status))
      .reverse()[0];
  }

  function getRoles() {
    authService.getRoles().then((response) => {
      setRoles(response);
    });
  }

  async function fetchReleaseData() {
    if (authService.isEmployee(roles)) {
      return fetchReleaseInfoAsEmployee();
    } else {
      return fetchReleaseInfoAsDeveloper();
    }
  }

  function fetchReleaseInfoAsEmployee() {
    return releaseService
      .getReleaseById(releaseId)
      .then((response) => {
        setReleaseData(response.data);
        fetchReleaseHistoryAndCcvStoreInfo(response.data.project.id);
      })
      .catch((err) => {
        fetchReleaseHistoryAndCcvStoreInfo();
        throw err;
      });
  }

  function fetchReleaseInfoAsDeveloper() {
    return releaseService
      .getReleaseByIdAndBupaId(releaseId, authService.getBupaId())
      .then((response) => {
        setReleaseData(response.data);
        response?.data?.project &&
          fetchReleaseHistoryAndCcvStoreInfo(response.data.project.id);
      })
      .catch((err) => {
        if ([401, 403].includes(err.response?.status)) {
          setIsAccessDenied(true);
          toast(
            <CcvMessage
              type="error"
              text={t("TOAST_ACCESS_DENIED")}
              testId="not_your_release_error"
            />
          );
        } else {
          fetchReleaseHistoryAndCcvStoreInfo();
          throw err;
        }
      });
  }

  async function fetchCcvStoreInfo(projectId) {
    return trackPromise(
      ccvStoreService.getCcvStoreInfo(projectId).then((response) => {
        setCcvStoreInfo(response.data);
      }),
      "ccvStore-area"
    );
  }

  async function fetchReleaseHistory() {
    return trackPromise(
      SpinnerService.errorSpinner(
        releaseService.getReleaseHistory(releaseId).then((response) => {
          setReleaseHistory(response.data);
        }),
        t
      ),
      "history-area"
    );
  }

  function fetchReleaseHistoryAndCcvStoreInfo(projectId) {
    SpinnerService.errorSpinner(
      Promise.all([fetchReleaseHistory(), fetchCcvStoreInfo(projectId)]),
      t
    );
  }

  function setReleaseData(responseData) {
    setRelease(responseData);
    setPaymentEngineVersions(
      setInitialEngineVersions(
        responseData.requirementTest.paymentEngineVersions
      )
    );
    setTitle(
      (responseData.applicationName
        ? responseData.applicationName
        : t(props.title)) +
        " - " +
        responseData.version
    );
    setProjectInfo(responseData.project);
    setActiveSection(
      localStorage.getItem("activeSection")
        ? localStorage.getItem("activeSection")
        : ReleaseStatus[responseData.releaseStatus].activeTabSection
    );
    localStorage.removeItem("activeSection");
  }

  function assignReleaseTo(user) {
    setIsUpdatingRelease(true);
    SpinnerService.defaultSpinner(
      releaseService.patchReleaseInfo(releaseId, {
        lastEditedBy: release.lastEditedBy,
        lastUpdated: release.lastUpdated,
        assignedTo: user,
      }),
      t,
      "TOAST_PROCESSING",
      user === "NOT_ASSIGNED"
        ? "TOAST_RELEASE_UNASSIGNED"
        : "TOAST_RELEASE_ASSIGNED_TO",
      setIsUpdatingRelease
    );
  }

  function CheckAssignedAndDownload(fileType) {
    if (
      authService.isEmployee(roles) &&
      [
        ReleaseStatus.SECURITYSCAN_SUCCESS.key,
        ReleaseStatus.FUNCTIONAL_TESTING_SKIPPED.key,
        ReleaseStatus.FUNCTIONAL_TESTING_SUCCESS.key,
      ].includes(release.releaseStatus)
    ) {
      if (
        release.assignedTo === authService.getEmail() ||
        !ReleaseStatus[release.releaseStatus].infoLabelEmployee.assignOption
      ) {
        return downloadFromSharepoint(fileType);
      }
      setActiveBodyInfo(
        ReleaseStatus[release.releaseStatus].infoLabelEmployee.dialogInfo
      );
      setDownloadFileType(fileType);
      setOpenUpdateDialog(true);
    } else {
      return downloadFromSharepoint(fileType);
    }
  }

  function downloadFromSharepoint(fileType) {
    SpinnerService.defaultSpinner(
      releaseService.getDownloadLink(release.id, fileType).then((response) => {
        return FormatService.downloadFileWithNewFilename(
          response.data.downloadUrl,
          fileType === DownloadTypes.UNSIGNED_APK
            ? FormatService.getUnsignedFileName(
                response.data.fileName,
                release.project?.apkInfo?.packageName,
                release.version,
                release.project?.projectName
              )
            : response.data.fileName
        );
      }),
      t,
      "TOAST_DOWNLOADING",
      "TOAST_DOWNLOAD_SUCCEEDED"
    );
  }

  function downloadImagesFromSharepoint(fileType, files) {
    return SpinnerService.defaultSpinner(
      Promise.all(
        files.map((file) =>
          projectService
            .getDownloadLink(release.project.id, fileType, file.name)
            .then((response) => {
              return response.data;
            })
        )
      ).then((urls) => {
        multiDownload(urls);
      }),
      t,
      "TOAST_DOWNLOADING",
      "TOAST_DOWNLOAD_SUCCEEDED"
    );
  }

  function setInitialEngineVersions(paymentEngineVersions) {
    return paymentEngineVersions.map((engine) => {
      return {
        key: engine.name,
        value: engine.version,
        isOtherOptionSelected: false,
        initialValue: engine.version,
      };
    });
  }

  useEffect(() => {
    location.state?.detail?.version &&
      setTitle(
        (location.state.detail.applicationName
          ? location.state.detail.applicationName
          : t(props.title)) +
          " - " +
          location.state.detail.version
      );
    setIncompletePermissionList([]);
    getRoles();
  }, []);

  useEffect(() => {
    if (release && !isUpdatingRelease) {
      fetchReleaseData();
    }
  }, [isUpdatingRelease]);

  useEffect(() => {
    roles &&
      trackPromise(
        SpinnerService.errorSpinner(fetchReleaseData(), t),
        "release-area"
      );
  }, [roles]);

  useEffect(() => {
    if (releaseHistory) {
      setSecurityScanRevisions(getSecurityscanRevisions());
    }
  }, [releaseHistory]);

  function openUpdateForm() {
    if (
      [
        "FUNCTIONAL_TESTING_SKIPPED",
        "FUNCTIONAL_TESTING_SUCCESS",
        "SECURITYSCAN_MORE_INFO",
        "SECURITYSCAN_FAILED",
      ].includes(release.releaseStatus)
    ) {
      navigate("/release/detail/securityscan", {
        state: {
          release: release,
        },
      });
    }
    setActiveBodyInfo({
      key: release.releaseStatus,
      title: ReleaseStatus[release.releaseStatus]?.nextActionTitle,
    });
    setOpenUpdateDialog(true);
  }

  function getUpdateButton(text) {
    return (
      <div className="status-button">
        <CcvButton
          size="normal"
          type="primary"
          text={t(text)}
          onClick={() => openUpdateForm()}
          testId={text?.toLowerCase()}
          disabled={!hasLastFeedbackResponse()}
        />
      </div>
    );
  }

  function getUpdateDialog() {
    return (
      <UpdateDialog
        open={openUpdateDialog}
        onDisagree={() => setOpenUpdateDialog(false)}
        popupTitle={t(
          activeBodyInfo.title
            ? activeBodyInfo.title
            : release.assignedTo === "NOT_ASSIGNED"
            ? activeBodyInfo.titleUnassigned
            : activeBodyInfo.titleAssigned
        )}
        releaseId={releaseId}
        bodyKey={activeBodyInfo.key}
        updating={isUpdatingRelease}
        setUpdating={setIsUpdatingRelease}
        downloadApkInfo={downloadFromSharepoint}
        downloadFileType={downloadFileType}
        release={release}
        assignedInfo={activeBodyInfo}
      />
    );
  }

  function getUpdateButtonBody() {
    if (
      ReleaseStatus[release.releaseStatus].overruleAction &&
      isSecurityscanOverrulable()
    ) {
      return (
        <ShowForRole permission="Employee">
          {hasLastFeedbackResponse() &&
            getUpdateButton(
              ReleaseStatus[release.releaseStatus].overruleAction
            )}
        </ShowForRole>
      );
    }
    if (
      ReleaseStatus[release.releaseStatus].nextAction &&
      !ReleaseStatus[release.releaseStatus].overruleAction
    ) {
      return (
        <ShowForRole permission="Employee">
          {getUpdateButton(ReleaseStatus[release.releaseStatus].nextAction)}
          {activeBodyInfo && getUpdateDialog()}
        </ShowForRole>
      );
    }
  }

  function hasLastFeedbackResponse() {
    if (
      ["SECURITYSCAN_MORE_INFO", "SECURITYSCAN_FAILED"].includes(
        release?.releaseStatus
      )
    ) {
      return !!release?.securityscan?.feedback[
        release?.securityscan?.feedback?.length - 1
      ]?.response?.answer;
    }
    return true;
  }

  function getSecurityscanRevisions() {
    const modifiedHistoryItems = releaseHistory
      ?.filter((item) => item?.modifiedValues)
      .map((item) => item.creationDate);

    const filteredSecurityScanStatuses = releaseHistory
      ?.filter((item) => item?.status?.includes("SECURITYSCAN"))
      .filter((item) => !modifiedHistoryItems.includes(item.creationDate));
    return filteredSecurityScanStatuses;
  }

  function isSecurityscanOverrulable() {
    return (
      securityScanRevisions[0]?.status === "SECURITYSCAN_FAILED" &&
      securityScanRevisions[1]?.status !== "SECURITYSCAN_MORE_INFO" &&
      securityScanRevisions[1]?.status !== "SECURITYSCAN_FAILED"
    );
  }

  function getEmployeeMessage() {
    if (!ReleaseStatus[release.releaseStatus]?.infoLabelEmployee) {
      return null;
    }
    if (
      !hasLastFeedbackResponse() &&
      ReleaseStatus[release.releaseStatus]?.infoLabelEmployeeNoResponseText
    ) {
      return getWaitForResponseEmployeeMessage();
    }
    if (hasLastFeedbackResponse() && isSecurityscanOverrulable()) {
      return getOverruleRequestMessage();
    } else if (
      release.assignedTo !== "NOT_ASSIGNED" &&
      release.assignedTo !== authService.getEmail()
    ) {
      return getDefaultEmployeeAssignToMessage();
    } else if (!["SECURITYSCAN_FAILED"].includes(release?.releaseStatus)) {
      return getDefaultEmployeeMessage();
    }
  }

  function getOverruleRequestMessage() {
    return (
      <CcvMessage
        text={
          <div className="message-block message-block-row">
            <p>
              {t(
                ReleaseStatus[release.releaseStatus].infoLabelEmployee
                  .textOverrule
              )}
            </p>
          </div>
        }
        type={"info"}
        testId={ReleaseStatus[
          release.releaseStatus
        ].infoLabelEmployee.textOverrule?.toLowerCase()}
      />
    );
  }

  function getDefaultEmployeeAssignToMessage() {
    return (
      <CcvMessage
        text={
          <div className="message-block">
            <p>
              {t(
                ReleaseStatus[release.releaseStatus].infoLabelEmployee
                  .assignedText
              )}{" "}
              "{release.assignedTo}"
            </p>
          </div>
        }
        type={"info"}
        testId={ReleaseStatus[
          release.releaseStatus
        ].infoLabelEmployee.assignedText?.toLowerCase()}
      />
    );
  }

  function getDefaultEmployeeMessage() {
    return (
      <CcvMessage
        text={
          <div className="message-block message-block-row">
            <p>
              {t(
                release.assignedTo === authService.getEmail()
                  ? ReleaseStatus[release.releaseStatus].infoLabelEmployee
                      .textAssigned
                  : ReleaseStatus[release.releaseStatus].infoLabelEmployee
                      .textUnassigned
              )}
            </p>
            {ReleaseStatus[release.releaseStatus].infoLabelEmployee
              .assignOption && (
              <ContractCheckbox
                translate={t}
                testId={"assign_to_me_checkbox"}
                setCheckedFunction={(item) =>
                  assignReleaseTo(
                    release.assignedTo === "NOT_ASSIGNED"
                      ? authService.getEmail()
                      : "NOT_ASSIGNED"
                  )
                }
                isChecked={release.assignedTo != "NOT_ASSIGNED"}
                disabled={isUpdatingRelease}
                text={
                  ReleaseStatus[release.releaseStatus].infoLabelEmployee
                    .checkboxText
                }
              />
            )}
          </div>
        }
        type={"info"}
        testId={(release.assignedTo === authService.getEmail()
          ? ReleaseStatus[release.releaseStatus].infoLabelEmployee.textAssigned
          : ReleaseStatus[release.releaseStatus].infoLabelEmployee
              .textUnassigned
        )?.toLowerCase()}
      />
    );
  }

  function getWaitForResponseEmployeeMessage() {
    return (
      <CcvMessage
        text={
          <div className="message-block">
            <p>
              {t(
                ReleaseStatus[release.releaseStatus]
                  .infoLabelEmployeeNoResponseText
              )}
            </p>
          </div>
        }
        type={"info"}
        testId={ReleaseStatus[
          release.releaseStatus
        ].infoLabelEmployeeNoResponseText?.toLowerCase()}
      />
    );
  }

  function getIntegratorMessage() {
    return (
      <CcvMessage
        text={<div className="message-block">{getIntegratorMessageBody()}</div>}
        type={handleIntegratorMessageType()}
        testId={
          ["SECURITYSCAN_MORE_INFO", "SECURITYSCAN_FAILED"].includes(
            release?.releaseStatus
          ) && hasLastFeedbackResponse()
            ? ReleaseStatus[
                release.releaseStatus
              ].infoLabelIntegratorHasResponded?.toLowerCase()
            : ReleaseStatus[release.releaseStatus].infoLabel?.toLowerCase()
        }
      />
    );
  }

  function getIntegratorMessageBody() {
    return (
      <>
        {getIntegratorMessageLabel()}
        {(release.releaseStatus.includes("FAILED") ||
          release.releaseStatus.includes("MORE_INFO")) &&
          !hasLastFeedbackResponse() &&
          getIntegratorMessageButtons()}
      </>
    );
  }

  function handleIntegratorMessageType() {
    if (
      release.releaseStatus.includes("FAILED") &&
      !hasLastFeedbackResponse()
    ) {
      return "error";
    } else if (
      release.releaseStatus.includes("MORE_INFO") &&
      !hasLastFeedbackResponse()
    ) {
      return "alert";
    } else {
      return "info";
    }
  }

  function getIntegratorMessageLabel() {
    return (
      <div className="message-block-label-with-icon">
        {(release.releaseStatus.includes("FAILED") ||
          release.releaseStatus.includes("MORE_INFO")) &&
          !hasLastFeedbackResponse() && (
            <CcvMaterialIcon
              iconName={
                release.releaseStatus.includes("FAILED") ? "error" : "report"
              }
              size="medium"
            />
          )}
        <p>
          {t(
            ["SECURITYSCAN_MORE_INFO", "SECURITYSCAN_FAILED"].includes(
              release?.releaseStatus
            ) && hasLastFeedbackResponse()
              ? ReleaseStatus[release.releaseStatus]
                  .infoLabelIntegratorHasResponded
              : ReleaseStatus[release.releaseStatus].infoLabel
          )}
        </p>
      </div>
    );
  }

  function getIntegratorMessageButtons() {
    return (
      <div className="button-row">
        <CcvButton
          type="secondary"
          size="small"
          text={t("READ_FEEDBACK")}
          onClick={() => {
            setActiveRevisionInfo(getHistoryInfo([release?.releaseStatus]));
            setOpenFeedbackDialog(true);
          }}
          testId={"integrator_read_feedback"}
          disabled={!release}
          extraClass={
            release.releaseStatus.includes("FAILED")
              ? "failed-button"
              : "more-info-button"
          }
        />
        {release.releaseStatus.includes("FAILED") && (
          <span
            data-testid="create_release_button"
            className="download-link no-wrap failed-button"
            onClick={(event) => {
              navigate("/release/createRelease", {
                state: {
                  projectId: release?.project?.id,
                  projectName: release?.project?.projectName,
                  integrationType: release?.project?.integrationType,
                  paymentEngineTypes: release?.project?.paymentEngineTypes,
                },
              });
            }}
          >
            {t("CREATE_NEW_RELEASE_TEXT")}
          </span>
        )}
      </div>
    );
  }

  function getStatusMessage() {
    return (
      <>
        <ShowForRole permission="Developer">
          {getIntegratorMessage()}
        </ShowForRole>
        <ShowForRole permission="Employee">
          {release &&
            ReleaseStatus[release.releaseStatus].infoLabelEmployee &&
            getEmployeeMessage()}
        </ShowForRole>
      </>
    );
  }

  return (
    <>
      <div className="content-block">
        {release && releaseHistory && (
          <div className="progress-block">
            <Spinner
              area="release-area"
              dataIsFetched={releaseHistory}
              accessDenied={isAccessDenied}
            >
              <span className="stepper-block">
                <StepIndicator
                  statusPool={ReleaseStatus}
                  currentStatus={release.releaseStatus}
                  possibleSteps={[
                    "CREATED",
                    "FUNCTIONAL_TESTING_SUCCESS",
                    "SECURITYSCAN_SUCCESS",
                    "SIGNING_SUCCESS",
                    "AVAILABLE_IN_STORE",
                  ]}
                />
                {getUpdateButtonBody()}
              </span>
              {getStatusMessage()}
            </Spinner>
          </div>
        )}
        <TabNavigator
          sections={{
            GENERAL_INFO: {
              title: "RELEASE_INFO_HEADING",
              block: (
                <Spinner
                  area="release-area"
                  dataIsFetched={release}
                  accessDenied={isAccessDenied}
                >
                  <ReleaseInfoComponent
                    release={release}
                    releaseHistory={releaseHistory}
                    setIsUpdatingRelease={setIsUpdatingRelease}
                    downloadFromSharepoint={() =>
                      CheckAssignedAndDownload(DownloadTypes.UNSIGNED_APK)
                    }
                    setInitialEngineVersions={setInitialEngineVersions}
                    setPaymentEngineVersions={setPaymentEngineVersions}
                    paymentEngineVersions={paymentEngineVersions}
                  />
                </Spinner>
              ),
              activated: true,
            },
            FUNCTIONAL_TESTING: {
              title: "FUNCTIONAL_TESTING_HEADING",
              block: (
                <Spinner area="release-area" dataIsFetched={release}>
                  <FunctionalTestingInfoComponent
                    release={release}
                    downloadFromSharepoint={() =>
                      CheckAssignedAndDownload(DownloadTypes.UNSIGNED_APK)
                    }
                    releaseHistory={releaseHistory}
                    paymentEngineVersions={paymentEngineVersions}
                    dialogRevisionInfo={functionalTestingRevisionInfo}
                    showFeedbackDialog={() => {
                      setActiveRevisionInfo(functionalTestingRevisionInfo);
                      setOpenFeedbackDialog(true);
                    }}
                  />
                </Spinner>
              ),
              activated: true,
            },
            SECURITYSCAN: {
              title: "SECURITYSCAN_HEADING",
              block: (
                <Spinner
                  area="history-area"
                  accessDenied={isAccessDenied}
                  dataIsFetched={releaseHistory}
                >
                  <SecurityInfoComponent
                    release={release}
                    roles={roles}
                    downloadFromSharepoint={CheckAssignedAndDownload}
                    releaseHistory={releaseHistory}
                    dialogRevisionInfo={securityScanRevisionInfo}
                    showFeedbackDialog={() => {
                      setActiveRevisionInfo(securityScanRevisionInfo);
                      setOpenFeedbackDialog(true);
                    }}
                  />
                </Spinner>
              ),
              activated: true,
            },
            SIGNING: {
              title: "SIGNING_HEADING",
              block: (
                <Spinner
                  area="history-area"
                  accessDenied={isAccessDenied}
                  dataIsFetched={releaseHistory}
                >
                  <SigningInfoComponent
                    release={release}
                    downloadFromSharepoint={() =>
                      CheckAssignedAndDownload(DownloadTypes.UNSIGNED_APK)
                    }
                    releaseHistory={releaseHistory}
                    dialogRevisionInfo={signingRevisionInfo}
                    showFeedbackDialog={() => {
                      setActiveRevisionInfo(signingRevisionInfo);
                      setOpenFeedbackDialog(true);
                    }}
                  />
                </Spinner>
              ),
              activated: true,
            },
            APPLICATION_INFO: {
              title: "APPLICATION_INFO_HEADING",
              block: (
                <Spinner
                  area="ccvStore-area"
                  accessDenied={isAccessDenied}
                  dataIsFetched={ccvStoreInfo}
                >
                  <ApplicationInfoComponent
                    release={release}
                    ccvStoreInfo={ccvStoreInfo}
                    downloadFromSharepoint={downloadFromSharepoint}
                    downloadImagesFromSharepoint={downloadImagesFromSharepoint}
                  />
                </Spinner>
              ),
              activated: true,
            },
          }}
          activeSection={activeSection}
        />

        <div className="info-block" data-testid="releasehistory">
          <Spinner
            area="history-area"
            accessDenied={isAccessDenied}
            dataIsFetched={releaseHistory}
          >
            <CcvHeading
              text={t("RELEASE_HISTORY_LABEL")}
              size="small"
              testId="release_history_title"
            />
            <HistoryTable
              data={releaseHistory}
              showFields={["creationDate", "status", "user", "revisionType"]}
              feedback={release?.feedback}
              showTitle={true}
              showReleaseVersionInRow={false}
              releaseId={release?.id}
              lastUserUpdate={{
                user: release?.lastEditedBy,
                release: release?.version,
              }}
            />
            <InfoDialog
              open={openFeedbackDialog}
              onDisagree={() => setOpenFeedbackDialog(false)}
              dialogTitle={
                activeRevisionInfo &&
                ReleaseStatus[activeRevisionInfo.status].stepName
              }
              dialogSubtitle="DIALOG_FEEDBACK"
              dialogInfo={
                activeRevisionInfo && {
                  releaseId: activeRevisionInfo.id,
                  releaseInfo:
                    release.securityscan?.jotti?.scannerResults?.filter(
                      (scannerResult) => scannerResult.packageName
                    ),
                  releaseStatus: activeRevisionInfo.status,
                  signing: activeRevisionInfo.signing,
                  functionalTest: activeRevisionInfo.functionalTest,
                  securityscan: activeRevisionInfo.securityscan,
                  permissions: release?.project?.apkInfo?.permissions,
                  date: FormatService.getDateWithTimeFormat(
                    activeRevisionInfo.creationDate
                  ),
                  ...activeRevisionInfo,
                }
              }
              releaseService={releaseService}
              securityScanRevisions={securityScanRevisions}
              releaseActiveStatus={release?.releaseStatus}
              fetchReleaseInfo={fetchReleaseInfoAsDeveloper}
            />
          </Spinner>
        </div>
      </div>
    </>
  );
}

export default ReleaseDetailComponent;
