import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";
import LoadingBlock from "../../Loading/LoadingBlock";

function ApkDropzoneWithFiles({
  files,
  OpenFileExplorer,
  removeAllFiles,
  translate,
  isDragActive,
  processing,
  disabled,
}) {
  return files.map((file) => (
    <>
      <div
        className={
          "dropzone large-dropzone apk-dropzone " +
          (isDragActive && " apk-dropzone-hover")
        }
        onClick={() => !disabled && OpenFileExplorer()}
      >
        <span>
          <CcvMaterialIcon iconName="backup" size="extra-large" />
          {processing ? (
            <CcvText size="small" color="grey">
              {translate("APK_PROCESSING_TEXT")+"..."}
            </CcvText>
          ) : (
            <CcvText size="small" color="grey">
              {translate("DROPZONE_CHANGE_APK")}
            </CcvText>
          )}
        </span>
      </div>
      <span className="acceptedfile">
        <CcvText size="medium" color="dark-grey">
          <CcvMaterialIcon
            iconName="description"
            size="small"
            iconStyle="outlined"
          />
          {file.path} - {Math.round(file.size / 10000) / 100} MB{" "}
        </CcvText>
        {processing ? (
          <LoadingBlock
            translatedText={translate("APK_PROCESSING_TEXT")}
            className="lottie-small"
          />
        ) : (
          <span class="cancelButton" onClick={() => removeAllFiles()}>
            <CcvMaterialIcon iconName="delete_outline"></CcvMaterialIcon>
          </span>
        )}
      </span>
    </>
  ));
}

function ApkDropzoneWithoutFiles({ translate, isDragActive, required }) {
  return (
    <div
      className={
        "dropzone large-dropzone apk-dropzone " +
        (isDragActive && " apk-dropzone-hover")
      }
    >
      <CcvMaterialIcon iconName="backup" size="extra-large" />
      <div>
        <span className="dropzone-drop-file-text">
          <CcvText size="medium" color="dark-grey">
            {translate("RELEASE_DROPZONE_DROP_FILE")}
          </CcvText>
          <CcvText size="medium" color="red">
            &nbsp;{required ? "*" : ""}
          </CcvText>
        </span>
        <CcvText size="small" color="grey">
          {translate("RELEASE_DROPZONE_ADD_APK_FILE")}
        </CcvText>
      </div>
    </div>
  );
}

export { ApkDropzoneWithFiles, ApkDropzoneWithoutFiles };
