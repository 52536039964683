import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../style/CreateForm.scss";
import { AppContext } from "../../config/AppContext";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import { CcvButton } from "@ccv-oc-myccv/ccv-react-components";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import FormValidation from "../../domain/FormValidation";
import ValidationError from "../../components/form/ValidationError";
import SpinnerService from "../../services/SpinnerService";
import TextInput from "../../components/form/TextInput";
import { ProjectService } from "../../services/ProjectService";
import ProjectCatalogue from "../../components/catalogue/ProjectCatalogue";
import Selector from "../../components/form/Selector";
import TooltipText from "../../domain/TooltipText";
import ProjectSolution from "../../domain/ProjectSolution";
import { CreateProjectYupValidation } from "../../yupValidation/CreateProjectYupValidation";
import EmptyIconBlock from "../../components/emptyBlocks/EmptyIconBlock";

function CreateProjectComponent(props) {
  const { setTitle, authService } = useContext(AppContext);
  let projectService = new ProjectService(authService);
  const [roles, setRoles] = useState();
  const location = useLocation();
  const [waitingForBackendResponse, setWaitingForBackendResponse] =
    useState(false);
  const [project, setProject] = useState(location.state?.project);
  const [solution, setSolution] = useState(project?.solution);
  const navigate = useNavigate();
  const [t] = useTranslation();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    clearErrors,
    trigger,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(CreateProjectYupValidation()),
    defaultValues: project && {
      solution: project.solution,
      projectName: project.projectName,
      technology: project.technology,
      environment: project.environment,
      paymentEngineTypes: project.paymentEngineTypes,
      ecronTerminal: project.ecronTerminal,
      segments: project.segments,
      terminals: project.terminals,
      integrationType: project.integrationType,
    },
  });

  useEffect(() => {
    setTitle(props.title);
    setSelectedValue(ProjectSolution.TERMINAL.selector.id);
    getRoles();
  }, []);

  async function getRoles() {
    await authService.getRoles().then((response) => {
      setRoles(response);
    });
  }

  function setProjectData(data) {
    data.lastEditedBy = authService.getEmail();
    if (!project) {
      data.userId = authService.getUserId();
      data.bupaName = authService.getBupaName();
      data.bupaId = authService.getBupaId();
      data.createdBy = authService.getEmail();
    }
  }

  function isValidUserOnPage() {
    return (
      authService.isDeveloper(roles) ||
      (authService.isEmployee(roles) && location?.state?.project)
    );
  }

  function createProject(data) {
    return projectService.createProject(data).then((response) => {
      navigate("/project/detail/" + response.data?.id, {
        state: { detail: response.data },
      });
    });
  }

  function updateProject(data) {
    return projectService
      .updateProjectinfo(project.id, data)
      .then((response) => {
        navigate("/project/detail/" + response.data?.id, {
          state: { detail: response.data },
        });
      });
  }

  async function onSubmit(data) {
    setWaitingForBackendResponse(true);
    setProjectData(data);

    SpinnerService.defaultSpinner(
      project ? updateProject(data) : createProject(data),
      t,
      "TOAST_PROCESSING",
      project ? "TOAST_PROJECT_UPDATED" : "TOAST_PROJECT_CREATED",
      setWaitingForBackendResponse
    );
  }

  function setSelectedValue(solution) {
    const projectName = getValues("projectName");
    reset();
    setValue("solution", solution);
    setSolution(solution);
    setValue("projectName", projectName);
  }

  return isValidUserOnPage() ? (
    <form className="form" data-testid="createProjectForm">
      <div className="multi-side-block white-block">
        <div className="left">
          <TextInput
            className={"no-spacing"}
            defaultInputData={project && project.projectName}
            field="projectName"
            setValue={setValue}
            required={true}
            placeholder={"INTEGRATION_PLACEHOLDER"}
            tooltipInfo={TooltipText.INTEGRATION_NAME}
            maxLength={FormValidation.MAX_PROJECT_NAME_LENGTH}
            label="PROJECT_NAME_LABEL"
            tooltipTitle={t("INFO_LABEL_INTEGRATION_NAME")}
            shouldValidateOnInput={false}
            clearErrors={clearErrors}
            validationError={
              !isValid && (
                <ValidationError
                  error={errors["projectName"]}
                  errorText={t("PROJECT_INFO_TEXT_ENTER_NAME")}
                  testId="project_name_error"
                />
              )
            }
          />
        </div>
        <div className="right">
          <Selector
            testId={"solution"}
            registerName={"solution"}
            options={Object.values(ProjectSolution).map(
              (solution) => solution.selector
            )}
            initialSelectedItem={ProjectSolution.TERMINAL.selector}
            tooltipInfo={TooltipText.SOLUTION}
            labelText={"INTEGRATION_SOLUTION_LABEL"}
            setSelectedResult={setSelectedValue}
            control={control}
            translate={t}
            validationError={
              <ValidationError
                error={errors["solution"]}
                errorText={t("PROJECT_SOLUTION_REQUIRED")}
                testId="project_solution_error"
              />
            }
            disabled={true}
            infoText={"TERMINAL_SELECTOR_DISABLED_TEXT"}
          />
        </div>
      </div>

      {solution && (
        <ProjectCatalogue
          defaultInputData={project}
          solution={solution}
          translate={t}
          errors={errors}
          control={control}
          register={register}
          setValue={setValue}
          validate={trigger}
        />
      )}

      <div className="button-block">
        <CcvButton
          disabled={waitingForBackendResponse}
          type="tertiary"
          size="normal"
          text={t("CANCEL_LABEL")}
          onClick={() => navigate(-1)}
          testId={"cancel_button"}
        />
        <CcvButton
          disabled={waitingForBackendResponse}
          type="primary"
          size="normal"
          text={
            project
              ? t("UPDATE_INTEGRATION_BUTTON")
              : t("PROJECT_BUTTON_ADD_PROJECT")
          }
          onClick={handleSubmit(onSubmit)}
          testId={project ? "update_project_button" : "create_project_button"}
        />
      </div>
    </form>
  ) : (
    <EmptyIconBlock emptyText={"RELEASE_INFO_TEXT_NO_INFO"} translate={t} />
  );
}

export default CreateProjectComponent;
