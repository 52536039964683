import React from "react";
import Link from "../../common/button/Link";
import ScannerResult from "../../common/ScannerResult";
import InfoTextWithIcon from "../../common/InfoTextWithIcon";
import { ShowForRole } from "../../ShowForRole";
import { useNavigate } from "react-router-dom";

function ScannerResultWithList({
  translate,
  data,
  url,
  isExternalLink,
  isButton,
  navigationInfo,
  translatedButtonText,
  testId,
  resultList,
  maxEntries,
  maxEntriesInfoMessage,
  children,
}) {
  const navigate = useNavigate();
  function navigateToUrl() {
    if (isExternalLink) {
      return window.open(url, "_blank");
    }
    return navigate(url, {
      state: {
        data: data,
        navigationInfo: navigationInfo,
      },
    });
  }

  function getListOfScannerResults() {
    if (data?.progress === "FINISHED") {
      return resultList.map((resultItem) => (
        <ScannerResult
          status={resultItem.status}
          resultName={resultItem.name}
          resultDescription={resultItem.description}
        />
      ));
    }
  }

  function getExceededMaxEntriesResult() {
    return (
      <div>
        <ScannerResult
          status={"FAILED"}
          resultName={resultList.length + " " + translate("ERRORS_FOUND_TEXT")}
        />
        {maxEntriesInfoMessage && (
          <ShowForRole permission="Employee">
            <InfoTextWithIcon
              translatedInfoText={maxEntriesInfoMessage}
              testId={"application_info_tooltip"}
            />
          </ShowForRole>
        )}
      </div>
    );
  }

  function getResultList() {
    if (maxEntries && resultList.length > maxEntries) {
      return getExceededMaxEntriesResult();
    }
    return getListOfScannerResults();
  }

  return (
    <div>
      <span className="label-with-button">
        {children}
        {url && (
          <Link
            isExternalLink={isExternalLink}
            isButton={isButton}
            onClick={() => navigateToUrl()}
            testId={testId}
          >
            {translatedButtonText}
          </Link>
        )}
      </span>
      {resultList && getResultList()}
    </div>
  );
}

export default ScannerResultWithList;
