import {
  CcvMaterialIcon,
  CcvMessage,
} from "@ccv-oc-myccv/ccv-react-components";
import React from "react";
import "../../style/TestCatalogue.scss";
import ProjectCatalogueSection from "./ProjectCatalogueSection";
import { SolutionSections } from "../../domain/ProjectCatalogueSections";

function ProjectCatalogue({
  defaultInputData,
  solution,
  register,
  control,
  translate,
  errors,
  setValue,
  validate,
}) {
  return (
    <>
      <div className="info-block catalogue">
        {SolutionSections[solution] ? (
          Object.values(SolutionSections[solution]).map((section) => (
            <div className="catalogue-form">
              <ProjectCatalogueSection
                defaultInputData={defaultInputData}
                translate={translate}
                sectionInfo={section}
                register={register}
                control={control}
                errors={errors}
                setValue={setValue}
                validate={validate}
              />
            </div>
          ))
        ) : (
          <CcvMessage
            text={translate("INTEGRATION_SOLUTION_UNAVAILABLE")}
            type="info"
            testId="integration_solution_unavailable_text"
          />
        )}
      </div>
    </>
  );
}

export default ProjectCatalogue;
