import {
  CcvHeading,
  CcvMaterialIcon,
  CcvText,
  CcvTooltip,
} from "@ccv-oc-myccv/ccv-react-components";
import React, { useEffect, useState } from "react";
import CheckBoxList from "../form/CheckBoxList";
import IntegrationTypeSection from "./projectSectionBodies/IntegrationTypeSection";
import RadioButtonSection from "./projectSectionBodies/RadioButtonSection";
import { Icon, Tooltip } from "@mui/material";

function ProjectCatalogueSection({
  sectionInfo,
  control,
  translate,
  errors,
  setValue,
  defaultInputData,
  validate,
}) {
  const [isSectionClosed, setIsSectionClosed] = useState(false);
  const [sectionResult, setSectionResult] = useState();

  useEffect(() => {
    if (defaultInputData && sectionInfo.type === "CHECK") {
      setSectionResult(defaultInputData[sectionInfo.registerName]);
    }
  }, []);

  function handleDialogOpen() {
    setIsSectionClosed(!isSectionClosed);
  }

  function setCheckBoxResult(value, checked) {
    if (checked) {
      sectionResult
        ? setSectionResult([...sectionResult, value])
        : setSectionResult([value]);
    } else {
      setSectionResult((oldValues) => {
        return oldValues?.length > 1
          ? oldValues.filter((item) => item !== value)
          : null;
      });
    }
  }

  function formatResultText() {
    if (sectionInfo.registerName) {
      return (
        <CcvText
          size="small"
          color="dark-grey"
          textStyle="bold"
          testId={sectionInfo.registerName + "_section_result_text"}
        >
          {sectionResult
            .map((item) => translate(sectionInfo.options[item]?.description))
            .join(", ")}
        </CcvText>
      );
    } else {
      return sectionResult;
    }
  }

  function getInputForm(section) {
    switch (section.type) {
      case "RADIO":
        return getSectionBody(
          <RadioButtonSection
            control={control}
            errors={errors}
            translate={translate}
            sectionInfo={section}
            setValue={setSectionResult}
            initialSelectedValue={defaultInputData}
          />
        );
      case "CHECK":
        return getSectionBody(
          <CheckBoxList
            options={section.options}
            translate={translate}
            registerName={sectionInfo.registerName}
            register={setValue}
            errors={errors}
            errorText={"CHECKBOX_LIST_REQUIRED"}
            onInput={setCheckBoxResult}
            defaultChecked={
              defaultInputData && defaultInputData[sectionInfo.registerName]
            }
            triggerValidation={validate}
          />
        );
      case "INTEGRATIONTYPE":
        return getSectionWithCustomInput(
          <IntegrationTypeSection
            setResult={setSectionResult}
            registerName={"integrationType"}
            errors={errors}
            errorText={"PROJECT_INFO_TEXT_CHOOSE_OPTION"}
            translate={translate}
            handleDialogOpen={handleDialogOpen}
            control={control}
            setIntegrationValue={setValue}
            defaultInputData={defaultInputData}
            validate={validate}
            required={sectionInfo.required}
          />
        );
    }
  }

  function getSectionBody(formInput) {
    return (
      <>
        <div
          className={
            "clickable catalogue-heading-result " +
            (isSectionClosed && "result-text")
          }
          onClick={handleDialogOpen}
        >
          <div className="catalogue-tooltip">
            <div className="catalogue-titles">
              {sectionInfo.title.map((title, index, array) => (
                <CcvHeading
                  text={translate(title) +
                    (index === array.length - 1 && sectionInfo?.required ? " *" : "")
                  }
                  size="extra-small"
                  testId={title}
                />
              ))}
            </div>
            {sectionInfo.tooltipInfo && (
              <CcvTooltip
                title={sectionInfo.tooltipInfo?.map((text) => (
                  <CcvText color="white" size="medium">
                    {translate(text)}
                  </CcvText>
                ))}
                direction="right"
              />
            )}
          </div>

          {sectionResult && isSectionClosed && formatResultText()}
        </div>
        <div hidden={isSectionClosed} className="catalogue-body">
          {formInput}
        </div>
      </>
    );
  }

  function getSectionWithCustomInput(customInput) {
    return (
      <>
        <div
          className={
            "clickable catalogue-heading-result " +
            (isSectionClosed && "result-text")
          }
          hidden={!isSectionClosed}
          onClick={handleDialogOpen}
        >
          <div className="catalogue-titles ">
            {sectionInfo.title.map((title) => (
              <CcvText testId={title} textStyle="semi-bold" size="medium">
                {translate(title)}
              </CcvText>
            ))}
          </div>
          {sectionResult && isSectionClosed && formatResultText()}
        </div>
        <div hidden={isSectionClosed}>{customInput}</div>
      </>
    );
  }

  return (
    <div
      className={
        sectionResult
          ? "catalogue-wrapper completed-border"
          : "catalogue-wrapper  "
      }
    >
      {getInputForm(sectionInfo)}
    </div>
  );
}

export default ProjectCatalogueSection;
