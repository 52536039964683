import React, { useState } from "react";
import { CcvButton, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import RadioButtonBody from "./RadioButtonBody";
import { DialogActions, DialogContent } from "@mui/material";

function AssignToMeBody({
  authService,
  translate,
  onDisagree,
  disabled,
  release,
  control,
  downloadFromSharepoint,
  assignFunction,
  assignedInfo,
  required,
}) {
  const [assignedUser, setAssignedUser] = useState();

  return (
    <>
      <DialogContent>
        {release.assignedTo === "NOT_ASSIGNED" ? (
          <form>
            <div className="form-inline">
              <RadioButtonBody
                control={control}
                translate={translate}
                title={assignedInfo.subtitle}
                setValue={setAssignedUser}
                name="assignTo"
                required={required}
                options={[
                  {
                    id: "yes",
                    value: authService.getEmail(),
                    text: "YES_TEXT",
                    icon: "done",
                    color: "green",
                    testid: "assign_to_me_yes_option",
                  },
                  {
                    id: "no",
                    value: "NOT_ASSIGNED",
                    text: "NO_TEXT",
                    icon: "clear",
                    color: "red",
                    testid: "assign_to_me_no_option",
                  },
                ]}
              />
            </div>
          </form>
        ) : (
          <CcvText size="medium" color="dark-grey">
            {translate(assignedInfo.assignedText) +
              '"' +
              release.assignedTo +
              '".'}{" "}
          </CcvText>
        )}
      </DialogContent>
      <DialogActions>
        <CcvButton
          type="tertiary"
          size="normal"
          text={translate("CANCEL_LABEL")}
          onClick={onDisagree}
          disabled={disabled}
          testId="cancel_status_update_button"
        />
        <CcvButton
          type="primary"
          size="normal"
          text={translate("SAVE_AND_DOWNLOAD_LABEL")}
          onClick={
            release.assignedTo === "NOT_ASSIGNED"
              ? assignFunction
              : downloadFromSharepoint
          }
          disabled={
            (!assignedUser && release.assignedTo === "NOT_ASSIGNED") || disabled
          }
          testId="download_button"
        />
      </DialogActions>
    </>
  );
}

export default AssignToMeBody;
