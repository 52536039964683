import React, { useEffect, useState } from "react";
import {
  CcvCheckbox,
  CcvHeading,
  CcvMaterialIcon,
  CcvText,
} from "@ccv-oc-myccv/ccv-react-components";
import { useTranslation } from "react-i18next";
import TestCatalogueInfo from "../../domain/TestCatalogueInfo";
import Log4jVersionBody from "./requirementTestSectionBodies/Log4jVersionBody";
import OperationInfoBody from "./requirementTestSectionBodies/OperationInfoBody";
import FormatAndSignatureBody from "./requirementTestSectionBodies/FormatAndSignatureBody";

function RequirementTestSection({
  DialogClosedOnDefault,
  sectionName,
  defaultCompleted,
  setSectionCompleted,
  setValue,
  errors,
  requirementTest,
}) {
  const [closeDropdown, setCloseDropdown] = useState(DialogClosedOnDefault);
  const [areAllBlocksAccepted, setAreAllBlocksAccepted] =
    useState(defaultCompleted);
  const [testsChecked, setTestsChecked] = useState(
    TestCatalogueInfo[sectionName].section.map((item) => {
      return { id: item.id, completed: false };
    })
  );
  const [t] = useTranslation();

  function handleDialogOpen() {
    setCloseDropdown(!closeDropdown);
  }

  function handleChecked(id) {
    setTestsChecked(
      testsChecked.map((item) => {
        if (item.id === id) {
          item.completed = !item.completed;
        }
        return item;
      })
    );
    areAllBlocksChecked();
  }

  function areAllBlocksChecked() {
    if (testsChecked.every((item) => item.completed)) {
      setAreAllBlocksAccepted(true);
      setCloseDropdown(true);
      setSectionCompleted(sectionName, true);
    } else {
      setAreAllBlocksAccepted(false);
      setSectionCompleted(sectionName, false);
    }
  }

  function isCheckboxCompleted(checkboxId) {
    return testsChecked.find(
      (checkbox) => Object.values(checkbox)[0] === checkboxId
    ).completed;
  }

  function getRequirementTestSections(item) {
    return (
      <div className="catalogue-block">
        <span className="catalogue-text">{getSectionBlock(item)}</span>
        <span className="executed-checkbox" data-testid="catalogueTests">
          <CcvCheckbox
            id={item.id}
            disabled={defaultCompleted}
            checked={defaultCompleted || isCheckboxCompleted(item.id)}
            value={item.id}
            labelText={t("TESTED_LABEL")}
            testId="catalogueTests"
            onChange={(selectedValue) => {
              handleChecked(selectedValue);
            }}
          />
        </span>
      </div>
    );
  }

  function getSectionBlock(item) {
    switch (item.custom) {
      case "LOG4J_VERSION":
        return (
          <Log4jVersionBody
            item={item}
            requirementTest={requirementTest}
            defaultCompleted={defaultCompleted}
            setValue={setValue}
            errors={errors}
            translate={t}
          />
        );
      case "OPERATION_INFO":
        return <OperationInfoBody translate={t} />;
      case "FORMAT_AND_SIGNATURE":
        return <FormatAndSignatureBody translate={t} />;
      default:
        return item.texts.map((item) => (
          <CcvText size="medium" color="medium-grey">
            {t(item)}
          </CcvText>
        ));
    }
  }

  return (
    <div
      className={
        areAllBlocksAccepted
          ? "catalogue-wrapper completed-border"
          : "catalogue-wrapper"
      }
    >
      <div className="catalogue-heading" onClick={handleDialogOpen}>
        <CcvHeading
          text={t(TestCatalogueInfo[sectionName].title)}
          size="extra-small"
          testId={TestCatalogueInfo[sectionName].title.toLowerCase()}
        />
        <CcvText size="small" color="dark-grey" testId="completed_text">
          {areAllBlocksAccepted && (
            <span className="completed">
              <CcvMaterialIcon
                iconName="check_circle_outline"
                size="normal"
                iconStyle="filled"
              />
              {t("COMPLETED")}
            </span>
          )}

          <CcvMaterialIcon
            iconName={
              closeDropdown ? "keyboard_arrow_down" : "keyboard_arrow_up"
            }
            size="normal"
            iconStyle="filled"
          />
        </CcvText>
      </div>
      <div hidden={closeDropdown} className="catalogue-body">
        <CcvText color="medium-grey" size="medium" textStyle="bold">
          {t("REQUIREMENTS_SUBTITLE")}
        </CcvText>
        {TestCatalogueInfo[sectionName].section.map((item) =>
          getRequirementTestSections(item)
        )}
      </div>
    </div>
  );
}

export default RequirementTestSection;
