import * as Oidc from "oidc-client";
import { OidcClientLogger } from "./oidc-client-logger";
import Role from "../domain/Role";
import { CcvTokenManager } from "@ccv-oc-myccv/ccv-token-manager";

export class AuthService {
  tokenManager;
  id_token;
  access_token;
  user;
  ensureAuthenticationPromise;

  constructor() {
    if (this.tokenManager == null) {
      Oidc.Log.logger = new OidcClientLogger("OidcClient", true);
      Oidc.Log.level = Oidc.Log.DEBUG;
      this.tokenManager = new CcvTokenManager(
        AuthService.createUserManager,
        AuthService.createTokenManagerSettings(),
        new OidcClientLogger("CcvTokenManager", true),
        window
      );
    }
  }

  async ensureAuthentication() {
    if (window.location.href && !window.location.href.includes("postlogin")) {
      if (!this.ensureAuthenticationPromise) {
        this.ensureAuthenticationPromise = new Promise(
          async (resolve, reject) => {
            await this.tokenManager.ensureAuthentication();
            let isAuthenticated = await this.tokenManager.isAuthenticated;
            if (isAuthenticated) {
              await this.updateUser();
            }
            resolve(isAuthenticated);
          }
        );
      }
    }
    return this.ensureAuthenticationPromise;
  }

  async isAuthenticated() {
    return this.tokenManager.isAuthenticated();
  }

  async handlePostLogin() {
    await this.tokenManager.handleLoginCallback(true);
  }

  async handlePostRenew() {
    await this.tokenManager.handleRenewCallback();
  }

  async handleLogout() {
    await this.tokenManager.handleLogoutCallback();
  }

  async updateUser() {
    let ccvUser = await this.tokenManager.user;
    this.id_token = ccvUser.id_token;
    this.access_token = ccvUser.access_token;
    this.user = ccvUser;
  }

  // Promise because we need to wait for the token before executing any call
  async authorizationHeaderValueAsPromise() {
    return new Promise(async (resolve, reject) => {
      try {
        await this.ensureAuthentication();
        this.tokenManager.user.then((response) => {
          resolve(`Bearer ${response.access_token}`);
        });
      } catch (e) {
        reject(e);
      }
    });
  }

  static createUserManager(settings) {
    return new Oidc.UserManager(settings);
  }

  static createTokenManagerSettings() {
    return {
      authorityOrigin: process.env.REACT_APP_SSO_URL,
      clientId: "developer_console",
      scopes: ["openid", "profile", "email", "context", "ccvProfile", "portal"],
      loginRedirectUrl: process.env.REACT_APP_HOST_URL + `/postlogin`,
      renewRedirectUrl: process.env.REACT_APP_HOST_URL + `/postrenew`,
    };
  }

  async getRoles() {
    await this.ensureAuthentication();
    if (this.user && this.user.profile && this.user.profile.contextData) {
      return this.user.profile.contextData;
    }
    return null;
  }

  getUserId() {
    if (this.user && this.user.profile && this.user.profile.sub) {
      return this.user.profile.sub;
    }
    return null;
  }

  getUsername() {
    if (this.user && this.user.profile && this.user.profile.name) {
      return this.user.profile.name;
    }
    return null;
  }

  getEmail() {
    return this.user.profile.email;
  }

  getBupaId() {
    return JSON.parse(this.user.profile.contextData).businessPartnerId;
  }

  getBupaName() {
    return JSON.parse(this.user.profile.contextData).businessPartnerName;
  }

  isDeveloper(roles) {
    return roles?.includes(Role.Developer);
  }

  isEmployee(roles) {
    return roles?.includes(Role.Employee);
  }

  isValidRoleForDevCentre(roles) {
    return roles && Object.values(Role).some((role) => roles.includes(role));
  }
}
