import React from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import {
  CcvButton,
  CcvMaterialIcon,
  CcvText,
} from "@ccv-oc-myccv/ccv-react-components";
import { ShowForRole } from "../ShowForRole";
import { useNavigate } from "react-router-dom";

function FeedbackItemAccordion({
  item,
  dialogSubtitle,
  translate,
  isItemExpanded,
  setisItemExpanded,
  FormatService,
}) {
  function renderItemLabel() {
    return (
      <>
        <ShowForRole permission="Developer">
          {item?.question
            ? translate("DIALOG_FEEDBACK_SECURITY_SCAN")
            : translate("RESPONSE_FEEDBACK_SECURITYSCAN")}
        </ShowForRole>
        <ShowForRole permission="Employee">{item?.email}</ShowForRole>
      </>
    );
  }

  return (
    <Accordion
      onChange={() => setisItemExpanded(!isItemExpanded)}
      expanded={isItemExpanded}
      className="accordion-wrapper securityscan-feedback-accordion"
    >
      <AccordionSummary expandIcon={<CcvMaterialIcon iconName="expand_more" />}>
        <CcvText
          size="small"
          color="dark-grey"
          textStyle="bold"
          testId={dialogSubtitle?.toLowerCase()}
        >
          {renderItemLabel()}
        </CcvText>
        <CcvText size="small" color="medium-grey">
          {FormatService.getDateWithTimeFormat(item?.date)}
        </CcvText>
      </AccordionSummary>
      <AccordionDetails className="securityscan-feedback-details">
        <CcvText size="medium" color={"dark-grey"} testId="feedback_container">
          {item?.question && (
            <div
              className="spacing-bottom"
              dangerouslySetInnerHTML={{
                __html: FormatService.getHTMLFormat(item?.question),
              }}
            />
          )}
          {item?.answer}
        </CcvText>
      </AccordionDetails>
    </Accordion>
  );
}

export default FeedbackItemAccordion;
