import * as yup from "yup";

export function SigningYupValidation() {
  return yup.object({
    status: yup.string().required("required").typeError("required"),
    file: yup
      .array()
      .when("status", ([status]) =>
        status === "SIGNING_FAILED"
          ? yup.array().notRequired()
          : yup.array().min(1).max(1).required("required")
      ),
    report: yup.array().max(1).notRequired(),
    signers: yup
      .array()
      .when("status", ([status]) =>
        status === "SIGNING_FAILED"
          ? yup.array().notRequired()
          : yup
              .array()
              .of(yup.string().required("required"))
              .required("required")
      ),
    feedback: yup
      .string()
      .when("status", ([status]) =>
        status === "SIGNING_FAILED"
          ? yup.string().required("required")
          : yup.string().notRequired()
      ),
    contract: yup
      .string()
      .when("status", ([status]) =>
        status === "SIGNING_FAILED"
          ? yup.boolean().notRequired()
          : yup
              .boolean()
              .required("required")
              .oneOf([true], "The terms and conditions must be accepted.")
      ),
  });
}
