const DownloadTypes = {
  SIGNED_APK: {
    key: "SIGNED_APK",
  },
  UNSIGNED_APK: {
    key: "UNSIGNED_APK",
  },
  FUNCTIONAL_TEST_REPORT: {
    key: "FUNCTIONAL_TEST_REPORT",
  },
  SECURITYSCAN_REPORT: {
    key: "SECURITYSCAN_REPORT",
  },
  SCREENSHOT: {
    key: "SCREENSHOTS",
  },
  APP_ICON: {
    key: "APPICON",
  },
};

export default DownloadTypes;
