import React, { useState, useEffect } from "react";
import FeedbackItemAccordion from "./FeedbackItemAccordion";

function SecurityscanFeedbackAccordion({
  item,
  dialogSubtitle,
  translate,
  FormatService,
  isLastItem,
}) {
  const [isQuestionExpanded, setIsQuestionExpanded] = useState(false);
  const [isResponseExpanded, setIsResponseExpanded] = useState(false);

  function handleOpeningLastItem() {
    if (isLastItem) {
      if (!item?.response?.answer) {
        setIsQuestionExpanded(true);
        setIsResponseExpanded(false);
      } else {
        setIsResponseExpanded(true);
        setIsQuestionExpanded(false);
      }
    }
  }

  useEffect(() => {
    handleOpeningLastItem();
  }, [isLastItem]);

  function getItemAccordion(item, isItemExpanded, setisItemExpanded) {
    return (
      <FeedbackItemAccordion
        item={item}
        dialogSubtitle={dialogSubtitle}
        translate={translate}
        isItemExpanded={isItemExpanded}
        setisItemExpanded={setisItemExpanded}
        FormatService={FormatService}
      />
    );
  }

  return (
    <span data-testid="feedback_accordion_container">
      {item?.question?.question &&
        getItemAccordion(
          item.question,
          isQuestionExpanded,
          setIsQuestionExpanded
        )}
      {item?.response?.answer &&
        getItemAccordion(
          item.response,
          isResponseExpanded,
          setIsResponseExpanded
        )}
    </span>
  );
}

export default SecurityscanFeedbackAccordion;
