import { CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";

function ResultLabel({ status, testId, translatedText }) {
  function getLabelColorClassName() {
    if (status?.toLowerCase().includes("failed")) {
      return "label-status-failed";
    }
    if (status?.toLowerCase().includes("success")) {
      return "label-status-success";
    }
    if (status?.toLowerCase().includes("warning")) {
      return "label-status-warning";
    }
    if (status?.toLowerCase().includes("ongoing")) {
      return "label-status-ongoing";
    }
    if (status?.toLowerCase().includes("more_info")) {
      return "label-status-warning";
    }
    return "label-status-inactive";
  }

  return (
    <label className={"label-status " + getLabelColorClassName()}>
      <CcvText size="small" testId={testId}>
        {translatedText}
      </CcvText>
    </label>
  );
}

export default ResultLabel;
