import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";

function InfoTextWithIcon({ testId, translatedInfoText }) {
  return (
    <span className="icon-text">
      <CcvText color="medium-grey" size="small" testId={testId}>
        <CcvMaterialIcon iconName="info" />
        {translatedInfoText}
      </CcvText>
    </span>
  );
}

export default InfoTextWithIcon;
