import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import { grey } from "@mui/material/colors";
import React from "react";

function ScannerResult({ status, resultName, resultDescription }) {
  return (
    <div>
      <span className="scan-result-label-with-icon">
        <CcvText color={status === "FAILED" ? "red" : "grey"}>
          <CcvMaterialIcon
            iconName={status === "FAILED" ? "clear" : "help_outline"}
          />
        </CcvText>
        <CcvText
          size="medium"
          color="dark-grey"
          testId={"scanner_name_" + resultName?.toLowerCase()}
        >
          {resultName}
        </CcvText>
      </span>
      <CcvText color={status === "FAILED" ? "red" : "grey"} testId={"result_description_" + resultName?.toLowerCase() }>
        {resultDescription}
      </CcvText>
    </div>
  );
}

export default ScannerResult;
