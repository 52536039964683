import React, { useContext, useEffect, useState } from "react";
import PermissionList from "../../../components/common/PermissionList";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../config/AppContext";
import {
  CcvButton,
  CcvHeading,
  CcvMaterialIcon,
  CcvMessage,
} from "@ccv-oc-myccv/ccv-react-components";
import { ReleaseService } from "../../../services/ReleaseService";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import SpinnerService from "../../../services/SpinnerService";
import { Spinner } from "../../../components/Loading/Spinner";
import { ShowForRole } from "../../../components/ShowForRole";
import { useForm } from "react-hook-form";
import FormatService from "../../../services/FormatService";
import { ApkInfoService } from "../../../services/ApkInfoService";
import { UpdateIncompletePermissionsYupValidation } from "../../../yupValidation/UpdateIncompletePermissionsYupValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import InfoDialog from "../../../components/dialogs/InfoDialog";
import ReleaseStatus from "../../../domain/ReleaseStatus";

function PermissionListComponent({
  setIncompletePermissionList,
  incompletePermissionList,
  ...props
}) {
  const { setTitle, authService } = useContext(AppContext);
  let releaseService = new ReleaseService(authService);
  let apkInfoService = new ApkInfoService(authService);
  const releaseId = useParams().id;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [release, setRelease] = useState();
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);
  const { promiseInProgress } = usePromiseTracker({
    area: "permissions",
    delay: 0,
  });
  const {
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      UpdateIncompletePermissionsYupValidation(
        getPermissionWithMoreInfoNeeded()
      )
    ),
  });

  useEffect(() => {
    setTitle(t(props.title));
    if (location?.state?.data) {
      setRelease(location?.state?.data);
      fetchReleaseData();
    } else {
      trackPromise(
        SpinnerService.errorSpinner(fetchReleaseData(), t),
        "permissions"
      );
    }
  }, []);

  function getPermissionWithMoreInfoNeeded() {
    return release?.project?.apkInfo?.permissions?.filter(
      (permission) => permission.incomplete
    );
  }

  async function fetchReleaseData(shouldOpenDialog) {
    await authService.ensureAuthentication();
    return releaseService
      .getReleaseByIdAndBupaId(releaseId, authService.getBupaId())
      .then((response) => {
        setRelease(response.data);
      })
      .finally(() => shouldOpenDialog && setOpenFeedbackDialog(true));
  }

  function onSubmit(data) {
    trackPromise(
      SpinnerService.successSpinner(
        apkInfoService
          .patchPermissions(
            release.project.id,
            getPermissionUpdatePayload(data)
          )
          .then(() => {
            fetchReleaseData(true);
          }),
        t,
        "TOAST_RELEASE_CREATED"
      ),
      "create-release"
    );
  }

  function getPermissionUpdatePayload(data) {
    return getPermissionWithMoreInfoNeeded().map((permission) => ({
      name: permission.name,
      reason: data[FormatService.getPermissionValidationKey(permission.name)],
    }));
  }

  function addOrRemoveIncompletePermission(permission, isIncomplete) {
    if (isIncomplete) {
      setIncompletePermissionList((prevList) => [...prevList, permission.name]);
    } else {
      setIncompletePermissionList((prevList) =>
        prevList?.filter(
          (incompletePermission) => incompletePermission !== permission.name
        )
      );
    }
  }

  return (
    <>
      {getPermissionWithMoreInfoNeeded()?.length > 0 && (
        <ShowForRole permission="Developer">
          <div className="info-block-full-page spacing-bottom">
            <span className="spacing-bottom">
              <CcvHeading
                text={t("MORE_INFO_PERMISSION_LABEL")}
                size="medium"
                testId="PERMISSIONS_HEADING"
              />
            </span>
            <span className="spacing-bottom">
              <CcvMessage
                text={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: FormatService.getHTMLFormat(
                        release?.securityscan?.feedback?.slice(-1)[0]?.question
                          .question
                      ),
                    }}
                  />
                }
                type="info"
              />
            </span>
            <PermissionList
              permissions={getPermissionWithMoreInfoNeeded()}
              isPermissionInputEnabled={true}
              isPermissionUpdateEnabled={false}
              setValue={setValue}
              errors={errors}
              clearErrors={clearErrors}
            />
          </div>
          <div className="permission-save-button">
            <CcvButton
              type="primary"
              size="normal"
              text={t("SAVE_LABEL")}
              disabled={false}
              onClick={handleSubmit(onSubmit)}
              testId="update_permission_button"
            />
          </div>
        </ShowForRole>
      )}

      <div className="info-block-full-page">
        <CcvHeading
          text={
            release?.project?.apkInfo?.permissions?.length +
            " " +
            t("PERMISSIONS_INFO_HEADING")
          }
          size="medium"
          testId="PERMISSIONS_HEADING"
        />

        <Spinner area={"permissions"} dataIsFetched={release}>
          <div className="message-block message-block-permissions spacing-top spacing-bottom">
            <CcvMessage
              text={
                <span className="message-block-text">
                  <CcvMaterialIcon
                    iconName={
                      !release?.project?.apkInfo?.permissions?.length
                        ? "check"
                        : "error"
                    }
                    size="medium"
                  />
                  {!release?.project?.apkInfo?.permissions?.length
                    ? t("NO_DANGEROUS_OR_UNKNOWN_PERMISSIONS_MESSAGE")
                    : t("DANGEROUS_OR_UNKNOWN_PERMISSIONS_MESSAGE")}
                </span>
              }
              type={
                !release?.project?.apkInfo?.permissions?.length
                  ? "success"
                  : "alert"
              }
              testId={
                !release?.project?.apkInfo?.permissions?.length
                  ? "no_dangerous_or_unknown_permissions_message"
                  : "dangerous_or_unknown_permissions_message"
              }
            />
          </div>
          <PermissionList
            permissions={release?.project?.apkInfo?.permissions}
            editIncompletePermissionList={addOrRemoveIncompletePermission}
            incompletePermissionList={incompletePermissionList}
            isPermissionUpdateEnabled={
              location?.state?.navigationInfo?.isPermissionUpdateEnabled
            }
            releaseStatus={release?.releaseStatus}
            authService={authService}
            project={release?.project}
            releaseId={releaseId}
            setRelease={setRelease}
          />
        </Spinner>
      </div>
      {release && (
        <InfoDialog
          open={openFeedbackDialog}
          dialogTitle={ReleaseStatus["SECURITYSCAN_MORE_INFO"].stepName}
          dialogSubtitle="DIALOG_FEEDBACK"
          dialogInfo={{
            releaseId: release.id,
            releaseInfo: release.securityscan?.jotti?.scannerResults?.filter(
              (scannerResult) => scannerResult.packageName
            ),
            releaseStatus: release?.releaseStatus,
            securityscan: release?.securityscan,
            permissions: release?.project?.apkInfo?.permissions,
            date: FormatService.getDateWithTimeFormat(release.creationDate),
            updateCompletedText: "PERMISSIONS_UPDATED_LABEL",
            afterUpdateResponseFunction: () =>
              navigate("/release/detail/" + release.id),
          }}
          releaseService={releaseService}
          releaseActiveStatus={release?.releaseStatus}
        />
      )}
    </>
  );
}

export default PermissionListComponent;
