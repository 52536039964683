import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";

function EmptyImageBlock({ image, emptyText, children, translate }) {
  return (
    <div className="empty-block" data-testid="empty-projects">
      {/* <img src={image}></img> */}
      {/* tussentijdse oplossing tot er nieuwe afbeeldingen zijn */}
      <CcvMaterialIcon
        iconName="description"
        size="extra-large"
        iconStyle="filled"
      />
      <CcvText color="dark-grey" size="medium" testId={emptyText.toLowerCase()}>
        {translate(emptyText)}
      </CcvText>
      {children}
    </div>
  );
}

export default EmptyImageBlock;
