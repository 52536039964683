import React from "react";
import PermissionListItem from "./PermissionListItem";

function PermissionList({
  permissions,
  editIncompletePermissionList,
  incompletePermissionList,
  isPermissionUpdateEnabled,
  isPermissionInputEnabled,
  setValue,
  errors,
  clearErrors,
  releaseStatus,
  authService,
  project,
  releaseId,
  setRelease,
}) {
  function sortPermissions(permissions) {
    return permissions?.sort((a, b) => {
      const priority = { NEW: 1, UPDATED_BY_CCV: 1, UPDATED: 2, default: 3 };

      const statusA = priority[a.status] || priority.default;
      const statusB = priority[b.status] || priority.default;

      return statusA - statusB;
    });
  }

  return (
    <div className="two-column-container">
      {sortPermissions(permissions)?.map((permission) => (
        <div className="permission_input">
          <PermissionListItem
            textAreaClassName="textarea-medium no-resize-textarea"
            permission={permission}
            disabled={!isPermissionInputEnabled}
            editIncompletePermissionList={editIncompletePermissionList}
            incompletePermissionList={incompletePermissionList}
            isPermissionUpdateEnabled={isPermissionUpdateEnabled}
            isPermissionInputEnabled={isPermissionInputEnabled}
            clearErrors={clearErrors}
            errors={errors}
            setValue={setValue}
            releaseStatus={releaseStatus}
            project={project}
            authService={authService}
            releaseId={releaseId}
            setRelease={setRelease}
          />
        </div>
      ))}
    </div>
  );
}

export default PermissionList;
