import { CcvMessage } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";
import { toast } from "react-toastify";

function defaultSpinner(
  promise,
  translate,
  processText,
  successText,
  callbackMethod
) {
  toast(
    <CcvMessage
      type="info"
      text={translate(processText)}
      testId={processText.toLowerCase()}
    />,
    {
      autoClose: false,
    }
  );
  return promise
    .then(
      (response) => {
        toast.dismiss();
        successText &&
          toast(
            <CcvMessage
              type="success"
              text={translate(successText)}
              testId={successText.toLowerCase()}
            />,
            {
              delay: 300,
            }
          );
      },
      (error) => {
        toast.dismiss();
        toast(
          <CcvMessage
            type="error"
            text={translate(
              error.response?.data?.translatableCode
                ? error.response?.data?.translatableCode
                : "TOAST_CALLADMIN"
            )}
            testId={
              error.response?.data?.translatableCode
                ? error.response?.data?.translatableCode.toLowerCase()
                : "call_admin_text"
            }
          />
        );
      }
    )
    .finally(() => callbackMethod && callbackMethod(false));
}

function errorSpinner(
  promise,
  translate,
  finalCallbackMethod,
  errorCallbackMethod
) {
  return promise
    .catch((error) => {
      toast.dismiss();
      toast(
        <CcvMessage
          type="error"
          text={translate("TOAST_CALLADMIN")}
          testId="call_admin_text"
        />
      );
      errorCallbackMethod && errorCallbackMethod();
    })
    .finally(() => finalCallbackMethod && finalCallbackMethod(false));
}

function successSpinner(
  promise,
  translate,
  successText,
  errorCallbackMethod,
  successCallbackMethod,
  finalCallbackMethod
) {
  return promise
    .then((response) => {
      toast.dismiss();
      successText &&
        toast(
          <CcvMessage
            type="success"
            text={translate(successText)}
            testId={successText.toLowerCase()}
          />
        );
      successCallbackMethod && successCallbackMethod();
    })
    .catch((error) => {
      errorCallbackMethod &&
        errorCallbackMethod(
          error.response?.data?.translatableCode
            ? error.response?.data?.translatableCode
            : "TOAST_CALLADMIN"
        );
    })
    .finally(() => finalCallbackMethod && finalCallbackMethod(false));
}

function loadingAndSuccessSpinner(
  promise,
  translate,
  processText,
  successText,
  errorCallbackMethod,
  finalCallbackMethod
) {
  toast(
    <CcvMessage
      type="info"
      text={translate(processText)}
      testId={processText.toLowerCase()}
    />,
    {
      autoClose: false,
    }
  );
  return promise
    .then((response) => {
      toast.dismiss();
      successText &&
        toast(
          <CcvMessage
            type="success"
            text={translate(successText)}
            testId={successText.toLowerCase()}
          />
        );
    })
    .catch((error) => {
      toast.dismiss();
      errorCallbackMethod &&
        errorCallbackMethod(
          error.response?.data?.translatableCode
            ? error.response?.data?.translatableCode
            : "TOAST_CALLADMIN"
        );
    })
    .finally(() => finalCallbackMethod && finalCallbackMethod(false));
}

export default { errorSpinner, successSpinner, defaultSpinner, loadingAndSuccessSpinner }
