import { CcvCheckbox, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";

function ContractCheckbox({
  translate,
  registerName,
  setChecked,
  setCheckedFunction,
  setValue,
  disabled,
  testId,
  text,
  isChecked,
}) {
  return (
    <span className="dialog-contract" data-testid={testId}>
      <CcvCheckbox
        id={testId}
        disabled={disabled}
        checked={isChecked}
        value={testId}
        labelText={translate(text)}
        testId={testId}
        onChange={
          setCheckedFunction
            ? setCheckedFunction
            : () => {
                setChecked(!isChecked);
                setValue &&
                  setValue(registerName, !isChecked, { shouldValidate: true });
              }
        }
      />
    </span>
  );
}

export default ContractCheckbox;
