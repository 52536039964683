import React, { useContext, useEffect } from "react";
import { AppContext } from "../../../config/AppContext";
import { useTranslation } from "react-i18next";
import BasicValidationChecklist from "../../../domain/BasicValidationChecklist";
import { CcvHeading, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import ValidationTable from "../../../components/common/ValidationTable";

function BasicValidationComponent(props) {
  const { setTitle } = useContext(AppContext);
  const [t] = useTranslation();

  useEffect(() => {
    setTitle(t(props.title));
  }, []);

  return (
    <div className="info-block">
      <span className="default-spacing-bottom">
        <CcvHeading text={t("CHECKS_TEXT")} size="small" level={1} />
      </span>
      <ValidationTable validations={BasicValidationChecklist} translate={t} />
    </div>
  );
}

export default BasicValidationComponent;
