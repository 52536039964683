import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";

function DropzoneWithFiles({
  files,
  removeAllFiles,
  OpenFileExplorer,
  isDragActive,
  translate,
  required,
}) {
  return files.map((file) => (
    <>
      <div
        className={
          "dropzone small-dropzone basic-dropzone-body " +
          (isDragActive && " dropzone-hover")
        }
      >
        <span onClick={() => OpenFileExplorer()} className="dropzone-input">
          <span className="dropzone-drop-file-text">
            <CcvText size="small" color="blue">
              <CcvMaterialIcon iconName="file_upload" size="normal" />
              {translate("CHOOSE_OTHER_FILE_LABEL")}
            </CcvText>
            <CcvText size="medium" color="red">
              &nbsp;{required ? "*" : ""}
            </CcvText>
          </span>
        </span>
      </div>
      <span className="acceptedfile ">
        <CcvText size="medium" color="dark-grey">
          <CcvMaterialIcon
            iconName="description"
            size="small"
            iconStyle="outlined"
          />
          {file.path} - {Math.round(file.size / 10000) / 100} MB{" "}
        </CcvText>
        <span class="cancelButton" onClick={() => removeAllFiles()}>
          <CcvMaterialIcon iconName="delete_outline" />
        </span>
      </span>
    </>
  ));
}

function DropzoneWithoutFiles({ translate, isDragActive, required }) {
  return (
    <div
      className={
        "dropzone basic-dropzone-body " + (isDragActive && " dropzone-hover")
      }
    >
      <CcvMaterialIcon
        iconName="file_upload"
        size="normal"
        iconStyle="filled"
      />
      <span className="dropzone-drop-file-text">
        <CcvText size="medium" color="blue">
          {translate("UPLOAD_FILE")}
        </CcvText>
        <CcvText size="medium" color="red">
          &nbsp;{required ? "*" : ""}
        </CcvText>
      </span>
    </div>
  );
}

export { DropzoneWithFiles, DropzoneWithoutFiles };
