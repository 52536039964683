import React, { useEffect, useState } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import LoadingBlock from "./LoadingBlock";

export const Spinner = ({
  area,
  delay,
  dataIsFetched,
  children,
  accessDenied,
}) => {
  const { promiseInProgress } = usePromiseTracker({
    area: area,
    delay: delay ? delay : 0,
  });

  useEffect(() => {
    if (promiseInProgress || dataIsFetched || accessDenied) {
      setPageIsLoading(false);
    }
  }, [promiseInProgress, dataIsFetched, accessDenied]);

  const [pageIsLoading, setPageIsLoading] = useState(true);

  return !pageIsLoading && !promiseInProgress ? children : <LoadingBlock />;
};
