import { CcvText, CcvTooltip } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";

function InputLabel({
  labelText,
  tooltipInfo,
  translate,
  bold,
  className,
  required,
}) {
  return (
    <CcvText size="medium" color="dark-grey" textStyle={bold && "semi-bold"}>
      <span
        className={"tooltip-label " + className}
        data-testid={(labelText && labelText.toLowerCase()) + "_tooltip"}
      >
        {translate(labelText) + (required && labelText ? " *" : "")}
        {tooltipInfo && (
          <CcvTooltip
            title={tooltipInfo?.map((text) => (
              <CcvText color="white" size="medium" testId={text.toLowerCase()}>
                {translate(text)}
              </CcvText>
            ))}
            direction="right"
          />
        )}
      </span>
    </CcvText>
  );
}

export default InputLabel;
