import React, { useEffect, useState } from "react";
import { Documentation } from "../../../domain/Documentation";
import ECROnTerminal from "../../../domain/ECROnTerminal";
import EmptyIconBlock from "../../../components/emptyBlocks/EmptyIconBlock";
import DocumentationSection from "./DocumentationSection";

function DocumentationComponent({ project, projectService, translate }) {
  const [projectRule, setProjectRule] = useState(
    project && getProjectInfoAsArray()
  );
  const [documentationUrls, setDocumentationUrls] = useState();

  useEffect(() => {
    projectRule && getDocumentationUrls();
  }, [projectRule]);

  function getProjectInfoAsArray() {
    let projectArray = [
      project.integrationType,
      project.solution,
      project.ecronTerminal && ECROnTerminal.ECR_ON_CCV_TERMINAL.key,
      project.environment,
      project.technology,
    ];

    project.paymentEngineTypes.map((engine) => projectArray.push(engine));
    project.segments.map((segment) => projectArray.push(segment));
    project.terminals.map((terminal) => projectArray.push(terminal));
    return projectArray;
  }

  function getDocumentationUrls() {
    setDocumentationUrls(
      Object.entries(Documentation).filter((docuLink) =>
        docuLink[1].rules.some((rule) =>
          rule.every((option) => projectRule.includes(option))
        )
      )
    );
  }

  return (
    <div className="documentation" data-testid="lst_documentation">
      {documentationUrls?.length > 0 ? (
        [...documentationUrls].map(([name, documentation]) => (
          <DocumentationSection
            sectionHeading={name}
            sectionInfo={documentation}
            translate={translate}
            projectService={projectService}
          />
        ))
      ) : (
        <EmptyIconBlock
          emptyText="PROJECT_DOCUMENTATION_INFO_TEXT_NOT_AVAILABLE"
          translate={translate}
        />
      )}
    </div>
  );
}

export default DocumentationComponent;
