export const FunctionalTestingFeedback = {
  NOT_BIG_ENOUGH: {
    key: "NOT_BIG_ENOUGH",
    description: "TESTING_FEEDBACK_NOT_BIG_ENOUGH_OPTION",
  },
  LACK_OF_TIME: {
    key: "LACK_OF_TIME",
    description: "TESTING_FEEDBACK_LACK_OF_TIME_OPTION",
  },
  NSP_CERTIFICATION: {
    key: "NSP_CERTIFICATION",
    description: "TESTING_FEEDBACK_NSP_CERTIFICATION_OPTION",
  },
  OVERRULED: {
    key: "OVERRULED",
    description: "TESTING_FEEDBACK_OVERRULED_OPTION",
  },
};
