import axios from "axios";

const CCV_STORE_INFO_REST_API_URL =
  process.env.REACT_APP_BACKEND_HOST + "/project/ccvStoreInfo";

export class CcvStoreInfoService {
  authService;

  constructor(authService) {
    this.authService = authService;
  }

  getCcvStoreInfo(projectId) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios.get(CCV_STORE_INFO_REST_API_URL + "/" + projectId, {
          headers: { Authorization: token },
        });
      });
  }
}
