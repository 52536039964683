import Lottie from "lottie-react";
import React from "react";
import ccvLoadingLottie from "../../style/lottie/ccv_loading_lottie.json";
import { CcvText } from "@ccv-oc-myccv/ccv-react-components";

function LoadingBlock({ className, translatedText }) {
  return (
    <div className={"ccv-spinner " + className} data-testid="spinner">
      {translatedText && (
        <CcvText testId="loading_text" size="medium" color="grey">
          {translatedText}
        </CcvText>
      )}
      <Lottie
        animationData={ccvLoadingLottie}
        loop={true}
        data-testid="ccv_spinner"
      />
    </div>
  );
}

export default LoadingBlock;
