import ECROnTerminal from "./ECROnTerminal";
import Environment from "./Environment";
import IntegrationType from "./IntegrationType";
import PaymentEngine from "./PaymentEngine";
import ProjectSolution from "./ProjectSolution";
import Segment from "./Segment";
import Terminal from "./Terminal";

export const Documentation = {
  "CCV SoftPOS": {
    path: "https://developer.myccv.eu/solutions/softpos/ccv_softpos/",
    description: ["SOFTPOS_DESCRIPTION"],
    type: "link",
    rules: [[ProjectSolution.SOFTPOS.key]],
  },
  "CCV PhonePOS": {
    path: "https://developer.myccv.eu/documentation/android_sdk/",
    description: ["PHONEPOS_DESCRIPTION"],
    type: "link",
    rules: [[ProjectSolution.PHONEPOS.key]],
  },
  "CCV Online Payments": {
    path: "https://developer.myccv.eu/solutions/ccv_online_payments/",
    description: ["ONLINE_PAYMENTS_DESCRIPTION"],
    type: "link",
    rules: [[ProjectSolution.ONLINE_PAYMENTS.key]],
  },
  "CCV Terminal": {
    path: "https://developer.myccv.eu/solutions/ccv_terminals/",
    description: ["TERMINAL_DESCRIPTION"],
    type: "link",
    rules: [[ProjectSolution.TERMINAL.key]],
  },
  "Journey EV Charging": {
    path: "https://developer.myccv.eu/use-area/mobility_self_service/ev_charging/",
    description: ["EV_CHARGING_DESCRIPTION"],
    type: "link",
    rules: [[Segment.EV_CHARGING.key]],
  },
  "Android REST API (PayProxy)": {
    path: "https://developer.myccv.eu/documentation/android_rest_beta_api/",
    description: ["REST_API_DESCRIPTION"],
    type: "link",
    rules: [[IntegrationType.PI_REST_API.key]],
  },
  "Android SDK (mAPI)": {
    path: "https://developer.myccv.eu/documentation/android_sdk/",
    description: ["MAPI_DESCRIPTION"],
    type: "link",
    rules: [[IntegrationType.MAPI.key]],
  },
  "Android SDK (mAPI) - Demo application": {
    path: "https://developer.myccv.eu/documentation/android_sdk/general/demo-application/",
    description: ["MAPI_DEMO_DESCRIPTION"],
    type: "link",
    rules: [[IntegrationType.MAPI.key]],
  },
  "Printerservice A920 (code examples)": {
    path: "https://fx.ccv-deutschland.de/index.php/s/zyNCgHziC7G8bRA/authenticate/showShare",
    description: ["A920_PRINTSERVICE_DESCRIPTION", "Password: CCVStore1!"],
    type: "link",
    rules: [
      [PaymentEngine.ACCEPT.key, PaymentEngine.SECPOS.key, Terminal.A920.key],
    ],
  },
  "C2P IM30 Datasheet 2019_11": {
    path: "https://ccveu.sharepoint.com/:b:/r/sites/CCVAPIs/Gedeelde%20documenten/General/Developer%20Centre/Requirements/Epics%20List-%20Android%20Integrations/Documentation/Docs/Data%20sheets%20(CCV%20Android%20Terminals)/C2P%20IM30%20Datasheet%202019_11.pdf?csf=1&web=1&e=0JTLIY",
    description: ["IM30_DATASHEET_DESCRIPTION"],
    type: "link",
    rules: [[Terminal.IM30.key]],
  },
  "CCV Mobile A920 ENG": {
    path: "https://ccveu.sharepoint.com/:b:/r/sites/CCVAPIs/Gedeelde%20documenten/General/Developer%20Centre/Requirements/Epics%20List-%20Android%20Integrations/Documentation/Docs/Data%20sheets%20(CCV%20Android%20Terminals)/CCV%20Mobile%20A920%20ENG.pdf?csf=1&web=1&e=ehod6X",
    description: ["A920_MOBILE_DESCRIPTION"],
    type: "link",
    rules: [[Terminal.A920.key]],
  },
  "CCV Fly A77 EN 1021": {
    path: "https://ccveu.sharepoint.com/:b:/r/sites/CCVAPIs/Gedeelde%20documenten/General/Developer%20Centre/Requirements/Epics%20List-%20Android%20Integrations/Documentation/Docs/Data%20sheets%20(CCV%20Android%20Terminals)/CCV_Fly_A77_EN_1021.pdf?csf=1&web=1&e=RyxjAv",
    description: ["A77_FLY_DESCRIPTION"],
    type: "link",
    rules: [[Terminal.A77.key]],
  },
  "OPI Spec": {
    path: "https://estate.myccv.eu/support/nl-nl/category/fusionaccept/type/specifications/",
    description: ["OPI_SPEC_DESCRIPTION"],
    type: "link",
    rules: [[PaymentEngine.ACCEPT.key], [PaymentEngine.SECPOS.key]],
  },
  "OPI Implementation note EVC": {
    path: "https://estate.myccv.eu/support/nl-nl/category/fusionaccept/type/specifications/",
    description: [
      "OPI_IMPLEMENTATION_EVC_DESCRIPTION",
      "OPI_SCENARIOS_DESCRIPTION",
    ],
    type: "link",
    rules: [[Segment.EV_CHARGING.key]],
  },
  "OPI Implementation note Parking": {
    path: "https://estate.myccv.eu/support/nl-nl/category/fusionaccept/type/specifications/",
    description: [
      "OPI_IMPLEMENTATION_PARKING_DESCRIPTION",
      "OPI_SCENARIOS_DESCRIPTION",
    ],
    type: "link",
    rules: [[Segment.PUBLIC_TRANSPORT.key]],
  },
  "IM30 3D step files": {
    path: "https://estate.myccv.eu/support/nl-nl/category/fusionaccept/type/specifications/",
    description: ["IM30_STEP_DESCRIPTION"],
    type: "link",
    rules: [[Terminal.IM30.key]],
  },
  "IM15 3D step files": {
    path: "https://estate.myccv.eu/support/nl-nl/category/fusionaccept/type/specifications/",
    description: ["IM15_STEP_DESCRIPTION"],
    type: "link",
    rules: [[Terminal.IM15.key]],
  },
  "IM30 leaflet": {
    path: "https://www.ccv.eu/en/wp-content/uploads/sites/18/2021/06/IM30-leaflet_FINAL.pdf",
    description: ["IM30_LEAFLET_DESCRIPTION"],
    type: "link",
    rules: [[Terminal.IM30.key]],
  },
  "IM15 leaflet": {
    path: "https://www.ccv.eu/wp-content/uploads/sites/18/2022/06/IM15-Leaflet-A5_web.pdf",
    description: ["IM15_LEAFLET_DESCRIPTION"],
    type: "link",
    rules: [[Terminal.IM15.key]],
  },
  "IM30 manual": {
    path: "https://www.ccv.eu/wp-content/uploads/sites/18/2021/11/Manual_CCV_IM30_Rev.07.pdf",
    description: ["IM30_MANUAL_DESCRIPTION"],
    type: "link",
    rules: [[Terminal.IM30.key]],
  },
  "IM15 manual (contact project engineer)": {
    path: null,
    description: ["IM15_MANUAL_DESCRIPTION", "DOCUMENTATION_HIDDEN"],
    type: "link",
    rules: [[Terminal.IM15.key]],
  },
  "IM30 instruction part 1": {
    path: "https://www.ccv.eu/en/wp-content/uploads/sites/18/2021/12/CCV-IM30-Set-up-video-DHCP-1.mp4",
    description: ["IM30_VIDEO_1_DESCRIPTION"],
    type: "link",
    rules: [[Terminal.IM30.key]],
  },
  "IM30 instruction part 2": {
    path: "https://www.ccv.eu/en/wp-content/uploads/sites/18/2021/12/CCV-IM30-Set-up-video-Static-IP-2.mp4",
    description: ["IM30_VIDEO_2_DESCRIPTION"],
    type: "link",
    rules: [[Terminal.IM30.key]],
  },
  "A80 manual": {
    path: "https://www.ccv.eu/nl/wp-content/uploads/sites/7/2021/10/Gebruikershandleiding-A80-P400-2021.pdf?srsltid=AfmBOoqd5Ws0I0HvLEpzCPp5bdhNHJdoRY6uKAC-Y0p8yAFTlonikBnI",
    description: ["A80_MANUAL_DESCRIPTION"],
    type: "link",
    rules: [[Terminal.A80.key]],
  },
  "Payclient & xPay": {
    path: "https://estate.myccv.eu/support/nl-nl/category/fusionaccept/type/software/",
    description: ["PAYCLIENT_DESCRIPTION", "XPAY_DESCRIPTION"],
    type: "link",
    rules: [
      [
        Terminal.IM30.key,
        Terminal.IM15.key,
        PaymentEngine.ACCEPT.key,
        PaymentEngine.SECPOS.key,
      ],
    ],
  },
  "Release note acCEPT Host/Client": {
    path: "https://estate.myccv.eu/support/nl-nl/category/fusionaccept/type/release-notes/",
    description: ["ACCEPT_HOST_CLIENT_DESCRIPTION"],
    type: "link",
    rules: [[PaymentEngine.ACCEPT.key]],
  },
  "Android Developer Guidelines and FAQs.pdf": {
    description: ["GUIDELINE_FAQ_DESCRIPTION"],
    type: "file",
    rules: [[ECROnTerminal.ECR_ON_CCV_TERMINAL.key]],
  },
  "ITS OPx-C60.zip": {
    description: ["OPXC60_DESCRIPTION"],
    type: "file",
    rules: [
      [PaymentEngine.PAYGEAR.key, Terminal.OPM_C60_COR_A10.key],
      [PaymentEngine.PAYGEAR.key, Terminal.OPM_C60_COR_A20.key],
      [PaymentEngine.PAYGEAR.key, Terminal.OPP_C60_COR_A10.key],
    ],
  },
  "ITS P400 CAT Attended.zip": {
    description: ["P400_CAT_ATTENDED_DESCRIPTION"],
    type: "file",
    rules: [
      [
        PaymentEngine.PAYGEAR.key,
        Terminal.P400.key,
        IntegrationType.CLOUD.key,
        Environment.ATTENDED.key,
      ],
    ],
  },
  "ITS P400 CAT SemiUnattended.zip": {
    description: ["P400_CAT_SEMIUNATTENDED_DESCRIPTION"],
    type: "file",
    rules: [
      [
        PaymentEngine.PAYGEAR.key,
        Terminal.P400.key,
        IntegrationType.CLOUD.key,
        Environment.SEMI_UNATTENDED.key,
      ],
    ],
  },
  "ITS P400 OPI Attended.zip": {
    description: ["P400_OPI_ATTENDED_DESCRIPTION"],
    type: "file",
    rules: [
      [
        PaymentEngine.PAYGEAR.key,
        Terminal.P400.key,
        IntegrationType.OPI.key,
        Environment.ATTENDED.key,
      ],
    ],
  },
  "ITS P400 OPI SemiUnattended.zip": {
    description: ["P400_OPI_SEMIUNATTENDED_DESCRIPTION"],
    type: "file",
    rules: [
      [
        PaymentEngine.PAYGEAR.key,
        Terminal.P400.key,
        IntegrationType.OPI.key,
        Environment.SEMI_UNATTENDED.key,
      ],
    ],
  },
};
