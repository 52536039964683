import React, { useState } from "react";
import { CcvText } from "@ccv-oc-myccv/ccv-react-components";
import { useTranslation } from "react-i18next";
import PermissionUpdateInput from "./PermissionUpdateInput";
import PermissionInput from "./PermissionInput";
import PermissionStatus from "../../domain/PermissionStatus";
import FormatService from "../../services/FormatService";

function PermissionListItem({
  permission,
  clearErrors,
  errors,
  setValue,
  disabled,
  textAreaClassName,
  editIncompletePermissionList,
  incompletePermissionList,
  isPermissionUpdateEnabled,
  releaseStatus,
  authService,
  project,
  releaseId,
  setRelease,
}) {
  const [t] = useTranslation();
  const [isEditPermissionFormOpen, setEditPermissionFormOpen] = useState(false);
  const [editButton, setEditButton] = useState();

  function getStatusLabels() {
    const statusLabels = [];

    if (![PermissionStatus.VALIDATED]?.includes(permission?.status)) {
      statusLabels.push({
        text: permission.status || PermissionStatus.NEW,
        type: "ongoing",
      });
    }

    return statusLabels;
  }

  function getPermissionTypeLabels() {
    const statusLabels = [];
    statusLabels.push({
      text: permission?.type,
      type: permission?.type === "DANGEROUS" ? "failed" : "inactive",
    });

    return statusLabels;
  }

  function getPermissionNameLabel(permissionName) {
    const [mainPermission, impliedPermission] =
      permissionName.split(" requires ");
    const impliedPermissionText =
      impliedPermission &&
      `(requires ${impliedPermission.split(".").pop().trim()})`;

    return (
      <div className="implied-permission-label">
        {mainPermission?.trim()}
        {impliedPermissionText && (
          <CcvText size="small" color="medium-grey">
            {impliedPermissionText}
          </CcvText>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="reason-name-header">
        <CcvText
          textStyle="semi-bold"
          testId={FormatService.replaceSpecialChar(permission.name) + "_header"}
        >
          {getPermissionNameLabel(permission?.name)}
        </CcvText>
        {!isEditPermissionFormOpen && editButton}
      </div>
      {isEditPermissionFormOpen ? (
        <PermissionUpdateInput
          permission={permission}
          setEditPermissionFormOpen={setEditPermissionFormOpen}
          textAreaClassName={textAreaClassName}
          statusLabels={getStatusLabels()}
          permissionTypeLabels={getPermissionTypeLabels()}
          authService={authService}
          project={project}
          releaseId={releaseId}
          setRelease={setRelease}
        />
      ) : (
        <PermissionInput
          permission={permission}
          setEditPermissionFormOpen={setEditPermissionFormOpen}
          setEditButton={setEditButton}
          statusLabels={getStatusLabels()}
          permissionTypeLabels={getPermissionTypeLabels()}
          clearErrors={clearErrors}
          errors={errors}
          setValue={setValue}
          disabled={disabled}
          releaseStatus={releaseStatus}
          editIncompletePermissionList={editIncompletePermissionList}
          incompletePermissionList={incompletePermissionList}
          isPermissionUpdateEnabled={isPermissionUpdateEnabled}
          isEditPermissionFormOpen={isEditPermissionFormOpen}
          textAreaClassName={textAreaClassName}
        />
      )}
    </>
  );
}

export default PermissionListItem;
