const TestCatalogueInfo = {
  SYSTEM_RESOURCES: {
    title: "SYSTEM_RESOURCE_TITLE",
    section: [
      {
        id: "SYSTEM_RESOURCE_1",
        texts: ["SYSTEM_RESOURCE_TEXT_1", "SYSTEM_RESOURCE_TEXT_2"],
        custom: null,
        required: true,
      },
      {
        id: "SYSTEM_RESOURCE_2",
        texts: [
          "SYSTEM_RESOURCE_TEXT_3",
          "SYSTEM_RESOURCE_TEXT_4",
          "SYSTEM_RESOURCE_TEXT_5",
        ],
        custom: null,
        required: true,
      },
      
    ],
  },
  SECURITY: {
    title: "SECURITY_TITLE",
    section: [
      {
        id: "SECURITY_1",
        texts: ["SECURITY_TEXT_1", "SECURITY_TEXT_2", "SECURITY_TEXT_3"],
        custom: null,
        required: true,
      },
      {
        id: "SECURITY_2",
        texts: ["SECURITY_TEXT_4", "SECURITY_TEXT_5", "SECURITY_TEXT_6"],
        custom: null,
        required: true,
      },
      {
        id: "SECURITY_3",
        texts: ["LOG4J_VERSION_TEXT_1", "LOG4J_VERSION_TEXT_2"],
        custom: "LOG4J_VERSION",
        required: true,
      },
    ],
  },
  USABILITY: {
    title: "USABILITY_TITLE",
    section: [
      {
        id: "USABILITY_1",
        texts: [
          "USABILITY_TITLE_TEXT_1",
          "USABILITY_TITLE_TEXT_2",
          "USABILITY_TITLE_TEXT_3",
        ],
        custom: null,
        required: true,
      },
      {
        id: "USABILITY_2",
        texts: ["USABILITY_TITLE_TEXT_4"],
        custom: null,
        required: true,
      },
    ],
  },
  OPERATION: {
    title: "OPERATION_TITLE",
    section: [
      {
        id: "OPERATION_1",
        texts: [
          "OPERATION_INFO_TEXT_1",
          "OPERATION_INFO_TEXT_2",
          "OPERATION_INFO_TEXT_3",
          "OPERATION_INFO_TEXT_4",
          "OPERATION_INFO_TEXT_5",
        ],
        custom: "OPERATION_INFO",
        required: true,
      },
    ],
  },
  UPDATE: {
    title: "UPDATE_TITLE",
    section: [
      {
        id: "UPDATE_1",
        texts: [
          "UPDATE_TEXT_1",
          "UPDATE_TEXT_2",
          "UPDATE_TEXT_3",
          "UPDATE_TEXT_4",
          "UPDATE_TEXT_5",
        ],
        custom: null,
        required: true,
      },
    ],
  },
  ANDROID_VERSION: {
    title: "ANDROID_VERSION_TITLE",
    section: [
      {
        id: "ANDROID_1",
        texts: ["ANDROID_VERSIONS_ACCEPT_TEXT_2"],
        custom: null,
        required: true,
      },
    ],
  },
  FORMAT_AND_SIGNATURE: {
    title: "FORMAT_AND_SIGNATURE_TITLE",
    section: [
      {
        id: "FORMAT_1",
        texts: [
          "FORMAT_AND_SIGNATURE_TEXT_1",
          "FORMAT_AND_SIGNATURE_TEXT_2",
          "FORMAT_AND_SIGNATURE_TEXT_3",
        ],
        custom: "FORMAT_AND_SIGNATURE",
        required: true,
      },
    ],
  },
};

export default TestCatalogueInfo;
