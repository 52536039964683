import {
  CcvButton,
  CcvHeading,
  CcvMaterialIcon,
  CcvText,
} from "@ccv-oc-myccv/ccv-react-components";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
} from "@mui/material";
import Lottie from "lottie-react";
import React from "react";
import ccvLoadingLottie from "../../style/lottie/ccv_loading_lottie.json";

function LoadingDialog({
  translate,
  dialogTitle,
  open,
  loadingText,
  errorText,
  closeDialog,
  isPromiseInProgress,
}) {
  function getPromiseInProgressBody() {
    return (
      <>
        <Lottie
          animationData={ccvLoadingLottie}
          loop={true}
          data-testid="ccv_spinner"
        />
        <span className="detail-spacing">
          <CcvText
            size="medium"
            color="medium-grey"
            testId={loadingText.toLowerCase()}
          >
            {translate(loadingText)}
          </CcvText>
        </span>
      </>
    );
  }

  function getPromiseFailedBody() {
    return (
      <>
        <CcvMaterialIcon iconName="error_outline" size="extra-large" />
        <CcvText
          size="medium"
          color="dark-grey"
          textStyle="bold"
          testId={"create_release_failed_label"}
        >
          {translate("CREATE_RELEASE_FAILED_LABEL")}
        </CcvText>
        <CcvText
          size="medium"
          color="dark-grey"
          testId={errorText?.toLowerCase()}
        >
          <div
            className="loading-dialog-error-text"
            dangerouslySetInnerHTML={{
              __html: translate(errorText),
            }}
          />
        </CcvText>
      </>
    );
  }

  return (
    <Dialog open={open} className="dialog">
      <DialogTitle>
        <CcvHeading
          size="small"
          text={translate(dialogTitle)}
          testId={dialogTitle?.toLowerCase()}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div
            className={
              "loading-dialog " + (errorText && "loading-dialog-error")
            }
          >
            {errorText ? getPromiseFailedBody() : getPromiseInProgressBody()}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions data-testid="button-block-info">
        {!isPromiseInProgress && (
          <CcvButton
            size="normal"
            type="primary"
            text="Ok"
            disabled={isPromiseInProgress}
            onClick={closeDialog}
            testId="ok_loading_dialog_button"
          />
        )}
      </DialogActions>
    </Dialog>
  );
}

export default LoadingDialog;
