import axios from "axios";

const APKINFO_RELEASE_API_URL =
  process.env.REACT_APP_BACKEND_HOST + "/apk-info";

const MANUAL_START_THRESHOLD_PERCENTAGE = 80;
const MANUAL_INCREMENT_INTERVAL = 1200;

export class ApkInfoService {
  authService;

  manualProgress = MANUAL_START_THRESHOLD_PERCENTAGE;
  isManualProgressActive = false;
  progressInterval = null;

  constructor(authService) {
    this.authService = authService;
  }

  patchPermissions(projectId, permissions) {
    return this.authService.authorizationHeaderValueAsPromise().then((token) =>
      axios.patch(`${APKINFO_RELEASE_API_URL}/permissions`, permissions, {
        params: { projectId },
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
    );
  }

  validateApk(formData, { onUploadProgress }) {
    return this.authService
      .authorizationHeaderValueAsPromise()
      .then((token) => {
        return axios
          .post(`${APKINFO_RELEASE_API_URL}/validateApk`, formData, {
            headers: {
              Authorization: token,
            },
            onUploadProgress: (progressEvent) =>
              this.handleUploadProgress(progressEvent, onUploadProgress),
          })
          .finally(() => {
            this.stopManualProgress();
          });
      });
  }

  handleUploadProgress(progressEvent, onUploadProgress) {
    const uploadProgress = this.calculateUploadProgress(progressEvent);

    if (uploadProgress < MANUAL_START_THRESHOLD_PERCENTAGE) {
      this.stopManualProgress();
      onUploadProgress(uploadProgress);
    } else {
      if (!this.isManualProgressActive) {
        this.manualProgress = MANUAL_START_THRESHOLD_PERCENTAGE;
        onUploadProgress(this.manualProgress);
        this.startManualProgress(onUploadProgress);
      }
    }
  }

  startManualProgress(onUploadProgress) {
    if (!this.isManualProgressActive) {
      this.isManualProgressActive = true;
      this.progressInterval = setInterval(() => {
        this.incrementManualProgress(onUploadProgress);
      }, MANUAL_INCREMENT_INTERVAL);
    }
  }

  stopManualProgress() {
    if (this.progressInterval) {
      clearInterval(this.progressInterval);
      this.progressInterval = null;
    }
    this.isManualProgressActive = false;
  }

  incrementManualProgress(onUploadProgress) {
    if (this.manualProgress < 100) {
      this.manualProgress += 1;
      onUploadProgress(this.manualProgress);
    } else {
      this.stopManualProgress();
    }
  }

  calculateUploadProgress(progressEvent) {
    const uploadProgress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    return uploadProgress < MANUAL_START_THRESHOLD_PERCENTAGE
      ? Math.round(uploadProgress * (MANUAL_START_THRESHOLD_PERCENTAGE / 100))
      : MANUAL_START_THRESHOLD_PERCENTAGE;
  }
}
