import { CcvText } from "@ccv-oc-myccv/ccv-react-components";
import { EditorState, ContentState } from "draft-js";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { useTranslation } from "react-i18next";
import FormatService from "../../services/FormatService";
import ValidationError from "./ValidationError";

function RichTextEditor({
  maxLength,
  setValue,
  initialValue,
  registerName,
  errors,
  translate,
  errorText,
  validate,
  editorClassName,
}) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [t] = useTranslation();

  useEffect(() => {
    if (initialValue) {
      setEditorState(
        EditorState.createWithContent(ContentState.createFromText(initialValue))
      );
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [initialValue]);

  useEffect(() => {
    if (editorState.getCurrentContent().getPlainText().trim()) {
      setValue(
        registerName,
        FormatService.getRawFormat(editorState.getCurrentContent()),
        { shouldValidate: true }
      );
    }
  }, [editorState]);

  function getLengthOfSelectedText() {
    let currentContent = editorState.getCurrentContent();
    let currentSelection = editorState.getSelection();
    let length = 0;

    if (!currentSelection.isCollapsed()) {
      let startKey = currentSelection.getStartKey();
      let endKey = currentSelection.getEndKey();
      let startBlock = currentContent.getBlockForKey(startKey);
      let isStartAndEndBlockAreTheSame = startKey === endKey;
      let startBlockTextLength = startBlock.getLength();
      let startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      let endSelectedTextLength = currentSelection.getEndOffset();
      let keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;

        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }

          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  }

  function canHandleBeforeInput() {
    const currentContentLength = editorState
      .getCurrentContent()
      .getPlainText().length;
    const selectedTextLength = getLengthOfSelectedText();
    if (currentContentLength - selectedTextLength > maxLength - 1) {
      return true;
    }
  }

  function canHandlePastedText(pastedText) {
    const currentContentLength = editorState
      .getCurrentContent()
      .getPlainText().length;
    const selectedTextLength = getLengthOfSelectedText();

    if (
      currentContentLength + pastedText.length - selectedTextLength >
      maxLength
    ) {
      return true;
    }
  }

  function handleChange(input) {
    setEditorState(input);
    if (input.getCurrentContent().getPlainText().length === 0) {
      setValue(registerName, null, { shouldValidate: true });
    } else {
      setValue(
        registerName,
        FormatService.getRawFormat(input.getCurrentContent()),
        { shouldValidate: true }
      );
      validate(registerName);
    }
  }

  return (
    <>
      <Editor
        editorState={editorState}
        handleBeforeInput={canHandleBeforeInput}
        handlePastedText={canHandlePastedText}
        onEditorStateChange={(input) => handleChange(input)}
        wrapperClassName="richtextbox-wrapper"
        editorClassName={"richtextbox-editor " + editorClassName}
        toolbarClassName="richtextbox-toolbar"
        toolbarOnFocus={true}
        toolbar={{
          options: ["inline", "list"],
          inline: { options: ["bold", "italic", "underline"] },
          list: { options: ["unordered"] },
        }}
      />
      <CcvText
        size="small"
        color="medium-grey"
        testId="characters_remaining_text"
      >
        <span className="medium-grey-bold">
          {maxLength - editorState.getCurrentContent().getPlainText().length}
        </span>{" "}
        {t("CHARACTERS_REMAINING")}
      </CcvText>
      {errors && (
        <ValidationError
          error={errors[registerName]}
          errorText={translate(errorText)}
          testId={registerName + "_richtextbox"}
        />
      )}
    </>
  );
}

export default RichTextEditor;
