import {
  CcvButton,
  CcvHeading,
  CcvText,
  CcvMessage,
  CcvMaterialIcon,
} from "@ccv-oc-myccv/ccv-react-components";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RowTable from "../../../components/tables/rowTable/RowTable";
import RadioButtonBody from "../../../components/dialogs/DialogBodies/updateBodies/RadioButtonBody";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ResultLabel from "../../../components/common/ResultLabel";
import { AppContext } from "../../../config/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import DownloadLink from "../../../components/common/button/DownloadLink";
import SpinnerService from "../../../services/SpinnerService";
import { ReleaseService } from "../../../services/ReleaseService";
import DownloadTypes from "../../../domain/DownloadType";
import FormatService from "../../../services/FormatService";
import ContractCheckbox from "../../../components/form/ContractCheckbox";
import RichTextEditor from "../../../components/form/RichTextEditor";
import Dropzone from "../../../components/form/Dropzone";
import FormValidation from "../../../domain/FormValidation";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { SecurityscanYupValidation } from "../../../yupValidation/SecurityscanYupValidation";
import TextInput from "../../../components/form/TextInput";
import { Spinner } from "../../../components/Loading/Spinner";

function SecurityScanComponent({
  incompletePermissionList,
  setIncompletePermissionList,
  ...props
}) {
  const [t] = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { setTitle, setProjectInfo, authService } = useContext(AppContext);
  let releaseService = new ReleaseService(authService);
  const [releaseHistory, setReleaseHistory] = useState();
  const [isContractSigned, setIsContractSigned] = useState(false);
  const [status, setStatus] = useState();
  const [release, setRelease] = useState();
  const { promiseInProgress } = usePromiseTracker({
    area: "updatingSecurityscan",
    delay: 0,
  });
  const {
    handleSubmit,
    setValue,
    control,
    trigger,
    clearErrors,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(SecurityscanYupValidation(release?.securityscan)),
  });

  useEffect(() => {
    trigger("feedback");
  }, [release]);

  useEffect(() => {
    setTitle(t(props.title));
    trackPromise(
      SpinnerService.errorSpinner(
        Promise.all([fetchReleaseData(), fetchReleaseHistory()]),
        t
      ),
      "release-area"
    );
  }, []);

  useEffect(() => {
    ["SECURITYSCAN_FAILED", "SECURITYSCAN_MORE_INFO"].includes(status) &&
      setIsContractSigned(false);
    setValue("contract", isContractSigned, { shouldValidate: true });
  }, [status]);

  function fetchReleaseData() {
    return releaseService
      .getReleaseByIdAndBupaId(
        location.state.release?.id,
        authService.getBupaId()
      )
      .then((response) => {
        setRelease(response.data);
        setProjectInfo(response.data.project);
      })
      .catch((err) => {
        throw err;
      });
  }

  async function fetchReleaseHistory() {
    return trackPromise(
      SpinnerService.errorSpinner(
        releaseService
          .getReleaseHistory(location.state.release?.id)
          .then((response) => {
            setReleaseHistory(response.data);
          }),
        t
      ),
      "history-area"
    );
  }

  function downloadFromSharepoint(fileType) {
    SpinnerService.defaultSpinner(
      releaseService.getDownloadLink(release.id, fileType).then((response) => {
        return FormatService.downloadFileWithNewFilename(
          response.data.downloadUrl,
          fileType != DownloadTypes.SECURITYSCAN_REPORT
            ? FormatService.getUnsignedFileName(
                response.data.fileName,
                release.project?.apkInfo?.packageName,
                release.version,
                release.project?.projectName
              )
            : response.data.fileName
        );
      }),
      t,
      "TOAST_DOWNLOADING",
      "TOAST_DOWNLOAD_SUCCEEDED"
    );
  }

  function onSubmit(data) {
    trackPromise(
      SpinnerService.defaultSpinner(
        updateSecurityscan(data),
        t,
        "TOAST_PROCESSING",
        "TOAST_RELEASE_UPDATED"
      ),
      "updatingSecurityscan"
    );
  }

  function updateSecurityscan(data) {
    return releaseService
      .patchReleaseSecurityScanStatus(
        release.id,
        getSecurityUpdateFormData(data)
      )
      .then((response) => {
        navigate("/release/detail/" + release.id, { replace: true });
      });
  }

  function getSecurityUpdateFormData(data) {
    const formData = new FormData();
    data.lastUpdated = Date.now();
    data.lastEditedBy = authService.getEmail();
    data.inCompletePermissions = incompletePermissionList;
    data.scanJobId =
      data.jottiUrl && FormatService.extractIdFromUrl(data.jottiUrl);
    data.mobsfHash =
      data.mobsfUrl && FormatService.extractIdFromUrl(data.mobsfUrl);
    data.report?.length > 0 &&
      formData.append(
        "file",
        new File(
          [data.report[0]],
          FormatService.replaceSpecialChar(data.report[0].name),
          {
            type: data.report[0].type,
          }
        )
      );
    formData.append(
      "securityScanRequest",
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    return formData;
  }

  function areValidationAndPermissionChecksAvailable() {
    const validationsReleaseDate = new Date(
      Number(process.env.REACT_APP_VALIDATIONS_RELEASE_DATE)
    );
    if (validationsReleaseDate) {
      return new Date(release?.creationDate) > validationsReleaseDate;
    }
  }

  function getScannerResultList() {
    return release?.securityscan?.jotti?.scannerResults?.map(
      (scannerResult) => {
        return {
          name: scannerResult.name,
          description: scannerResult.unknownResultReason
            ? t("SCAN_RESULT_UNKNOWN_LABEL")
            : scannerResult.packageName,
          status: scannerResult.unknownResultReason ? "UNKNOWN" : "FAILED",
        };
      }
    );
  }

  function getJottiBody(progress) {
    switch (progress) {
      case "RUNNING":
        return getResultLabel(
          "SCAN_ONGOING_LABEL",
          "ongoing",
          "jotti_scan_result_label_"
        );
      case "FINISHED":
        return getFinishedJottiBody(
          release?.securityscan?.jotti?.scannerResults
        );
      case "JOTTI_DISABLED":
        return getResultLabel(
          "JOTTI_DISABLED_LABEL",
          "warning",
          "jotti_scan_result_label_"
        );
      case "EXECUTED_MANUALLY":
        if (!isJottiInputNeeded()) {
          return getResultLabel(
            "MANUALLY_EXECUTED_LABEL",
            "inactive",
            "jotti_scan_result_label_"
          );
        }
      default:
        return (
          <TextInput
            maxLength={FormValidation.MAX_URL_INPUT}
            isMaxCharHidden={true}
            field="jottiUrl"
            setValue={setValue}
            shouldValidateOnInput={true}
            placeholder={"JOTTI_URL_PLACEHOLDER"}
            clearErrors={clearErrors}
          />
        );
    }
  }

  function getFinishedJottiBody(scannerResults) {
    if (
      scannerResults &&
      release?.securityscan?.jotti?.scannerResults?.filter(
        (ScannerResult) => ScannerResult.packageName
      )?.length == 0
    ) {
      return getResultLabel("OK", "success", "jotti_scan_result_label_");
    }
    return getResultLabel("FAILED_TEXT", "failed", "jotti_scan_result_label_");
  }

  function getMobsfBody(progress) {
    if (progress === "RUNNING") {
      return getResultLabel(
        "SCAN_ONGOING_LABEL",
        "ongoing",
        "mobsf_scan_result_label_"
      );
    }

    return (
      <div className="rowtable_input_form">
        {isMobsfInputNeeded() && (
          <TextInput
            field="mobsfUrl"
            setValue={setValue}
            shouldValidateOnInput={true}
            placeholder={"MOBSF_URL_PLACEHOLDER"}
            clearErrors={clearErrors}
            maxLength={FormValidation.MAX_URL_INPUT}
            isMaxCharHidden={true}
          />
        )}
        {release?.securityscan?.reportFileAvailable ? (
          <DownloadLink
            testId="download_mobsf_report"
            translatedText={t("DOWNLOAD_RAPPORT_LABEL")}
            downloadFunction={() =>
              downloadFromSharepoint(DownloadTypes.SECURITYSCAN_REPORT)
            }
          />
        ) : (
          <Dropzone
            setValue={setValue}
            acceptedExtensions={{ "file/type": [".pdf", ".docx"] }}
            registerName="report"
            maxSize={FormValidation.MAX_REPORT_FILE_SIZE}
            isLabelBold={true}
            shouldValidateOnInput={true}
            required={true}
          />
        )}
      </div>
    );
  }

  function isApiCallFailed(progress) {
    return ![
      "FINISHED",
      "RUNNING",
      "JOTTI_DISABLED",
      "EXECUTED_MANUALLY",
    ].includes(progress);
  }

  function isApiCallFinished(progress) {
    return !["RUNNING", "JOTTI_DISABLED"].includes(progress);
  }

  function isJottiResultAvailable() {
    return release?.securityscan?.jotti?.scanJobIdUrl;
  }

  function isMobsfResultAvailable() {
    return (
      release?.securityscan?.mobsf?.hashUrl &&
      release?.securityscan?.reportFileAvailable
    );
  }

  function isMobsfInputNeeded() {
    return (
      isApiCallFailed(release?.securityscan?.mobsf?.progress) ||
      (isApiCallFinished(release?.securityscan?.mobsf?.progress) &&
        !isMobsfResultAvailable())
    );
  }

  function isJottiInputNeeded() {
    return (
      isApiCallFailed(release?.securityscan?.jotti?.progress) ||
      (isApiCallFinished(release?.securityscan?.jotti?.progress) &&
        !isJottiResultAvailable())
    );
  }

  function getPermissionsBody(permissions) {
    return getResultLabel(
      permissions?.length + " " + t("USED_TEXT"),
      "warning",
      "permissions_found_amount"
    );
  }

  function getPreviousResult() {
    const previousResultLabel = getPreviousResultLabel();

    return (
      previousResultLabel && (
        <div className="previous-result-securityscan">
          <CcvHeading
            color="blue"
            text={t("Previous result") + ": "}
            size="extra-small"
            testId="previous_result_label_securityscan"
          />
          {previousResultLabel}
        </div>
      )
    );
  }

  function getPreviousResultLabel() {
    if (releaseHistory) {
      switch (releaseHistory[0]?.status) {
        case "SECURITYSCAN_FAILED":
          return getResultLabel(
            "FAILED_TEXT",
            "failed",
            "previous_result_label_"
          );
        case "SECURITYSCAN_MORE_INFO":
          return getResultLabel(
            "MORE_INFO_TEXT",
            "warning",
            "previous_result_label_"
          );
      }
    }
  }

  function getResultLabel(text, status, testId) {
    return (
      <ResultLabel
        status={status}
        translatedText={t(text).toLocaleUpperCase()}
        testId={testId + status}
      />
    );
  }

  function getSecurityscanFeedbackInitialValue() {
    if (incompletePermissionList?.length > 0) {
      if (incompletePermissionList?.length === 1) {
        return t("PERMISSION_INPUT_SINGLE_LABEL", {
          permission: incompletePermissionList[0],
        });
      }
      return t("PERMISSION_INPUT_MULTIPLE_LABEL", {
        permissions: incompletePermissionList.join(", "),
      });
    }
    return "";
  }

  function getColumnTitleSubTextJotti() {
    if (!release?.securityscan) {
      return "RELEASE_INFO_TEXT_NO_INFO";
    }
    if (isJottiInputNeeded()) {
      return "API_CALL_FAILED_EMPLOYEE_TEXT";
    }
    return "";
  }

  function getColumnTitleSubTextMobsf() {
    if (!release?.securityscan) {
      return "RELEASE_INFO_TEXT_NO_INFO";
    }
    if (isMobsfInputNeeded()) {
      return "API_CALL_FAILED_EMPLOYEE_TEXT";
    }
    return "";
  }

  function getLossOfInformationMessage() {
    return (
      <div className="message-block-label-with-icon">
        <CcvMaterialIcon iconName="report" iconStyle="outlined" size="medium" />
        {t("WARNING_LOSS_OF_INFORMATION")}
      </div>
    );
  }

  return (
    <>
      <CcvMessage type="alert" text={getLossOfInformationMessage()} />
      <div className="info multi-side-block gap">
        <div className="info-block left ">
          <div className="header-with-edit spacing-bottom">
            <CcvHeading
              color="blue"
              text={t("INPUT_TEXT")}
              size="small"
              testId="release_general_info_title"
            />
          </div>
          <Spinner
            area="release-area"
            dataIsFetched={release}
            accessDenied={false}
          >
            <RowTable
              translate={t}
              data={[
                (isJottiInputNeeded(release?.securityscan?.jotti?.progress) ||
                  isMobsfInputNeeded(
                    release?.securityscan?.mobsf?.progress
                  )) && {
                  columnName: "RELEASE_APK_LABEL",
                  data: "BASICTABLE_DOWNLOADLINK",
                  function: () =>
                    downloadFromSharepoint(DownloadTypes.UNSIGNED_APK),
                  type: "download",
                },
                {
                  columnName: "APK_VALIDATION_LABEL",
                  data: release?.project?.apkInfo,
                  unavailableData: !areValidationAndPermissionChecksAvailable(),
                  url: "/release/detail/security-checklist",
                  isButton: true,
                  isExternalLink: false,
                  buttonText: "VIEW_LIST_LABEL",
                  testId: "apk_validation_label",
                  element: getResultLabel(
                    "OK",
                    "success",
                    "apk_validation_result_label"
                  ),
                  type: "scanner_result_with_list",
                },
                {
                  columnName: "JOTTI_SCAN_RESULT_LABEL",
                  columnRequired: isJottiInputNeeded(),
                  columnSubText: getColumnTitleSubTextJotti(),
                  columnSubTextColor: !release?.securityscan
                    ? "medium-grey"
                    : "red",
                  data: release?.securityscan?.jotti,
                  url:
                    !isJottiInputNeeded() &&
                    release?.securityscan?.jotti?.scanJobIdUrl,
                  isButton: true,
                  isExternalLink: true,
                  testId: "jotti_scan_result",
                  resultList: release?.securityscan?.jotti,
                  list: getScannerResultList(),
                  element: getJottiBody(release?.securityscan?.jotti?.progress),
                  maxEntries: 3,
                  maxEntriesInfoMessage: t("JOTTI_MAX_ENTRIES_INFO_TEXT"),
                  type: "scanner_result_with_list",
                },
                {
                  columnName: "MOBSF_RAPPORT_LABEL",
                  data: release,
                  columnSubText: getColumnTitleSubTextMobsf(),
                  columnSubTextColor: !release?.securityscan
                    ? "medium-grey"
                    : "red",
                  url:
                    !isMobsfInputNeeded() &&
                    release?.securityscan?.mobsf?.hashUrl,
                  isButton: true,
                  isExternalLink: true,
                  testId: "mobsf_scan_result",
                  element: getMobsfBody(release?.securityscan?.mobsf?.progress),
                  type: "scanner_result_with_list",
                },
                {
                  columnName: "NO_PERMISSIONS_INFO_HEADING",
                  data: release,
                  testId: "detail_permission_list",
                  url: `/release/detail/${release?.id}/permissions`,
                  navigationInfo: { isPermissionUpdateEnabled: true },
                  isButton: true,
                  isExternalLink: false,
                  unavailableData:
                    !areValidationAndPermissionChecksAvailable() ||
                    !release?.project?.apkInfo?.permissions?.length,
                  emptyMessage:
                    areValidationAndPermissionChecksAvailable() &&
                    t("NO_DANGEROUS_OR_UNKNOWN_PERMISSIONS_MESSAGE"),
                  buttonText: "VIEW_LIST_LABEL",
                  testId: "view_list_of_permission_button",
                  element: getPermissionsBody(
                    release?.project?.apkInfo?.permissions
                  ),
                  type: "scanner_result_with_list",
                },
                {
                  columnName: "EXTRA_INFO_HEADING",
                  fullDataView: true,
                  data: release?.securityscan?.feedback,
                  releaseId: release?.id,
                  permissions: release?.project?.apkInfo?.permissions,
                  unavailableData:
                    release?.securityscan?.feedback?.length === 0,
                  testId: "extra_info_feedback",
                  type: "feedback_view",
                },
              ]}
            />
          </Spinner>
        </div>
        <div className="info-block right">
          <div className="header-with-edit">
            <CcvHeading
              color="blue"
              text={t("RESULT_LABEL")}
              size="small"
              testId="release_general_info_title"
            />
            {getPreviousResult()}
          </div>
          {release?.project?.apkInfo?.neptuneFound && (
            <div className="message-block-text-neptune-found">
              <CcvMessage
                text={
                  <span className="message-block-text neptune-found-text">
                    <CcvMaterialIcon iconName={"error"} size="medium" />
                    {t("NEPTUNE_FOUND_WARNING_MESSAGE")}
                  </span>
                }
                type={"error"}
                testId={"neptune_found_warning_message"}
              />
            </div>
          )}
          <form>
            <div className="input-block-checkbox">
              <RadioButtonBody
                control={control}
                translate={t}
                name={"status"}
                setValue={setStatus}
                setFormValue={setValue}
                selectedOption={
                  incompletePermissionList?.length > 0
                    ? "SECURITYSCAN_MORE_INFO"
                    : status
                }
                title={"PROGRESSBAR_SCAN"}
                required={true}
                options={[
                  {
                    id: "success",
                    value: "SECURITYSCAN_SUCCESS",
                    text: "SUCCESS_TEXT",
                    icon: "done",
                    color: "green",
                    testid: "securityscan_success_option",
                    disabled: incompletePermissionList?.length > 0,
                  },
                  {
                    id: "more_info",
                    value: "SECURITYSCAN_MORE_INFO",
                    text: "MORE_INFO_TEXT",
                    icon: "info",
                    color: "orange",
                    testid: "securityscan_more_info_option",
                  },
                  {
                    id: "failed",
                    value: "SECURITYSCAN_FAILED",
                    text: "FAILED_TEXT",
                    icon: "clear",
                    color: "red",
                    testid: "securityscan_failed_option",
                    disabled: incompletePermissionList?.length > 0,
                  },
                ]}
              />
            </div>

            <CcvText size="medium" color="dark-grey" textStyle="semi-bold">
              {t("DIALOG_FEEDBACK")}
            </CcvText>
            <RichTextEditor
              maxLength={FormValidation.MAX_FEEDBACK_LENGTH_LARGE}
              setValue={setValue}
              registerName={"feedback"}
              validate={trigger}
              initialValue={getSecurityscanFeedbackInitialValue()}
              editorClassName={"richtextbox-editor-large"}
            />
            {status === "SECURITYSCAN_SUCCESS" && (
              <ContractCheckbox
                translate={t}
                testId={"securityscan_authorize"}
                setChecked={setIsContractSigned}
                isChecked={isContractSigned}
                text={"SUCRITYSCAN_SUCCESS_AUTHORIZE_LABEL"}
                registerName={"contract"}
                setValue={setValue}
              />
            )}

            <div className="button-block full-width">
              <CcvButton
                type="tertiary"
                size="normal"
                text={t("CANCEL_LABEL")}
                onClick={() => navigate(-1)}
                disabled={promiseInProgress}
                testId="cancel_status_update_button"
              />
              <CcvButton
                type="primary"
                size="normal"
                text={t("SAVE_LABEL")}
                onClick={handleSubmit(onSubmit)}
                disabled={promiseInProgress || !isValid}
                testId="update_status_button"
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default SecurityScanComponent;
