import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";

function EmptyIconBlock({ emptyText, translate }) {
  return (
    <div className="empty-block">
      <CcvMaterialIcon
        iconName="description"
        size="extra-large"
        iconStyle="outlined"
      />
      <CcvText size="medium" color="dark-grey" testId={emptyText.toLowerCase()}>
        {translate(emptyText)}
      </CcvText>
    </div>
  );
}

export default EmptyIconBlock;
