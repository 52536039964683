import { CcvMessage } from "@ccv-oc-myccv/ccv-react-components";
import multiDownload from "multi-download";
import React from "react";
import ButtonWithIcons from "../../common/button/ButtonWithIcons";
import { toast } from "react-toastify";

function TextCopyDownload({ buttonInfo, translate }) {
  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(
        toast(
          <CcvMessage type="info" text={translate("COPY_TO_CLIPBOARD")} />,
          { autoClose: 800 }
        )
      );
  }

  function downloadToTextFile(text, fileName, columnName) {
    multiDownload(
      [
        URL.createObjectURL(
          new Blob([translate(columnName), " of unsigned APK: ", text], {
            type: "text/plain",
          })
        ),
      ],
      {
        rename: ({ url, index, urls }) =>
          (fileName ? fileName : "apkInfo") + ".txt",
      }
    );
  }

  return (
    <ButtonWithIcons
      buttonColor={"dark-grey"}
      iconNames={[
        {
          iconName: "content_copy",
          onClick: () => copyToClipboard(buttonInfo.data),
        },
        {
          iconName: "file_download",
          onClick: () =>
            buttonInfo.donwloadFunction
              ? buttonInfo.donwloadFunction()
              : downloadToTextFile(
                  buttonInfo.data,
                  buttonInfo.fileName,
                  buttonInfo.columnName
                ),
        },
      ]}
      buttonText={
        buttonInfo.data.length > 35
          ? buttonInfo.data.toString().substring(0, 25) + "..."
          : buttonInfo.data
      }
      iconClassName="icon-blue button-with-icon-row"
      translate={translate}
    />
  );
}

export default TextCopyDownload;
