import * as yup from "yup";

export function UpdatePermissionYupValidation(permissionReason) {
  return yup.object({
    permission: yup
      .string()
      .default(permissionReason || "")
      .required("required")
      .typeError("required"),
  });
}
