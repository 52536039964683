import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../config/AppContext";
import HistoryTable from "../../components/tables/HistoryTable";
import { CcvHeading } from "@ccv-oc-myccv/ccv-react-components";
import SpinnerService from "../../services/SpinnerService";
import { ProjectService } from "../../services/ProjectService";
import EmptyIconBlock from "../../components/emptyBlocks/EmptyIconBlock";

function FullHistoryComponent(props) {
  const { setTitle, authService } = useContext(AppContext);
  let projectService = new ProjectService(authService);
  const [projectHistory, setProjectHistory] = useState();
  const location = useLocation();
  const [t] = useTranslation();
  const [waitingForBackendResponse, setWaitingForBackendResponse] =
    useState(false);

  function fetchProjectHistory() {
    SpinnerService.errorSpinner(
      projectService
        .getProjectHistory(location?.state?.projectId)
        .then((response) => {
          setProjectHistory(response.data);
        }),
      t,
      setWaitingForBackendResponse
    );
  }

  useEffect(() => {
    setWaitingForBackendResponse(true);
    setTitle(t(props.title) + " " + (location?.state?.projectName || ""));
    fetchProjectHistory();
  }, []);

  return (
    <div className="history-wrapper">
      {projectHistory ? (
        <>
          <CcvHeading
            text={t("PROJECT_HISTORY_LABEL")}
            size="small"
            testId="history_table_title"
          />
          <HistoryTable
            data={projectHistory}
            waitingForBackendResponse={waitingForBackendResponse}
            showFields={["creationDate", "status", "user", "revisionType"]}
            showTitle={true}
            showReleaseVersionInRow={true}
          />
        </>
      ) : (
        <EmptyIconBlock emptyText={"RELEASE_INFO_TEXT_NO_INFO"} translate={t} />
      )}
    </div>
  );
}

export default FullHistoryComponent;
