import React from "react";
import FormValidation from "../../../../../domain/FormValidation";
import RichTextEditor from "../../../../form/RichTextEditor";
import Dropzone from "../../../../form/Dropzone";
import { t } from "i18next";
import InputLabel from "../../../../form/InputLabel";

function FailedBody({ setValue, validate, translate }) {
  return (
    <div>
      <Dropzone
        isLabelBold={true}
        label={"UPLOAD_LOG_FILE_LABEL"}
        infoMessage={"SIGNING_FILE_DROPZONE_INFO"}
        setValue={setValue}
        acceptedExtensions={{
          "file/type": [".pdf", ".docx"],
          "image/png": [".png"],
          "image/jpeg": [".jpeg", ".jpg"],
          "text/plain": [".txt", ".log"],
          "image/bmp": [".bmp"],
        }}
        registerName="report"
        maxSize={FormValidation.MAX_REPORT_FILE_SIZE}
        shouldValidateOnInput={true}
      />
      <label className="input-label" />

      <InputLabel
        labelText={"DIALOG_FEEDBACK"}
        translate={translate}
        bold={true}
        className="richtextbox-label"
        required={true}
      />
      <RichTextEditor
        maxLength={FormValidation.MAX_FEEDBACK_LENGTH_LARGE}
        setValue={setValue}
        registerName={"feedback"}
        translate={t}
        validate={validate}
      />
    </div>
  );
}

export default FailedBody;
