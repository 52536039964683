import React, { useEffect, useState } from "react";
import Selector from "../../../../form/Selector";
import { CcvText } from "@ccv-oc-myccv/ccv-react-components";
import FormValidation from "../../../../../domain/FormValidation";
import Dropzone from "../../../../form/Dropzone";
import SelectorDefault from "../../../../../domain/SelectorDefault";
import ContractCheckbox from "../../../../form/ContractCheckbox";

function SuccessBody({
  translate,
  signerGroups,
  setValue,
  validate,
  control,
  setIsContractSigned,
  isContractSigned,
}) {
  const [signers, setSigners] = useState(getSignerGroupsFormat());

  function getSignerGroupsFormat() {
    return (
      signerGroups &&
      Object.entries(signerGroups).map((signerGroup) => {
        return {
          group: signerGroup[0],
          fullname: null,
          isOtherOptionSelected: false,
        };
      })
    );
  }

  function setSignerValue(group, fullname, isOtherOptionSelected) {
    setSigners(
      signers.map((signer) => {
        if (signer.group === group) {
          signer.fullname = fullname != "NOT_FILLED_IN" ? fullname : null;
          signer.isOtherOptionSelected = isOtherOptionSelected;
        }
        return signer;
      })
    );
  }

  function getSignerGroupOptions(signerGroup) {
    let signerOptions = [{ id: "OTHER", text: "Other" }];
    signerGroup
      ?.sort((a, b) =>
        b.fullName.toLowerCase().localeCompare(a.fullName.toLowerCase())
      )
      .map((signer) => {
        signerOptions.unshift({ id: signer.fullName, text: signer.fullName });
      });
    return signerOptions;
  }

  useEffect(() => {
    setValue(
      "signers",
      signers?.map((signer) => signer.fullname),
      { shouldValidate: true }
    );
  }, [signers]);

  useEffect(() => {
    setValue("feedback", "NOT_FILLED_IN", { shouldValidate: true });
  }, []);

  return (
    <div className="dialog-signer">
      {signers &&
        Object.entries(signerGroups).map((signerGroup, id) => (
          <Selector
            testId={signerGroup[0]?.toLowerCase()}
            registerName={signerGroup[0]}
            options={getSignerGroupOptions(signerGroup[1])}
            initialSelectedItem={SelectorDefault.signerDefault[0]}
            isOtherOptionSelected={signers[id].isOtherOptionSelected}
            labelText={translate("SIGNER_LABEL") + " " + (id + 1)}
            isLabelBold={true}
            required={true}
            setCustomKey={signerGroup[0]}
            setCustomSelectedResult={setSignerValue}
            control={control}
            maxLength={FormValidation.MAX_SIGNER_NAME_LENGTH}
            translate={translate}
            setInputResult={(input) => {
              setSignerValue(
                signerGroup[0],
                input.target.value ? input.target.value : null,
                true
              );
            }}
            inputPlaceholder={"SIGNER_INPUT_PLACEHOLDER"}
          />
        ))}

      <Dropzone
        label={"UPLOAD_SIGNED_APK_LABEL"}
        setValue={setValue}
        acceptedExtensions={{ "file/type": [".apk"] }}
        registerName="file"
        maxSize={FormValidation.MAX_APK_FILE_SIZE}
        isLabelBold={true}
        required={true}
        shouldValidateOnInput={true}
      />
      <ContractCheckbox
        translate={translate}
        testId={"signing_authorize"}
        setChecked={setIsContractSigned}
        text={"SIGNING_AUTHORIZE_LABEL"}
        isChecked={isContractSigned}
        registerName={"contract"}
        setValue={setValue}
      />
    </div>
  );
}

export default SuccessBody;
