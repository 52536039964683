import React from "react";
import BasicTable from "../../../components/tables/BasicTable";
import { CcvButton } from "@ccv-oc-myccv/ccv-react-components";
import EmptyIconBlock from "../../../components/emptyBlocks/EmptyIconBlock";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ShowForRole } from "../../../components/ShowForRole";

function LatestReleasesInfoComponent({ releases, project }) {
  const navigate = useNavigate();
  const [t] = useTranslation();

  return project && releases?.length > 0 ? (
    <>
      <BasicTable
        data={releases}
        filter={["creationDate", "version", "releaseStatus"]}
        navigationPage={"release"}
      />
      <div
        className="buttonblock-right"
        data-testid="btn-releases"
        style={{ display: releases.length > 0 ? "flex" : "none" }}
      >
        <CcvButton
          type="secondary"
          size="small"
          text={t("PROJECT_BUTTON_VIEW_ALL_RELEASES")}
          onClick={(event) => {
            navigate("/release/allreleases", {
              state: {
                projectId: project.id,
                projectName: project.projectName,
              },
            });
          }}
          testId="all_releases_button"
        />
        <ShowForRole permission="Developer">
          <CcvButton
            type="primary"
            size="small"
            text={t("RELEASE_BUTTON_ADD_RELEASE")}
            //time based solution until the next release
            disabled={project.paymentEngineTypes.length === 0}
            onClick={(event) => {
              navigate("/release/createRelease", {
                state: {
                  projectId: project.id,
                  projectName: project.projectName,
                  integrationType: project.integrationType,
                  paymentEngineTypes: project.paymentEngineTypes,
                },
              });
            }}
            testId="create_release_button"
          />
        </ShowForRole>
      </div>
    </>
  ) : (
    <div className="empty-block" data-testid="empty-releases">
      <EmptyIconBlock emptyText="PROJECT_INFO_TEXT_NO_RELEASES" translate={t} />
      {project && (
        <ShowForRole permission="Developer">
          <CcvButton
            type="primary"
            size="small"
            text={t("RELEASE_BUTTON_ADD_RELEASE")}
            //time based solution until the next release
            disabled={project.paymentEngineTypes?.length === 0}
            onClick={(event) => {
              navigate("/release/createRelease", {
                state: {
                  projectId: project.id,
                  projectName: project.projectName,
                  integrationType: project.integrationType,
                  paymentEngineTypes: project.paymentEngineTypes,
                },
              });
            }}
            testId="create_release_button"
          />
        </ShowForRole>
      )}
    </div>
  );
}

export default LatestReleasesInfoComponent;
