import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { ShowForRole } from "../../components/ShowForRole";
import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";

function Sidebar(props) {
  const [t] = useTranslation();
  const location = useLocation();

  return (
    <div className="sidebar ng-scope">
      <ul>
        <li>
          <a
            className={
              location.pathname === "/home" || location.pathname === "/"
                ? "active"
                : ""
            }
            href="/home"
            data-testid="sidebar_home_option"
          >
            <CcvMaterialIcon iconName="home" size="large" />
            <CcvText size="medium" color="dark-grey" textStyle="semi-bold">
              {t("MENU_PROJECTS")}
            </CcvText>
          </a>
        </li>

        <ShowForRole permission="Employee">
          <li>
            <a
              href="/actions"
              className={location.pathname === "/actions" ? "active" : ""}
              data-testid="sidebar_actions_option"
            >
              <CcvMaterialIcon iconName="assignment_turned_in" size="large" />
              <CcvText size="medium" color="dark-grey" textStyle="semi-bold">
                {t("MENU_ACTIONS")}
              </CcvText>
            </a>
          </li>
          <li>
            <a
              href="/traceability"
              className={location.pathname === "/traceability" ? "active" : ""}
              data-testid="sidebar_actions_option"
            >
              <CcvMaterialIcon iconName="find_in_page" size="large" />
              <CcvText size="medium" color="dark-grey" textStyle="semi-bold">
                {t("MENU_TRACEABILITY")}
              </CcvText>
            </a>
          </li>
        </ShowForRole>
      </ul>
    </div>
  );
}

export default Sidebar;
