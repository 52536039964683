import { CcvButton, CcvMaterialIcon } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";

function DownloadLink({ downloadFunction, translatedText, testId }) {
  return (
    <span onClick={downloadFunction} className="download-link">
      <CcvButton
        type="link"
        size="normal"
        text={translatedText}
        testId={testId?.toLowerCase()}
      />
      <CcvMaterialIcon iconName="download" extraClass="icon-btn-blue" />
    </span>
  );
}

export default DownloadLink;
