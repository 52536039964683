const ProjectSolution = {
  SOFTPOS: {
    key: "SOFTPOS",
    selector: { id: "SOFTPOS", text: "CCV SoftPOS" },
    description: "CCV SoftPOS",
    tooltip: "SOFTPOS_SOLUTION_TOOLTIP",
  },
  PHONEPOS: {
    key: "PHONEPOS",
    selector: {
      id: "PHONEPOS",
      text: "CCV PhonePOS",
    },
    description: "CCV PhonePOS",
    tooltip: "PHONEPOS_SOLUTION_TOOLTIP",
  },
  ONLINE_PAYMENTS: {
    key: "ONLINE_PAYMENTS",
    selector: {
      id: "ONLINE_PAYMENTS",
      text: "CCV Online Payments",
    },
    description: "CCV Online Payments",
    tooltip: "ONLINE_PAYMENTS_SOLUTION_TOOLTIP",
  },
  TERMINAL: {
    key: "TERMINAL",
    selector: {
      id: "TERMINAL",
      text: "CCV Terminal",
    },
    description: "CCV Terminal",
    tooltip: "TERMINAL_SOLUTION_TOOLTIP",
  },
};

export default ProjectSolution;
