import { CcvText, CcvTextInput } from "@ccv-oc-myccv/ccv-react-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import InputLabel from "./InputLabel";

function TextInput({
  setInput,
  field,
  setValue,
  maxLength,
  label,
  tooltipInfo,
  defaultInputData,
  validationError,
  disabled,
  placeholder,
  required,
  shouldValidateOnInput,
  clearErrors,
  isMaxCharHidden,
  className,
}) {
  const [inputData, setInputData] = useState(
    defaultInputData ? defaultInputData : ""
  );
  const [t] = useTranslation();

  function setTextInput(textinput) {
    setInputData(textinput);
    setInput && setInput(textinput);
    clearErrors && clearErrors(field);
    setValue &&
      setValue(field, textinput, {
        shouldValidate: shouldValidateOnInput,
        shouldTouch: true,
      });
  }

  return (
    <div
      className={
        "input input--text " + (className ? className : "form-input-spacing")
      }
    >
      <InputLabel
        labelText={label}
        tooltipInfo={tooltipInfo}
        required={required}
        translate={t}
      />
      <CcvTextInput
        id={field}
        testId={field}
        disabled={disabled}
        readonly={disabled}
        type="text"
        placeholder={placeholder && t(placeholder)}
        maxLength={maxLength}
        value={inputData}
        onChange={(e) => {
          setTextInput(e.target.value);
        }}
      />
      {!disabled && maxLength && !isMaxCharHidden ? (
        <CcvText
          color="medium-grey"
          size="small"
          testId="characters_remaining_text"
        >
          <span className="characters-left-bold">
            {maxLength - inputData.length} &nbsp;
          </span>
          {t("CHARACTERS_REMAINING")}
        </CcvText>
      ) : (
        <div className="no-max-length-padding"/>
      )}
      {validationError}
    </div>
  );
}

export default TextInput;
