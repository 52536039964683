import React from "react";
import { CcvButton } from "@ccv-oc-myccv/ccv-react-components";
import Textarea from "../form/Textarea";
import { useTranslation } from "react-i18next";
import ValidationError from "../form/ValidationError";
import FormValidation from "../../domain/FormValidation";
import { ShowForRole } from "../ShowForRole";
import { UpdatePermissionYupValidation } from "../../yupValidation/UpdatePermissionYupValidation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { trackPromise } from "react-promise-tracker";
import SpinnerService from "../../services/SpinnerService";
import { ApkInfoService } from "../../services/ApkInfoService";
import { ReleaseService } from "../../services/ReleaseService";
import PermissionStatus from "../../domain/PermissionStatus";

function PermissionUpdateInput({
  permission,
  textAreaClassName,
  setEditPermissionFormOpen,
  statusLabels,
  authService,
  project,
  releaseId,
  setRelease,
  permissionTypeLabels,
}) {
  const [t] = useTranslation();
  let releaseService = new ReleaseService(authService);
  let apkInfoService = new ApkInfoService(authService);
  const {
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(UpdatePermissionYupValidation(permission.reason)),
  });

  async function fetchReleaseData() {
    await authService.ensureAuthentication();
    return releaseService
      .getReleaseByIdAndBupaId(releaseId, authService.getBupaId())
      .then((response) => setRelease(response.data));
  }

  function onSubmit(data) {
    trackPromise(
      SpinnerService.successSpinner(
        apkInfoService
          .patchPermissions(project.id, getPermissionUpdatePayload(data))
          .then(() => {
            setEditPermissionFormOpen(false);
            fetchReleaseData();
          }),
        t,
        "TOAST_RELEASE_UPDATED"
      ),
      "update-permission"
    );
  }

  function getPermissionUpdatePayload(data) {
    return [
      {
        name: permission.name,
        reason: data.permission,
        status: PermissionStatus.UPDATED_BY_CCV,
      },
    ];
  }

  return (
    <>
      <Textarea
        className={textAreaClassName}
        classNameBlock="no-spacing"
        translate={t}
        labelText="REASON_LABEL"
        showForRole="Employee"
        statusLabels={statusLabels}
        permissionTypeLabels={permissionTypeLabels}
        field={"permission"}
        defaultInputData={permission?.reason}
        setValue={setValue}
        required={true}
        clearErrors={clearErrors}
        shouldValidateOnInput={false}
        maxLength={FormValidation.MAX_REASON_INPUT_LENGTH}
        validationError={
          errors && (
            <ValidationError
              error={errors["permission"]}
              errorText={t("PERMISSION_ADD_REASON")}
              testId="permission_reason_error"
            />
          )
        }
      />
      <ShowForRole permission="Employee">
        <div className="update-reason-permissions-buttons">
          <CcvButton
            type="tertiary"
            size="normal"
            text={t("CANCEL_LABEL")}
            onClick={() => setEditPermissionFormOpen(false)}
          />
          <CcvButton
            type="primary"
            size="normal"
            text={t("SAVE_LABEL")}
            onClick={handleSubmit(onSubmit)}
            testId="update_permission_save_button"
          />
        </div>
      </ShowForRole>
    </>
  );
}

export default PermissionUpdateInput;
