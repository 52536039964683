import React, { useEffect, useState } from "react";
import EmptyIconBlock from "../components/emptyBlocks/EmptyIconBlock";
import { useTranslation } from "react-i18next";
import { Spinner } from "../components/Loading/Spinner";
import LoadingBlock from "../components/Loading/LoadingBlock";

function AuthorizedView({ authService, noEmptyBlock, children }) {
  const [roles, setRoles] = useState();
  const [t] = useTranslation();
  useEffect(() => {
    authService.getRoles().then((response) => {
      setRoles(response);
    });
  }, []);

  return roles && authService.isValidRoleForDevCentre(roles)
    ? children
    : noEmptyBlock || (
        <div className="unauthorized-error">
          {authService?.user && (
            <EmptyIconBlock emptyText={"TOAST_UNAUTHORISED"} translate={t} />
          )}
        </div>
      );
}

export default AuthorizedView;
