import Environment from "./Environment";
import ProjectSolution from "./ProjectSolution";

const TooltipText = {
  INTEGRATION_NAME: ["INTEGRATION_NAME_TOOLTIP"],
  SOLUTION: getTooltipText(ProjectSolution),
  ENVIRONMENT: getTooltipText(Environment),
  PAYMENT_ENGINE: [
    "PAYMENT_ENGINE_INTRO_TOOLTIP",
    "PAYMENT_ENGINE_TYPE_TOOLTIP",
    "PAYMENT_ENGINE_CONTACT_TOOLTIP",
  ],
  PAYMENT_ENGINE_VERSION: [
    "PAYMENT_ENGINE_VERSION_INTRO_TOOLTIP",
    "PAYMENT_ENGINE_VERSION_APASPP_TOOLTIP",
    "PAYMENT_ENGINE_VERSION_OTHER_TOOLTIP",
    "PAYMENT_ENGINE_VERSION_OTHER_INPUT_TOOLTIP",
  ],
  MAPI_VERSION: ["MAPI_VERSION_TOOLTIP", "MAPI_VERSION_INFO_TOOLTIP"],
  RELEASENOTES: ["RELEASE_NOTES_TOOLTIP"],
  APPLICATION_INFORMATION: ["APPLICATION_INFORMATION_TOOLTIP"],
  SCREENSHOT_ORDER_INFORMATION: ["SCREENSHOT_ORDER_TOOLTIP"],
};

function getTooltipText(options) {
  return options && Object.values(options).map((option) => option.tooltip);
}

export default TooltipText;
