import { CcvSelect, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import InputLabel from "./InputLabel";
import { useTranslation } from "react-i18next";

function Selector({
  registerName,
  control,
  options,
  initialSelectedItem,
  isOtherOptionSelected,
  labelText,
  tooltipInfo,
  setSelectedResult,
  setCustomSelectedResult,
  setCustomKey,
  setInputResult,
  maxLength,
  translate,
  testId,
  validationError,
  inputPlaceholder,
  isLabelBold,
  disabled,
  infoText,
  required,
}) {
  const { t } = useTranslation();
  const [selectorValue, setSelectorValue] = useState({
    id: initialSelectedItem.id,
    text: t(initialSelectedItem.text),
  });

  function setSelectedValue(item) {
    setSelectorValue({ id: item.id, text: item.text });
    setSelectedResult && setSelectedResult(item.id);
    setCustomSelectedResult &&
      setCustomSelectedResult(
        setCustomKey,
        item.id === "OTHER" ? "NOT_FILLED_IN" : item.text,
        item.id === "OTHER"
      );
  }

  return (
    <div>
      <Controller
        control={control && control}
        name={registerName}
        render={({ field: { onChange } }) => (
          <>
            <InputLabel
              labelText={labelText}
              tooltipInfo={tooltipInfo}
              translate={translate}
              bold={isLabelBold}
              required={required}
            />
            <CcvSelect
              items={options}
              initialSelectedItem={selectorValue}
              onSelectionChanged={(item) => setSelectedValue(item)}
              testId={(testId ? testId : registerName) + "_option_select"}
              disabled={disabled}
              infoText={infoText}
            />
            {isOtherOptionSelected && (
              <div className="input input--text">
                <input
                  data-testid={
                    (testId ? testId : registerName?.toLowerCase()) +
                    "_other_input"
                  }
                  maxLength={maxLength && maxLength}
                  placeholder={
                    inputPlaceholder
                      ? translate(inputPlaceholder)
                      : translate("VERSION_INPUT_LABEL")
                  }
                  type="text"
                  onInput={
                    setInputResult
                      ? setInputResult
                      : (input) =>
                          onChange(
                            input.target.value
                              ? input.target.value + "_OTHER"
                              : "NOT_FILLED_IN"
                          )
                  }
                />
              </div>
            )}
            {infoText ? (
              <CcvText
                color="medium-grey"
                size="small"
                testId={infoText.toLowerCase()}
              >
                {t(infoText)}
              </CcvText>
            ) : (
              <div className="no-info-padding"></div>
            )}
          </>
        )}
      />
      {validationError && validationError}
    </div>
  );
}

export default Selector;
