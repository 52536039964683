import React, { useState } from "react";
import "../../style/Switch.scss";
import { CcvText } from "@ccv-oc-myccv/ccv-react-components";

function Switch({ setValue, translatedSwitchText, isChecked, testId }) {
  const [isOn, setIsOn] = useState(isChecked);

  function handleSwitchToggle() {
    setIsOn(!isOn);
    setValue && setValue(!isOn);
  }

  return (
    <div className="switch-container">
      <CcvText>{translatedSwitchText}</CcvText>
      <label className="switch">
        <input type="checkbox" checked={isOn} onChange={handleSwitchToggle} />
        <span className="slider" data-testid={testId} />
      </label>
    </div>
  );
}

export default Switch;
