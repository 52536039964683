import { CcvText } from "@ccv-oc-myccv/ccv-react-components";
import FormValidation from "../../../domain/FormValidation";
import TextInput from "../../form/TextInput";
import ValidationError from "../../form/ValidationError";

function Log4jVersionBody({
  item,
  requirementTest,
  defaultCompleted,
  setValue,
  errors,
  translate,
}) {
  return (
    <>
      {item.texts.map((item) => (
        <CcvText size="medium" color="medium-grey">
          {translate(item)}
        </CcvText>
      ))}
      <TextInput
        defaultInputData={requirementTest && requirementTest.log4jVersion}
        disabled={defaultCompleted}
        field="log4jVersion"
        setValue={setValue}
        maxLength={FormValidation.MAX_LOG4JVERSION_LENGTH}
        label="RELEASE_NAME_LABEL"
        shouldValidateOnInput={false}
        validationError={
          errors && (
            <ValidationError
              errorText={translate("LOG4J_REQUIRED_TEXT")}
              error={errors["log4jVersion"]}
              testId="log4j_version_error"
            />
          )
        }
      />
    </>
  );
}

export default Log4jVersionBody;
