import { AppContext } from "../../config/AppContext";
import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Role from "../../domain/Role";

function PostLogin({ component: Component, ...rest }) {
  const { authService } = useContext(AppContext);
  const navigate = useNavigate();

  useEffect(() => {
    authService
      ?.handlePostLogin()
      .then()
      .catch((error) => {
        console.log("Error postlogin", error);
      });
  });

  return <React.Fragment />;
}
export default PostLogin;
