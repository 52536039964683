import {
  CcvCheckbox,
  CcvHeading,
  CcvMaterialIcon,
  CcvText,
} from "@ccv-oc-myccv/ccv-react-components";
import React, { useEffect, useState } from "react";
import "../../style/TestCatalogue.scss";
import TestCatalogueInfo from "../../domain/TestCatalogueInfo";
import { useTranslation } from "react-i18next";
import ValidationError from "../form/ValidationError";
import RequirementTestSection from "./RequirementTestSection";

function RequirementTestCatalogue({
  DialogClosedOnDefault,
  defaultCompleted,
  requirementTest,
  errors,
  setValue,
  clearError,
}) {
  const REQUIREMENT_TEST = "requirementTests";
  const [sectionCompleted, setSectionCompleted] = useState(
    Object.keys(TestCatalogueInfo).map((item) => {
      return { id: item, completed: false };
    })
  );
  const [isRequirementChecked, setRequirementChecked] = useState(false);
  const [allSectionsCompleted, setAllSectionsCompleted] = useState(false);
  const [t] = useTranslation();

  function setNewSectionCompleted(id, value) {
    setSectionCompleted(
      sectionCompleted.map((item) => {
        if (item.id === id) {
          item.completed = value;
        }
        return item;
      })
    );
  }

  function areAllSectionsCompleted() {
    if (
      isRequirementChecked &&
      sectionCompleted.every((item) => item.completed)
    ) {
      setValue(REQUIREMENT_TEST, true);
      setAllSectionsCompleted(true);
    } else {
      setValue(REQUIREMENT_TEST, null);
      setAllSectionsCompleted(false);
    }
  }

  useEffect(() => {
    !defaultCompleted && areAllSectionsCompleted();
  }, [isRequirementChecked, setNewSectionCompleted]);

  useEffect(() => {
    clearError && clearError(REQUIREMENT_TEST);
  }, [allSectionsCompleted]);

  return (
    <>
      <div className="catalogue">
        {defaultCompleted && (
          <CcvHeading text={t("REQUIREMENT_TITLE")} size="small" />
        )}
        <CcvText size="medium" color="dark-grey">
          {t("REQUIREMENT_INFO")}
        </CcvText>
        {Object.keys(TestCatalogueInfo).map((item) => (
          <RequirementTestSection
            DialogClosedOnDefault={DialogClosedOnDefault}
            sectionName={item}
            defaultCompleted={defaultCompleted}
            setSectionCompleted={(id, value) =>
              setNewSectionCompleted(id, value)
            }
            setValue={setValue}
            errors={errors}
            requirementTest={requirementTest}
          />
        ))}
        {!defaultCompleted && (
          <div className="requirement-test-contract">
            <CcvText
              testId="contract_requirement_test_title"
              textStyle="semi-bold"
              size="large"
              color="dark-grey"
            >
              {t("CONFIRMATION_TEXT")}
            </CcvText>
            <div className="catalogue-block">
              <span className="catalogue-text">
                <CcvText>{t("BOX_MESSAGE_CATALOGUE")}</CcvText>
              </span>
              <span
                className="executed-checkbox"
                data-testid="catalogue_contract"
              >
                <CcvCheckbox
                  id="requirementAccept"
                  checked={isRequirementChecked}
                  data-testid="catalogue_contract"
                  labelText={t("READ_AND_CONFIRM_TEXT")}
                  onChange={() => setRequirementChecked(!isRequirementChecked)}
                />
              </span>
            </div>
          </div>
        )}
        {!defaultCompleted && (
          <ValidationError
            error={errors[REQUIREMENT_TEST]}
            errorText={t("REQUIREMENT_TEST_REQUIRED")}
            testId={"requirement_test_not_completed_error"}
          />
        )}
      </div>
    </>
  );
}

export default RequirementTestCatalogue;
