import { CcvText } from "@ccv-oc-myccv/ccv-react-components";
import { Table, TableCell, TableRow } from "@mui/material";
import React from "react";
import FormatService from "../../../services/FormatService";
import EditableSelectList from "./EditableSelectList";
import EditableSelect from "./EditableSelect";
import DownloadLink from "../../common/button/DownloadLink";
import Link from "../../common/button/Link";
import TextCopyDownload from "./TextCopyDownload";
import ScannerResultWithList from "./ScannerResultWithList";
import SecurityscanFeedbackAccordion from "../../common/SecurityscanFeedbackAccordion";

function RowTable({
  translate,
  data,
  editActive,
  formControl,
  isBorderDisabled,
}) {
  function getFormatData(item) {
    if (!item.data || item.unavailableData) {
      return item.emptyMessage ? (
        <CcvText testId={item.emptyMessage} color="grey">
          {item.emptyMessage}
        </CcvText>
      ) : (
        <CcvText testId="no_info_available" color="grey">
          {translate("RELEASE_INFO_TEXT_NO_INFO")}
        </CcvText>
      );
    }

    switch (item.type) {
      case "date":
        return FormatService.getMediumDateFormat(item.data);
      case "link":
        return (
          <Link
            onClick={item.function}
            isExternalLink={item.isExternalLink}
            testId={item.data}
          >
            {translate(item.data)}
          </Link>
        );
      case "download":
        return (
          <DownloadLink
            downloadFunction={item.function}
            translatedText={translate(item.data)}
            testId={item.data}
          />
        );
      case "editable_select":
        return (
          <EditableSelect
            selectInfo={item}
            translate={translate}
            formControl={formControl}
            isCustomInput={isCustomInput}
            editActive={editActive}
          />
        );
      case "editable_select_list":
        return (
          <EditableSelectList
            selectorList={item}
            translate={translate}
            isCustomInput={isCustomInput}
            formControl={formControl}
            editActive={editActive}
          />
        );
      case "text_copy_download":
        return <TextCopyDownload buttonInfo={item} translate={translate} />;
      case "scanner_result_with_list":
        return (
          <ScannerResultWithList
            translatedButtonText={
              item.buttonText
                ? translate(item.buttonText)
                : translate("VIEW_ONLINE_LABEL")
            }
            testId={item.testId}
            url={item.url}
            data={item.data}
            isExternalLink={item.isExternalLink}
            isButton={item.isButton}
            navigationInfo={item.navigationInfo}
            resultList={item.list}
            maxEntries={item.maxEntries}
            maxEntriesInfoMessage={item.maxEntriesInfoMessage}
            translate={translate}
          >
            {item.element}
          </ScannerResultWithList>
        );
      case "element":
        return item.data;
      case "list":
        return item.data
          .map((option) => translate(item.options[option].description))
          .join(", ");
      case "downloadAndImage":
        return (
          <span className="downloadAndImage">
            <DownloadLink
              downloadFunction={item.function}
              translatedText={translate(item.data)}
              testId={item.data}
            />
            {item.image}
          </span>
        );
      case "feedback_view":
        function isLastFeedbackItem(itemData) {
          return item?.data?.[item?.data?.length - 1] === itemData;
        }
        return (
          <div className="extra-info-securityscan-feedback-container">
            {item?.data?.map((valueItem) => (
              <SecurityscanFeedbackAccordion
                item={valueItem}
                dialogSubtitle={"EXTRA_INFO_SECURITYSCAN_FEEDBACK"}
                translate={translate}
                FormatService={FormatService}
                isLastItem={isLastFeedbackItem(valueItem)}
                releaseId={item?.releaseId}
                permissions={item?.permissions}
              />
            ))}
          </div>
        );
      case "unavailable":
        return (
          <CcvText testId={item?.data?.toLowerCase()} color="grey">
            {translate(item.data)}
          </CcvText>
        );
      default:
        return (
          <span data-testid={item?.data?.toLowerCase()}>
            {translate(item.data)}
          </span>
        );
    }
  }

  function isCustomInput(data) {
    if (data) {
      return data?.includes("_OTHER")
        ? data.split("_OTHER")[0] + " (" + translate("OPTION_OTHER") + ")"
        : translate(data);
    } else {
      return translate("NOT_FILLED_IN");
    }
  }

  return (
    <Table>
      {data.map(
        (item) =>
          item && (
            <TableRow
              className={"row-style " + (isBorderDisabled && "border-disabled")}
            >
              <TableCell className="title" colSpan={item?.fullDataView ? 2 : 1}>
                <CcvText size="medium" color="dark-grey" textStyle="bold">
                  {translate(item.columnName)}
                  {item.columnRequired && " *"}
                </CcvText>
                <CcvText
                  size="small"
                  color={item.columnSubTextColor}
                  testId={item.columnSubText?.toLowerCase()}
                >
                  {translate(item.columnSubText)}
                </CcvText>

                {item?.fullDataView && (
                  <div className="data">
                    <CcvText size="medium" color="dark-grey">
                      {getFormatData(item)}
                    </CcvText>
                  </div>
                )}
              </TableCell>

              {!item?.fullDataView && (
                <TableCell className="data">
                  <CcvText size="medium" color="dark-grey">
                    {getFormatData(item)}
                  </CcvText>
                </TableCell>
              )}
            </TableRow>
          )
      )}
    </Table>
  );
}

export default RowTable;
