const Environment = {
  ATTENDED: {
    key: "ATTENDED",
    description: "Attended",
    tooltip: "ATTENDED_TOOLTIP",
  },
  UNATTENDED: {
    key: "UNATTENDED",
    description: "Unattended",
    tooltip: "UNATTENDED_TOOLTIP",
  },
  SEMI_UNATTENDED: {
    key: "SEMI_UNATTENDED",
    description: "Semi-unattended",
    tooltip: "SEMI_UNATTENDED_TOOLTIP",
  },
};

export default Environment;
