import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export function ScrollToTop({ children, doScroll }) {
  const { pathname, search } = useLocation();

  useEffect(() => {
    ScrollToPageTop();
  }, [pathname, search]);

  return children;
}

export function ScrollToPageTop() {
  return window.scrollTo(0, 0, "smooth");
}
