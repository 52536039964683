import * as yup from "yup";

export function FunctionalTestingYupValidation() {
  return yup.object({
    status: yup.string().required("required").typeError("required"),
    report: yup.array(),
    feedback: yup
      .string()
      .when("status", ([status]) =>
        status === "FUNCTIONAL_TESTING_SUCCESS"
          ? yup.string().notRequired()
          : yup.string().required("required")
      ),
    overruler: yup.string().max(30).required("required").typeError("required"),
    contract: yup
      .boolean()
      .required("The terms and conditions must be accepted.")
      .oneOf([true], "The terms and conditions must be accepted."),
  });
}
