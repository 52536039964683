const TechnologyType = {
  NATIVE_ANDROID: {
    key: "NATIVE_ANDROID",
    description: "Native Android",
  },
  XAMARIN: {
    key: "XAMARIN",
    description: "Xamarin",
  },
  REACT_NATIVE: {
    key: "REACT_NATIVE",
    description: "React-Native",
  },
  FLUTTER: {
    key: "FLUTTER",
    description: "Flutter",
  },
  OTHER: {
    key: "OTHER",
    description: "Other",
  },
};

export default TechnologyType;
