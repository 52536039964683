import { CcvText } from "@ccv-oc-myccv/ccv-react-components";
import alibarray from "alib-array";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import FormValidation from "../../domain/FormValidation";
import InputLabel from "./InputLabel";
import ValidationError from "./ValidationError";
import {
  ApkDropzoneWithFiles,
  ApkDropzoneWithoutFiles,
} from "./dropzoneBodies/ApkBody";
import {
  DropzoneWithFiles,
  DropzoneWithoutFiles,
} from "./dropzoneBodies/DefaultBody";
import {
  AppIconDropzoneWithFiles,
  ImageDropzoneWithoutFiles,
  ScreenshotDropzoneWithFiles,
} from "./dropzoneBodies/ImageBody";

function Dropzone({
  label,
  labelInfoMessage,
  infoMessage,
  setValue,
  setCustomValue,
  acceptedExtensions,
  registerName,
  maxSize,
  errors,
  errorText,
  rejectedErrorText,
  maxFiles,
  defaultValue,
  setDeletionFiles,
  disabled,
  processing,
  required,
  tooltipInfo,
  isLabelBold,
  shouldValidateOnInput,
  clearError,
  minFiles,
}) {
  const [t] = useTranslation();
  const [files, setFiles] = useState(
    defaultValue ? getFormattedImageList(defaultValue) : []
  );
  const [isDataFromSharepoint, setIsDataFromSharepoint] = useState(
    defaultValue ? true : false
  );
  const [indexOfFileToChange, setIndexOfFileToChange] = useState();
  const { fileRejections, getRootProps, getInputProps, open, isDragActive } =
    useDropzone({
      onDrop: (acceptedFiles) => {
        setAcceptedFiles(acceptedFiles);
        setValue(registerName, files, {
          shouldTouch: true,
          shouldValidate: shouldValidateOnInput,
        });
        setCustomValue && setCustomValue(acceptedFiles);
      },
      onFileDialogCancel: () => {
        setIndexOfFileToChange(null);
      },
      onDropRejected: () => {
        setIndexOfFileToChange(null);
      },
      noClick: files?.length > 0,
      accept: acceptedExtensions,
      maxFiles: maxFiles ? maxFiles - files.length : 1,
      maxSize: maxSize,
      minSize: FormValidation.MIN_FILE_SIZE,
      validator: maxFiles && nameExistsValidator,
    });

  function nameExistsValidator(file) {
    if (files.map((item) => item.name).includes(file.name)) {
      setIndexOfFileToChange(null);
      return {
        code: "file-exists",
      };
    }
    return null;
  }

  useEffect(() => {
    setValue(registerName, files, { shouldValidate: shouldValidateOnInput });
    if (clearError && !shouldValidateOnInput) {
      if (minFiles && files.length >= minFiles) {
        clearError(registerName);
      } else {
        files.length > 0 && clearError(registerName);
      }
    }
  }, [indexOfFileToChange, files]);

  useEffect(() => {
    indexOfFileToChange != null && open();
  }, [indexOfFileToChange]);

  function getFormattedImageList(files) {
    return files.map((file) =>
      Object.assign(file, {
        deletionName: file.name,
      })
    );
  }

  function moveImage(imageIndex, targetImageIndex) {
    let help = [...files];
    alibarray(help).swap(imageIndex, targetImageIndex);
    setFiles(help);
  }

  function replaceFile(acceptedFiles) {
    let editableFileList = files;
    editableFileList[indexOfFileToChange].deletionName &&
      setDeletionFiles(editableFileList[indexOfFileToChange].deletionName);

    editableFileList[indexOfFileToChange] = Object.assign(acceptedFiles[0], {
      preview: URL.createObjectURL(acceptedFiles[0]),
    });
    setFiles(editableFileList);
    setIndexOfFileToChange(null);
  }

  function setListOfFiles(acceptedFiles) {
    if (acceptedFiles.length + files.length <= maxFiles) {
      setFiles([
        ...files,
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
      setIndexOfFileToChange(null);
    }
  }

  function setAcceptedFiles(acceptedFiles) {
    if (indexOfFileToChange != null && indexOfFileToChange != "ADD") {
      replaceFile(acceptedFiles);
    } else if (maxFiles) {
      setListOfFiles(acceptedFiles);
    } else {
      setFiles([
        ...acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    }
  }

  function addFile() {
    setIndexOfFileToChange("ADD");
  }

  function changeFile(key) {
    setIndexOfFileToChange(key);
  }

  function removeFile(key) {
    let editableFileList = [...files];
    editableFileList[key].deletionName &&
      setDeletionFiles(editableFileList[key].deletionName);
    editableFileList.splice(key, 1);
    setFiles(editableFileList);
  }

  function removeAllFiles() {
    setFiles([]);
    setCustomValue && setCustomValue();
  }

  function getBodyWithFilesByType() {
    switch (registerName) {
      case "apk":
        return (
          <ApkDropzoneWithFiles
            files={files}
            translate={t}
            disabled={disabled}
            OpenFileExplorer={open}
            removeAllFiles={removeAllFiles}
            isDragActive={isDragActive}
            processing={processing}
          />
        );
      case "appIcon":
        return (
          <AppIconDropzoneWithFiles
            files={files}
            openFileExplorer={open}
            registerName={registerName}
            disabled={disabled}
            infoMessage={infoMessage}
            isDragActive={isDragActive}
            translate={t}
          />
        );
      case "screenshots":
        return (
          <>
            <ScreenshotDropzoneWithFiles
              changeFile={changeFile}
              infoMessage={infoMessage}
              registerName={registerName}
              files={files}
              removeFile={removeFile}
              openFileExplorer={addFile}
              maxFiles={maxFiles}
              translate={t}
              disabled={disabled}
              isDragActive={isDragActive}
              moveImage={moveImage}
              isImageFromSharepoint={isDataFromSharepoint}
            />
          </>
        );
      default:
        return (
          <DropzoneWithFiles
            files={files}
            removeAllFiles={removeAllFiles}
            isDragActive={isDragActive}
            OpenFileExplorer={open}
            translate={t}
            required={!label && required}
          />
        );
    }
  }

  function getBodyWithoutFilesByType() {
    switch (registerName) {
      case "apk":
        return (
          <ApkDropzoneWithoutFiles
            translate={t}
            isDragActive={isDragActive}
            required={required}
          />
        );
      case "appIcon":
      case "screenshots":
        return (
          <ImageDropzoneWithoutFiles
            openFileExplorer={open}
            registerName={registerName}
            isDragActive={isDragActive}
          />
        );
      default:
        return (
          <DropzoneWithoutFiles
            required={!label && required}
            translate={t}
            isDragActive={isDragActive}
          />
        );
    }
  }

  function getRejectedError(errorCode) {
    switch (errorCode) {
      case "too-many-files":
        return t("TO_MANY_FILES_ERROR");
      case "file-too-large":
        return t("FILE_TOO_LARGE_ERROR");
      case "file-exists":
        return t("FILE_ALREADY_EXISTS");
      default:
        return t(rejectedErrorText ? rejectedErrorText : "FILE_NOT_ALLOWED");
    }
  }

  function getRejectedDropzoneBody() {
    return (
      fileRejections.length > 0 && (
        <CcvText size="small" color="red" testId="file_not_allowed_error">
          {getRejectedError(fileRejections[0]?.errors[0]?.code)}
        </CcvText>
      )
    );
  }

  return (
    <>
      <span className="label-with-message">
        <InputLabel
          labelText={label}
          tooltipInfo={tooltipInfo}
          translate={t}
          bold={isLabelBold}
          required={required}
        />

        <CcvText size="medium" color="medium-grey">
          {labelInfoMessage}
        </CcvText>
      </span>
      <div data-testid={registerName + "-dropzone"} className={"dropzone-box"}>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {files.length === 0 && getBodyWithoutFilesByType()}
          {files.length > 0 && getBodyWithFilesByType()}
        </div>
      </div>
      {infoMessage && files.length === 0 && (
        <CcvText
          size="small"
          color="medium-grey"
          testId={infoMessage.toLowerCase()}
        >
          {t(infoMessage)}
        </CcvText>
      )}
      {getRejectedDropzoneBody()
        ? getRejectedDropzoneBody()
        : errors && (
            <ValidationError
              error={errors[registerName]}
              errorText={t(errorText)}
              testId={registerName.toLowerCase() + "-error"}
            />
          )}
    </>
  );
}

export default Dropzone;
