import { CcvRadioButton, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const RadioButtonList = ({
  register,
  name,
  options,
  direction,
  setValue,
  initialSelectedValue,
  validate,
  validationError,
}) => {
  const [t] = useTranslation();
  const [selectedValue, setSelectedValue] = useState(
    initialSelectedValue && initialSelectedValue
  );
  return (
    <div className={"radiobuttonList-" + direction}>
      {Object.keys(options).map((item) => (
        <CcvRadioButton
          id={item}
          labelText={
            <span data-testid={item.toLowerCase() + "_option"}>
              {t(options[item].description)}
            </span>
          }
          groupName={name}
          key={item}
          value={item}
          onChange={(item) => {
            register &&
              register(
                options[item].value != null ? options[item].value : item
              );
            setValue && setValue(item);
            setSelectedValue(item);
            validate && validate(name);
          }}
          checked={selectedValue === item}
        />
      ))}
      {validationError}
    </div>
  );
};

export default RadioButtonList;
