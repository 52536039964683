import React, { useContext, useEffect } from "react";
import { AppContext } from "../../config/AppContext";

function HandleLogout() {
  const { authService } = useContext(AppContext);

  useEffect(() => {
    if (!authService) return;
    authService
      .handleLogout()
      .then((response) => console.log("logged out"))
      .catch(() => console.log("something went wrong while logging out"));
  });

  return <React.Fragment />;
}

export default HandleLogout;
