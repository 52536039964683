export const ApkValidationInfo = {
  EMPTY_PACKAGE_NAME: {
    infoText: "VALIDATION_EMPTY_PACKAGE_NAME",
    url: "",
  },
  EMPTY_VERSION_NAME: {
    infoText: "VALIDATION_EMPTY_VERSION_NAME",
    url: "",
  },
  EMPTY_VERSION_CODE: {
    infoText: "VALIDATION_EMPTY_VERSION_CODE",
    url: "",
  },
  INVALID_PACKAGE_NAME: {
    infoText: "VALIDATION_ERROR_PACKAGE_NAME",
    url: "https://developer.android.com/build/configure-app-module",
  },
  INVALID_VERSION_NAME: {
    infoText: "VALIDATION_ERROR_VERSION_NAME",
    url: "https://developer.android.com/studio/publish/versioning",
  },
  INVALID_VERSION_CODE: {
    infoText: "VALIDATION_ERROR_VERSION_CODE",
    url: "https://developer.android.com/studio/publish/versioning",
  },
  INVALID_SIGNATURE: {
    infoText: "VALIDATION_ERROR_SIGNATURE",
    url: "https://source.android.com/docs/security/features/apksigning",
  },
  NO_SIGNATURE_FOUND: {
    infoText: "VALIDATION_EMPTY_SIGNATURE",
    url: "https://source.android.com/docs/security/features/apksigning",
  },
  INVALID_SIGNATURE_VERSION: {
    infoText: "VALIDATION_ERROR_SIGNATURE_VERSION",
    url: "https://source.android.com/docs/security/features/apksigning",
    except: "DEPRECATED_V1_SIGNATURE",
  },
  INVALID_DEBUG_MANIFEST: {
    infoText: "VALIDATION_ERROR_DEBUG_MANIFEST",
    url: "https://developer.android.com/privacy-and-security/risks/android-debuggable",
  },
  DEPRECATED_V1_SIGNATURE: {
    infoText: "VALIDATION_DEPRECATED_V1",
    url: "https://source.android.com/docs/security/features/apksigning",
  },
  INVALID_DEBUG_CERTIFICATE: {
    infoText: "VALIDATION_ERROR_DEBUG_CERTIFICATE",
    url: "",
  },
};
