import React from "react";
import HistoryTable from "../../../components/tables/HistoryTable";
import { CcvMaterialIcon, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import EmptyIconBlock from "../../../components/emptyBlocks/EmptyIconBlock";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function HistoryInfoComponent({ projectHistory, project }) {
  const [t] = useTranslation();
  const navigate = useNavigate();

  return projectHistory?.length > 0 ? (
    <>
      <div
        className={
          "history-block-table " + (projectHistory?.length >= 5 && "shrinked")
        }
        data-testid="history_table"
      >
        <HistoryTable
          data={projectHistory}
          showFields={["creationDate", "status", "user", "revisionType"]}
          showTitle={true}
          showReleaseVersionInRow={true}
        />
      </div>
      {projectHistory?.length >= 5 && (
        <>
          <div className="fade"></div>
          <span className="show-history-btn">
            <button
              className="history-button"
              data-testid="btn-full-history"
              onClick={(event) => {
                navigate("/project/history", {
                  state: {
                    projectId: project.id,
                    projectName: project.projectName,
                  },
                });
              }}
            >
              <CcvMaterialIcon iconName="arrow_downward" size="small" />
              <CcvText
                size="medium"
                color="medium-grey"
                testId="history_button"
              >
                {t("PROJECT_BUTTON_VIEW_FULL_HISTORY")}
              </CcvText>
            </button>
          </span>
          <div className="fadeout"></div>
        </>
      )}
    </>
  ) : (
    <EmptyIconBlock emptyText="PROJECT_NO_HISTORY_LABEL" translate={t} />
  );
}

export default HistoryInfoComponent;
