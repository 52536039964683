import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReleaseService } from "../../services/ReleaseService";
import "../../style/CreateForm.scss";
import "../../style/Detail.scss";
import "../../style/Button.scss";
import { AppContext } from "../../config/AppContext";
import "react-toastify/dist/ReactToastify.css";
import {
  CcvButton,
  CcvHeading,
  CcvMessage,
} from "@ccv-oc-myccv/ccv-react-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ShowForRole } from "../../components/ShowForRole";
import SpinnerService from "../../services/SpinnerService";
import { ProjectService } from "../../services/ProjectService";
import Role from "../../domain/Role";
import { trackPromise } from "react-promise-tracker";
import GeneralInfoComponent from "./ProjectDetailComponents/GeneralInfoComponent";
import CcvStoreInfoComponent from "./ProjectDetailComponents/CcvStoreInfoComponent";
import LatestReleasesInfoComponent from "./ProjectDetailComponents/LatestReleasesInfoComponent";
import HistoryInfoComponent from "./ProjectDetailComponents/HistoryInfoComponent";
import { CcvStoreInfoService } from "../../services/CcvStoreInfoService";
import { toast } from "react-toastify";
import DocumentationComponent from "./ProjectDetailComponents/DocumentationCatalogue";
import multiDownload from "multi-download";
import { Spinner } from "../../components/Loading/Spinner";

function ProjectDetailComponent(props) {
  const { setTitle, authService } = useContext(AppContext);
  let projectService = new ProjectService(authService);
  let releaseService = new ReleaseService(authService);
  let ccvStoreService = new CcvStoreInfoService(authService);
  const location = useLocation();
  const navigate = useNavigate();
  const projectId = useParams().id;
  const [project, setProject] = useState();
  const [ccvStoreInfo, setCcvStoreInfo] = useState();
  const [releases, setReleases] = useState();
  const [projectHistory, setProjectHistory] = useState();
  const [roles, setRoles] = useState(null);
  const [isAccessDenied, setIsAccessDenied] = useState(false);
  const [t] = useTranslation();

  function downloadImagesFromSharepoint(fileType, files) {
    return SpinnerService.defaultSpinner(
      Promise.all(
        files.map((file) =>
          projectService
            .getDownloadLink(project.id, fileType, file.name)
            .then((response) => {
              return response.data;
            })
        )
      ).then((urls) => {
        multiDownload(urls);
      }),
      t,
      "TOAST_DOWNLOADING",
      "TOAST_DOWNLOAD_SUCCEEDED"
    );
  }

  function getRoles() {
    return authService.getRoles().then((response) => {
      setRoles(response);
    });
  }

  async function fetchProject() {
    if (authService.isEmployee(roles)) {
      return fetchProjectInfoAsEmployee();
    } else {
      return fetchProjectInfoAsDeveloper();
    }
  }

  function fetchProjectInfoAsEmployee() {
    fetchprojectHistoryAndReleaseAndStoreInfo();
    return projectService.getProjectById(projectId).then((response) => {
      setProjectData(response);
    });
  }

  function fetchProjectInfoAsDeveloper() {
    return projectService
      .getProjectByIdAndBupaId(projectId, authService.getBupaId())
      .then((response) => {
        setProjectData(response);
        response?.data?.projectName &&
          fetchprojectHistoryAndReleaseAndStoreInfo();
      })
      .catch((err) => {
        if ([401, 403].includes(err.response?.status)) {
          setIsAccessDenied(true);
          toast(
            <CcvMessage
              type="error"
              text={t("TOAST_ACCESS_DENIED")}
              testId="not_your_project_error"
            />
          );
        } else {
          fetchprojectHistoryAndReleaseAndStoreInfo();
          throw err;
        }
      });
  }

  function setProjectData(responseData) {
    setProject(responseData.data);
    setTitle(responseData.data.projectName);
  }

  async function fetchProjectHistory() {
    return trackPromise(
      projectService.getProjectHistory(projectId).then((response) => {
        setProjectHistory(response.data);
      }),
      "history-area"
    );
  }

  async function fetchCcvStoreInfo() {
    return trackPromise(
      ccvStoreService
        .getCcvStoreInfo(projectId)
        .then((response) => {
          setCcvStoreInfo(response.data);
        })
        .catch((err) => {
          if (err.response.status != 404) {
            throw err;
          }
        }),
      "ccvStoreInfo-area"
    );
  }

  async function fetchRelease() {
    return trackPromise(
      releaseService
        .getLatestReleasesByProjectId(projectId)
        .then((response) => {
          setReleases(response.data.content);
        }),
      "project-area"
    );
  }

  function fetchprojectHistoryAndReleaseAndStoreInfo() {
    SpinnerService.errorSpinner(
      Promise.all([fetchProjectHistory(), fetchRelease(), fetchCcvStoreInfo()]),
      t
    );
  }

  useEffect(() => {
    setTitle(location.state?.detail?.projectName);
    getRoles();
  }, []);

  useEffect(() => {
    roles &&
      trackPromise(
        SpinnerService.errorSpinner(fetchProject(), t),
        "project-area"
      );
  }, [roles]);

  //time based solution until the next release
  function getpaymentEngineTypesMissingMessage() {
    if (project?.paymentEngineTypes?.length === 0) {
      return (
        <div className="empty-block progress-block">
          <CcvMessage text={t("EMTPY_ENGINE_MESSAGE")} type="alert" />
        </div>
      );
    }
  }

  return (
    <div>
      {releases?.length === 0 && (
        <ShowForRole permission="Employee">
          <div className="edit_integration_button">
            <CcvButton
              text={t("EDIT_INTEGRATION_BUTTON")}
              icon="mode_edit"
              type="secondary"
              testId="edit_integration_button"
              onClick={(event) => {
                navigate("/updateProject", {
                  state: {
                    project: project,
                  },
                });
              }}
              size="normal"
              disabled={!project}
            />
          </div>
        </ShowForRole>
      )}
      {project && getpaymentEngineTypesMissingMessage()}
      <Spinner area="project-area" dataIsFetched={project}>
        <GeneralInfoComponent project={project} roles={roles} />
      </Spinner>
      <div className="info multi-side-block gap">
        <div className="left">
          <div className="info-block">
            <CcvHeading
              text={t("PROJECT_LATEST_RELEASE_LABEL")}
              size="small"
              testId="latest_releases_title"
            />
            <Spinner
              area="project-area"
              dataIsFetched={releases}
              accessDenied={isAccessDenied}
            >
              <LatestReleasesInfoComponent
                releases={releases}
                project={project}
              />
            </Spinner>
          </div>
          <div className="info-block">
            <CcvHeading
              text={t("PROJECT_DOCUMENTATION_LABEL")}
              size="small"
              testId="documentation_title"
            />
            <Spinner area="project-area" dataIsFetched={project}>
              {
                <DocumentationComponent
                  project={project}
                  projectService={projectService}
                  translate={t}
                />
              }
            </Spinner>
          </div>
          <div className="info-block history-block">
            <CcvHeading
              text={t("PROJECT_HISTORY_TITLE")}
              size="small"
              testId="documentation_title"
            />
            <Spinner
              area="history-area"
              dataIsFetched={projectHistory}
              accessDenied={isAccessDenied}
            >
              <HistoryInfoComponent
                projectHistory={projectHistory}
                project={project}
              />
            </Spinner>
          </div>
        </div>
        <div className="right">
          <div className="info-block">
            <CcvHeading
              text={t("APPLICATION_INFO_HEADING")}
              size="small"
              testId="application_info_title"
            />
            <Spinner
              area="ccvStoreInfo-area"
              dataIsFetched={ccvStoreInfo}
              accessDenied={isAccessDenied}
            >
              <CcvStoreInfoComponent
                project={project}
                ccvStoreInfo={ccvStoreInfo}
                downloadImagesFromSharepoint={downloadImagesFromSharepoint}
              />
            </Spinner>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectDetailComponent;
