import {
  CcvText,
  CcvButton,
  CcvMessage,
} from "@ccv-oc-myccv/ccv-react-components";
import React, { useState, useContext, useEffect } from "react";
import FormatService from "../../../../services/FormatService";
import { AppContext } from "../../../../config/AppContext";
import SecurityscanFeedbackAccordion from "../../../common/SecurityscanFeedbackAccordion";
import SecurityscanFeedbackResponseArea from "../../../common/SecurityscanFeedbackResponseArea";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import SpinnerService from "../../../../services/SpinnerService";
import { SecurityscanFeedbackYupValidation } from "../../../../yupValidation/SecurityscanFeedbackYupValidation";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ResultLabel from "../../../common/ResultLabel";
import { useNavigate } from "react-router-dom";
import ScannerResult from "../../../common/ScannerResult";

function SecurityScanBody({
  dialogInfo,
  dialogSubtitle,
  translate,
  releaseActiveStatus,
  setDialogActionButtons,
  onDisagree,
  fetchReleaseInfo,
  releaseService,
  securityScanRevisions,
}) {
  const { authService } = useContext(AppContext);
  const navigate = useNavigate();
  const {
    handleSubmit,
    setValue,
    control,
    formState: { isValid },
  } = useForm({
    resolver: yupResolver(SecurityscanFeedbackYupValidation()),
  });

  const [isResponseAreaOpen, setIsResponseAreaOpen] = useState(false);

  const { promiseInProgress } = usePromiseTracker({
    area: "updatingFeedbackResponse",
    delay: 0,
  });

  function onSubmit(data) {
    setIsResponseAreaOpen(false);
    trackPromise(
      SpinnerService.defaultSpinner(
        updateFeedbackResponse(data),
        translate,
        "TOAST_PROCESSING",
        "TOAST_RELEASE_UPDATED"
      ),
      "updatingFeedbackResponse"
    );
  }

  function updateFeedbackResponse(data) {
    return releaseService
      ?.patchFeedbackResponse(
        dialogInfo?.releaseId,
        dialogInfo?.securityscan?.feedback[
          dialogInfo?.securityscan?.feedback?.length - 1
        ].id,
        getFeedbackResponseFormData(data)
      )
      .then(
        dialogInfo?.afterUpdateResponseFunction
          ? dialogInfo.afterUpdateResponseFunction
          : () => {
              onDisagree && onDisagree();
              fetchReleaseInfo();
            }
      );
  }

  function getFeedbackResponseFormData(data) {
    return {
      response: data?.feedbackResponse,
      email: authService.getEmail(),
      date: Date.now(),
    };
  }

  function isSecurityscanOverrulable() {
    return (
      securityScanRevisions[0]?.status === "SECURITYSCAN_FAILED" &&
      securityScanRevisions[1]?.status !== "SECURITYSCAN_MORE_INFO" &&
      securityScanRevisions[1]?.status !== "SECURITYSCAN_FAILED"
    );
  }

  useEffect(() => {
    setButtonsForDialog();
  }, [isResponseAreaOpen, isValid, promiseInProgress]);

  function areSomePermissionsIncomplete() {
    return dialogInfo?.permissions?.some((permission) => permission.incomplete);
  }

  function setButtonsForDialog() {
    if (["SECURITYSCAN_MORE_INFO"]?.includes(releaseActiveStatus)) {
      return handleButtonsForStatusMoreInfo();
    }
    if (
      ["SECURITYSCAN_FAILED"]?.includes(releaseActiveStatus) &&
      !hasLastFeedbackResponse()
    ) {
      return handleButtonsForStatusFailed();
    }
  }

  function handleButtonsForStatusMoreInfo() {
    if (areSomePermissionsIncomplete()) {
      return setDialogActionButtons(
        <>
          <CcvButton
            size="normal"
            type="tertiary"
            text={translate("CANCEL_LABEL")}
            onClick={() => {
              setIsResponseAreaOpen(false);
              onDisagree();
            }}
          />
          <CcvButton
            text={translate("GO_TO_PERMISSIONS_BUTTON")}
            testId="go_to_permissions_button"
            type="primary"
            onClick={() =>
              navigate(
                "/release/detail/" + dialogInfo.releaseId + "/permissions"
              )
            }
          />
        </>
      );
    }
    if (!hasLastFeedbackResponse()) {
      return setDialogActionButtons(
        <>
          {onDisagree && (
            <CcvButton
              size="normal"
              type="tertiary"
              text={translate("CANCEL_LABEL")}
              onClick={() => {
                setIsResponseAreaOpen(false);
                onDisagree();
              }}
            />
          )}
          <CcvButton
            size="normal"
            type="primary"
            text={translate("SEND_BUTTON_LABEL")}
            testId="send_button"
            disabled={promiseInProgress || !isValid}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      );
    }
    setDialogActionButtons(
      <CcvButton
        size="normal"
        type="primary"
        text="Ok"
        disabled={promiseInProgress}
        onClick={onDisagree}
      />
    );
  }

  function handleButtonsForStatusFailed() {
    setDialogActionButtons(
      !isResponseAreaOpen ? (
        <>
          {isSecurityscanOverrulable() && (
            <CcvButton
              icon="add"
              size="normal"
              type="tertiary"
              text={translate("ASK_MORE_INFO_BUTTON_LABEL")}
              testId="ask_more_info_button"
              onClick={() => setIsResponseAreaOpen(true)}
            />
          )}
          <CcvButton
            size="normal"
            type="primary"
            text="Ok"
            disabled={promiseInProgress}
            onClick={onDisagree}
          />
        </>
      ) : (
        <>
          <CcvButton
            size="normal"
            type="tertiary"
            text={translate("CANCEL_LABEL")}
            onClick={() => setIsResponseAreaOpen(false)}
          />
          <CcvButton
            size="normal"
            type="primary"
            text={translate("SEND_BUTTON_LABEL")}
            testId="send_button"
            disabled={promiseInProgress || !isValid}
            onClick={handleSubmit(onSubmit)}
          />
        </>
      )
    );
  }

  function isOnlyOneQuestion() {
    return (
      dialogInfo?.securityscan?.feedback?.length === 1 &&
      !dialogInfo?.securityscan?.feedback[0]?.response
    );
  }

  function hasLastFeedbackResponse() {
    return dialogInfo?.securityscan?.feedback[
      dialogInfo?.securityscan?.feedback?.length - 1
    ]?.response?.answer;
  }

  function isLastItem(item) {
    return (
      dialogInfo?.securityscan?.feedback?.[
        dialogInfo?.securityscan?.feedback.length - 1
      ] === item
    );
  }

  function getLabelInfoStatusAfterFeedback() {
    return securityScanRevisions && getRevisionStatus();
  }

  function getRevisionStatus() {
    if (
      securityScanRevisions[0]?.status === "SECURITYSCAN_FAILED" &&
      !isSecurityscanOverrulable()
    ) {
      return "failed";
    } else if (securityScanRevisions[0]?.status === "SECURITYSCAN_SUCCESS") {
      return "success";
    }
  }

  function getSecurityScanStatusAfterFeedback(statusLabel) {
    if (statusLabel) {
      return (
        <div
          className="status-after-feedback"
          data-testId={"status_after_feedback"}
        >
          <CcvText textStyle="bold">
            {translate("SECURITYSCAN_STATUS_AFTER_FEEDBACK")}
          </CcvText>
          <ResultLabel
            status={statusLabel}
            translatedText={translate(`${statusLabel?.toUpperCase()}_TEXT`)}
          />
        </div>
      );
    }
  }

  function getDefaultFeedbackBody(item) {
    return (
      <div className="feedback-container">
        <div className="feedback-heading">
          <CcvText
            size="small"
            color="dark-grey"
            textStyle="bold"
            testId={dialogSubtitle?.toLowerCase() + "_security_scan"}
          >
            {dialogInfo?.releaseStatus?.includes("SECURITYSCAN_MORE_INFO")
              ? translate("DIALOG_FEEDBACK")
              : translate(dialogSubtitle)}
          </CcvText>
          <CcvText size="small" color="medium-grey">
            {FormatService?.getDateWithTimeFormat(item.question.date)}
          </CcvText>
        </div>
        <CcvText
          size="medium"
          color={dialogInfo?.securityscan ? "dark-grey" : "medium-grey"}
          testId="feedback_container"
        >
          {item?.question ? (
            <div
              className="spacing-bottom"
              dangerouslySetInnerHTML={{
                __html: FormatService.getHTMLFormat(item?.question?.question),
              }}
            />
          ) : (
            translate("NO_FEEDBACK_TEXT")
          )}
        </CcvText>
      </div>
    );
  }

  return (
    <div className="securityscan-info-body">
      {dialogInfo?.updateCompletedText && (
        <div className="permissions-updated-label">
          <CcvMessage
            type="success"
            text={translate(dialogInfo?.updateCompletedText)}
            testId={dialogInfo?.updateCompletedText.toLowerCase()}
          />
        </div>
      )}
      <div className="full-feedback-accordion-container">
        {dialogInfo?.securityscan?.feedback?.map((item) => (
          <>
            {isOnlyOneQuestion() ? (
              getDefaultFeedbackBody(item)
            ) : (
              <SecurityscanFeedbackAccordion
                item={item}
                dialogSubtitle={dialogSubtitle}
                translate={translate}
                FormatService={FormatService}
                isLastItem={isLastItem(item)}
              />
            )}
          </>
        ))}
      </div>

      {!areSomePermissionsIncomplete() && (
        <SecurityscanFeedbackResponseArea
          hasLastFeedbackResponse={hasLastFeedbackResponse()}
          releaseActiveStatus={releaseActiveStatus}
          translate={translate}
          setValue={setValue}
          control={control}
          isResponseAreaOpen={isResponseAreaOpen}
        />
      )}

      {!isOnlyOneQuestion() &&
        getSecurityScanStatusAfterFeedback(getLabelInfoStatusAfterFeedback())}
    </div>
  );
}

export default SecurityScanBody;
