const BasicValidationChecklist = [
  { validationText: "CHECK_PACKAGE_NAME" },
  { validationText: "CHECK_VERSIONNAME" },
  { validationText: "CHECK_VERSIONCODE" },
  { validationText: "CHECK_SIGNATURE_VERSION" },
  { validationText: "CHECK_DEBUG_MANIFEST" },
  { validationText: "CHECK_DEBUG" },
  { validationText: "CHECK_PERMISSIONS" },
  { validationText: "CHECK_APK_SIZE" },
];

export default BasicValidationChecklist;
