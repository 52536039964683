import { CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";
import ResultLabel from "./ResultLabel";

function ValidationTable({ validations, translate }) {
  return (
    <div className="validation-checklist">
      {validations?.map((validation) => (
        <span
          className="checklist-row"
          data-testid={validation.validationText?.toLowerCase()}
        >
          <CcvText
            size="medium"
            textStyle="semi-bold"
            testId={validation.validationText?.toLowerCase()}
          >
            {translate(validation.validationText)}
          </CcvText>
          <ResultLabel
            status={"success"}
            translatedText={translate("OK")}
            testId={validation?.validationText?.toLowerCase()}
          />
        </span>
      ))}
    </div>
  );
}

export default ValidationTable;
