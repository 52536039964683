import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import HomeComponent from "../pages/home/HomeComponent";
import CreateProjectComponent from "../pages/project/CreateProjectComponent";
import ProjectDetailComponent from "../pages/project/ProjectDetailComponent";
import { AppContext } from "./AppContext";
import CreateReleaseComponent from "../pages/release/CreateReleasesComponent";
import AllReleasesComponent from "../pages/release/AllReleasesComponent";
import ReleaseDetailComponent from "../pages/release/ReleaseDetailComponent";
import FullHistoryComponent from "../pages/project/FullHistoryComponent";
import { AuthService } from "../services/AuthService";
import PostLogin from "../pages/login/PostLogin";
import PostRenew from "../pages/login/PostRenew";
import OverviewActions from "../pages/project/ActionsOverviewComponent";
import Sidebar from "../pages/layout/Sidebar";
import Header from "../pages/layout/Header";
import { ShowForRole } from "../components/ShowForRole";
import TestCatalogueComponent from "../pages/release/TestCatalogueComponent";
import { ScrollToTop } from "./ScrollToTop";
import TracebillityComponent from "../pages/tracebillity/TracebillityComponent";
import SecurityScanComponent from "../pages/release/statusUpdateComponents/SecurityScanComponent";
import HandleLogout from "../pages/login/HandleLogout";
import PermissionListComponent from "../pages/release/statusUpdateComponents/PermissionListComponent";
import BasicValidationComponent from "../pages/release/statusUpdateComponents/BasicValidationComponent";
import AuthorizedView from "./AuthorizedView";

function RouterNav(props) {
  const [title, setTitle] = useState("");
  const [projectInfo, setProjectInfo] = useState();
  const [incompletePermissionList, setIncompletePermissionList] = useState([]);
  const [authService, setAuthService] = useState(new AuthService());
  return (
    <AppContext.Provider
      value={{ title, setTitle, projectInfo, setProjectInfo, authService }}
    >
      <ScrollToTop>
        <div className="webcontent">
          <Header />
          <AuthorizedView authService={authService} noEmptyBlock={true}>
            <Sidebar />
          </AuthorizedView>
          <div className="main">
            <Routes>
              {" "}
              <Route exact path="/postlogin" element={<PostLogin />} />
              <Route path="/postrenew" element={<PostRenew />} />
              <Route path="/handlelogout" element={<HandleLogout />} />
            </Routes>
            <AuthorizedView authService={authService}>
              <Routes>
                <Route
                  exact
                  path="/home"
                  element={<HomeComponent {...props} title="MENU_PROJECTS" />}
                />
                <Route exact path="/" element={<Navigate to="/home" />} />
                <Route
                  exact
                  path="/createProject"
                  element={
                    <ShowForRole permission="Developer" redirect="/home">
                      <CreateProjectComponent
                        {...props}
                        title="PROJECT_ADD_TITLE"
                      />
                    </ShowForRole>
                  }
                />
                <Route
                  exact
                  path="/updateProject"
                  element={
                    <ShowForRole permission="Employee" redirect="/actions">
                      <CreateProjectComponent
                        {...props}
                        title="PROJECT_UPDATE_TITLE"
                      />
                    </ShowForRole>
                  }
                />
                <Route
                  exact
                  path="/project/detail/:id"
                  element={<ProjectDetailComponent {...props} />}
                />
                <Route
                  exact
                  path="/release/createRelease"
                  element={
                    <ShowForRole permission="Developer" redirect="/home">
                      <CreateReleaseComponent title="CREATE_RELEASE_TITLE" />
                    </ShowForRole>
                  }
                />
                <Route
                  exact
                  path="/release/allreleases"
                  element={
                    <AllReleasesComponent {...props} title="RELEASES_TITLE" />
                  }
                />
                <Route
                  exact
                  path="/release/detail/:id"
                  element={
                    <ReleaseDetailComponent
                      {...props}
                      setIncompletePermissionList={setIncompletePermissionList}
                      title="RELEASE_DETAIL_TITLE"
                    />
                  }
                />
                <Route
                  exact
                  path="/release/detail/security-checklist"
                  element={
                    <BasicValidationComponent
                      {...props}
                      title="SECURITY_CHECKLIST_TITLE"
                    />
                  }
                />
                <Route
                  exact
                  path="/release/detail/:id/permissions"
                  element={
                    <PermissionListComponent
                      {...props}
                      title="NO_PERMISSIONS_INFO_HEADING"
                      setIncompletePermissionList={setIncompletePermissionList}
                      incompletePermissionList={incompletePermissionList}
                    />
                  }
                />
                <Route
                  exact
                  path="/release/detail/securityscan"
                  element={
                    <ShowForRole permission="Employee" redirect="/home">
                      <SecurityScanComponent
                        {...props}
                        incompletePermissionList={incompletePermissionList}
                        title="ACTIONS_ACTION_SCAN_TITLE"
                        setIncompletePermissionList={
                          setIncompletePermissionList
                        }
                      />
                    </ShowForRole>
                  }
                />
                <Route
                  exact
                  path="/project/history"
                  element={
                    <FullHistoryComponent
                      {...props}
                      title="PROJECT_HISTORY_TITLE"
                    />
                  }
                />
                <Route
                  exact
                  path="/actions"
                  element={
                    <ShowForRole permission="Employee" redirect="/home">
                      <OverviewActions {...props} title="MENU_ACTIONS" />
                    </ShowForRole>
                  }
                />
                <Route
                  exact
                  path="/release/detail/testcatalogue"
                  element={
                    <TestCatalogueComponent
                      {...props}
                      title="REQUIREMENT_TESTS_TITLE"
                    />
                  }
                />
                <Route
                  exact
                  path="/traceability"
                  element={
                    <ShowForRole permission="Employee" redirect="/home">
                      <TracebillityComponent
                        {...props}
                        title="TRACEABILITY_TITLE"
                      />
                    </ShowForRole>
                  }
                />
              </Routes>
            </AuthorizedView>
          </div>
        </div>
      </ScrollToTop>
    </AppContext.Provider>
  );
}

export default RouterNav;
