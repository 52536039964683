import { CcvMessage, CcvText } from "@ccv-oc-myccv/ccv-react-components";
import React from "react";
import { useTranslation } from "react-i18next";
import EmptyIconBlock from "../../../components/emptyBlocks/EmptyIconBlock";
import Textarea from "../../../components/form/Textarea";
import ButtonWithIcons from "../../../components/common/button/ButtonWithIcons";

function CcvStoreInfoComponent({
  ccvStoreInfo,
  project,
  downloadImagesFromSharepoint,
}) {
  const [t] = useTranslation();

  function getEmptyBlock() {
    if (project) {
      return (
        <div className="detail-spacing">
          <CcvMessage
            type="info"
            text={t("EMPTY_APPLICATION_INFO")}
            testId="no_application_info_text"
          />
        </div>
      );
    } else {
      return (
        <EmptyIconBlock emptyText="APPLICATION_INFO_EMPTY" translate={t} />
      );
    }
  }

  return ccvStoreInfo ? (
    <div className="one-side-block">
      <div className="left">
        <div className="detail-spacing">
          <CcvText size="medium" color="dark-grey">
            {t("APPLICATION_NAME_LABEL")}
          </CcvText>
          <CcvText
            size="medium"
            color="dark-grey"
            textStyle="semi-bold"
            testId="applicationName"
          >
            {ccvStoreInfo.applicationName}
          </CcvText>
        </div>
        <div className="detail-spacing">
          <CcvText size="medium" color="dark-grey">
            {t("APP_ICON_LABEL")}
          </CcvText>
          <img
            data-testid="appIcon"
            src={ccvStoreInfo.appIcon[0]?.preview}
            alt="app icon"
            className="accepted-app-icon image-spacing"
          />
          <ButtonWithIcons
            iconFirst={true}
            iconNames={[
              {
                iconName: "file_download",
                onClick: () =>
                  downloadImagesFromSharepoint("APPICON", ccvStoreInfo.appIcon),
              },
            ]}
            iconClassName="icon-blue"
            buttonText={"DOWNLOAD_APPICON_TEXT"}
            textClassName="download-link"
            translate={t}
          />
        </div>
        <div className="detail-spacing">
          <CcvText size="medium" color="dark-grey">
            {t("SCREENSHOTS_LABEL")}
          </CcvText>
          <div className="screenshots-detail image-spacing">
            {ccvStoreInfo.screenshots?.map((screenshot) => {
              return (
                <img
                  data-testid="screenshots"
                  src={screenshot.preview}
                  alt="app screenshots"
                  className="accepted-screenshots"
                />
              );
            })}
          </div>
          <ButtonWithIcons
            iconFirst={true}
            iconNames={[
              {
                iconName: "file_download",
                onClick: () =>
                  downloadImagesFromSharepoint(
                    "SCREENSHOTS",
                    ccvStoreInfo.screenshots
                  ),
              },
            ]}
            iconClassName="icon-blue"
            buttonText={"DOWNLOAD_SCREENSHOTS_LABEL"}
            textClassName="download-link"
            translate={t}
          />
        </div>
      </div>
      <div className="right">
        <div className="detail-spacing">
          <Textarea
            translate={t}
            labelText={"SHORT_DESCRIPTION_LABEL"}
            defaultInputData={ccvStoreInfo.shortDescription}
            disabled={true}
            className={"textarea-extra-small"}
            testId={"short_description"}
          />
        </div>
        <div className="detail-spacing">
          <Textarea
            translate={t}
            labelText={"DESCRIPTION_LABEL"}
            defaultInputData={ccvStoreInfo.description}
            disabled={true}
            className={"textarea-medium"}
            testId={"description"}
          />
        </div>
      </div>
    </div>
  ) : (
    getEmptyBlock()
  );
}

export default CcvStoreInfoComponent;
