import { CcvHeading, CcvTextInput } from "@ccv-oc-myccv/ccv-react-components";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import TextInput from "../../../../components/form/TextInput";
import ValidationError from "../../../../components/form/ValidationError";
import FormValidation from "../../../../domain/FormValidation";
import PaymentEngine from "../../../../domain/PaymentEngine";
import SelectorDefault from "../../../../domain/SelectorDefault";
import IntegrationType from "../../../../domain/IntegrationType";
import Textarea from "../../../../components/form/Textarea";
import { useTranslation } from "react-i18next";
import Selector from "../../../../components/form/Selector";
import TooltipText from "../../../../domain/TooltipText";

function ReleaseFormComponent({
  clearErrors,
  integrationType,
  errors,
  control,
  setValue,
  paymentEngineTypes,
  apkVersionName,
}) {
  const [t] = useTranslation();
  const [paymentEngineVersions, setPaymentEngineVersions] = useState(
    paymentEngineTypes.map((paymentEngine) => {
      return {
        name: paymentEngine,
        version: "NOT_FILLED_IN",
        isOtherOptionSelected: false,
      };
    })
  );
  const [mapiVersion, setMapiVersion] = useState(
    integrationType !== "MAPI" ? SelectorDefault.notUsed[0].id : "NOT_FILLED_IN"
  );

  function setEngineVersion(paymentEngine, version, isOtherOptionSelected) {
    setPaymentEngineVersions(
      paymentEngineVersions.map((engine) => {
        if (engine.name === paymentEngine) {
          engine.version = version;
          engine.isOtherOptionSelected = isOtherOptionSelected;
        }
        return engine;
      })
    );
  }

  useEffect(() => {
    setValue("paymentEngineVersions", paymentEngineVersions);
    setValue(
      "mapiVersion",
      mapiVersion === "OTHER" ? "NOT_FILLED_IN" : mapiVersion
    );
  }, [paymentEngineVersions, mapiVersion]);

  return (
    <div className="block">
      <div className="multi-side-block gap">
        <div className="left">
          <CcvHeading
            color="blue"
            text={t("RELEASE_DETAILS_TEXT")}
            size="small"
          />
          <TextInput
            shouldValidateOnInput={false}
            field="version"
            setValue={setValue}
            label="RELEASE_NAME_LABEL"
            disabled={true}
            placeholder={
              apkVersionName ? apkVersionName : t("VERSION_PLACEHOLDER")
            }
          />

          {paymentEngineTypes.map((paymentEngine, id) => {
            return (
              <div className="form-input-spacing">
                <Selector
                  testId={paymentEngine?.toLowerCase()}
                  registerName={"paymentEngineVersions"}
                  options={PaymentEngine[paymentEngine].versions}
                  initialSelectedItem={SelectorDefault.default[0]}
                  isOtherOptionSelected={
                    paymentEngineVersions[id].isOtherOptionSelected
                  }
                  tooltipInfo={TooltipText.PAYMENT_ENGINE_VERSION}
                  labelText={
                    PaymentEngine[paymentEngine].description +
                    " " +
                    t("PAYMENT_ENGINE_VERSION_LABEL")
                  }
                  setCustomSelectedResult={setEngineVersion}
                  setCustomKey={paymentEngine}
                  control={control}
                  maxLength={FormValidation.MAX_ENGINE_VERSION_LENGTH}
                  translate={t}
                  setInputResult={(input) => {
                    setEngineVersion(
                      paymentEngine,
                      input.target.value
                        ? input.target.value + "_OTHER"
                        : "NOT_FILLED_IN",
                      true
                    );
                  }}
                />
              </div>
            );
          })}

          {IntegrationType[integrationType] == IntegrationType.MAPI && (
            <div className="form-input-spacing">
              <Selector
                registerName={"mapiVersion"}
                options={IntegrationType.MAPI.versions}
                initialSelectedItem={
                  IntegrationType[integrationType] != IntegrationType.MAPI
                    ? SelectorDefault.notUsed[0]
                    : SelectorDefault.default[0]
                }
                tooltipInfo={TooltipText.MAPI_VERSION}
                isOtherOptionSelected={mapiVersion === "OTHER"}
                labelText={"MAPI_VERSION_LABEL"}
                setSelectedResult={setMapiVersion}
                control={control}
                maxLength={FormValidation.MAX_MAPI_LENGTH}
                translate={t}
                testId={"mapi_version_version_select"}
              />
            </div>
          )}

          <Textarea
            className={"textarea-medium"}
            translate={t}
            labelText={"RELEASE_NOTES_LABEL"}
            tooltip={TooltipText.RELEASENOTES}
            maxLength={FormValidation.MAX_RELEASENOTES_LENGTH}
            control={control}
            field={"releaseNotes"}
            required={true}
            setValue={setValue}
            clearErrors={clearErrors}
            shouldValidateOnInput={false}
            validationError={
              <ValidationError
                error={errors["releaseNotes"]}
                errorText={t("RELEASE_INFO_TEXT_ADD_RELEASE_NOTES")}
                testId="release_notes_error"
              />
            }
          />
        </div>
        <div className="right">
          <CcvHeading color="blue" text={t("APK_OWNER_HEADING")} size="small" />
          <TextInput
            shouldValidateOnInput={false}
            clearErrors={clearErrors}
            field="ownerName"
            setValue={setValue}
            maxLength={FormValidation.MAX_APK_OWNER_LENGTH}
            label="APK_OWNER_LABEL"
            required={true}
            validationError={
              <ValidationError
                error={errors["ownerName"]}
                errorText={t("APK_OWNER_REQUIRED")}
                testId="owner_name_error"
              />
            }
          />
          <TextInput
            shouldValidateOnInput={false}
            field="ownerRole"
            setValue={setValue}
            maxLength={FormValidation.MAX_APK_OWNER_POSITION_LENGTH}
            label="APK_OWNER_POSITION_LABEL"
          />
        </div>
      </div>
    </div>
  );
}

export default ReleaseFormComponent;
