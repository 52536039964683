import IntegrationType from "./IntegrationType";

const IntegrationCategory = {
  CLOUD: {
    description: "Cloud integration",
  },
  LOCAL: {
    description: "Local integration",
    integrationType: {
      PI_REST_API: IntegrationType.PI_REST_API,
      MAPI: IntegrationType.MAPI,
      OPI: IntegrationType.OPI,
      TWO_STEP: IntegrationType.TWO_STEP,
      OTHER: IntegrationType.OTHER,
    },
  },
};

export default IntegrationCategory;
