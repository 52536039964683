import React from "react";
import { Controller } from "react-hook-form";
import RadioButtonList from "../../form/RadioButtonList";
import ValidationError from "../../form/ValidationError";

function RadioButtonSection({
  sectionInfo,
  control,
  translate,
  errors,
  setValue,
  initialSelectedValue,
}) {
  function getInitialValue() {
    if (Object.values(sectionInfo.options)[0].value != null) {
      let optionKey = Object.keys(sectionInfo.options).find(
        (key) =>
          sectionInfo.options[key].value ===
          initialSelectedValue[sectionInfo.registerName]
      );
      setSectionValue(optionKey);
      return optionKey;
    }
    setSectionValue(initialSelectedValue[sectionInfo.registerName]);
    return initialSelectedValue[sectionInfo.registerName];
  }

  function setSectionValue(item) {
    setValue([item]);
  }

  return (
    <div>
      <Controller
        control={control}
        name={sectionInfo.registerName}
        render={({ field: { onChange } }) => (
          <RadioButtonList
            direction="column"
            register={onChange}
            name={sectionInfo.registerName}
            options={sectionInfo.options}
            setValue={setSectionValue}
            initialSelectedValue={() =>
              initialSelectedValue && getInitialValue()
            }
          />
        )}
      />
      <ValidationError
        error={errors[sectionInfo.registerName]}
        errorText={translate("PROJECT_INFO_TEXT_CHOOSE_OPTION")}
        testId={sectionInfo?.registerName?.toLowerCase() + "_error"}
      />
    </div>
  );
}

export default RadioButtonSection;
