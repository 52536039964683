import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { StepConnector } from "@mui/material";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  stepIconClasses,
  stepLabelClasses,
  stepConnectorClasses,
} from "@mui/material";
import LoadingBlock from "../Loading/LoadingBlock";

function StepIndicator({ possibleSteps, statusPool, currentStatus }) {
  const [steps, setSteps] = useState(null);
  const [waitingForSteps, setWaitingForSteps] = useState(true);
  const [t] = useTranslation();

  function getSteps() {
    setSteps(possibleSteps);
    setWaitingForSteps(false);
  }

  function setStatusLayout(label) {
    const labelProps = {};
    if (
      currentStatus?.includes("FAILED") &&
      statusPool[label].stepKey === statusPool[currentStatus].stepKey
    ) {
      labelProps.error = true;
    }
    return labelProps;
  }

  useEffect(() => {
    setWaitingForSteps(true);
    getSteps();
  }, [i18next.language]);

  const theme = createTheme({
    components: {
      MuiStepLabel: {
        styleOverrides: {
          label: {
            [`&.${stepLabelClasses.label}`]: {
              fontSize: "1.5vh",
              fontFamily: "var(--base-font-family)",
            },
            [`&.${stepIconClasses.active}`]: {
              color: currentStatus?.includes("MORE_INFO")
                ? "var(--warning-medium)"
                : "var(--primary-medium)",
            },
            [`&.${stepIconClasses.completed}`]: {
              color: "var(--primary-light)",
            },
            [`&.${stepIconClasses.error}`]: {
              color: "var(--danger-medium)",
            },
            [`&.${stepIconClasses.root}`]: {
              color: "var(--grey-light-150)",
            },
          },
        },
      },
      MuiStepIcon: {
        styleOverrides: {
          root: {
            [`&.${stepIconClasses.root}`]: {
              color: "var(--grey-light-150)",
              height: "3.5vh",
              width: "3.5vh",
              zIndex: "1",
              marginTop: "1vh",
            },
            [`&.${stepIconClasses.completed}`]: {
              color: "var(--primary-light)",
            },
            [`&.${stepIconClasses.active}`]: {
              color: currentStatus?.includes("MORE_INFO")
                ? "var(--warning-medium)"
                : "var(--primary-medium)",
              height: "4vh",
              width: "4vh",
              padding: "0.2vh",
              border: currentStatus?.includes("MORE_INFO")
                ? "0.2vh solid var(--warning-medium)"
                : "0.2vh solid var(--primary-medium)",
              borderRadius: "100%",
              marginTop: "0.5vh",
            },
            [`&.${stepIconClasses.error}`]: {
              color: "var(--danger-medium)",
              border: "none",
            },
          },
          text: {
            [`&.${stepIconClasses.text}`]: {
              fontSize: "var(--base-font-size)",
              fontFamily: "var(--base-font-family)",
            },
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          root: {
            [`&.${stepConnectorClasses.root}`]: {
              color: "var(--grey-medium-400)",
              marginTop: "1vh",
              fontFamily: "var(--base-font-family)",
            },
            [`&.${stepConnectorClasses.completed}`]: {
              color: "var(--primary-light)",
            },
            [`&.${stepConnectorClasses.active}`]: {
              color:currentStatus?.includes("MORE_INFO")
                ? "var(--warning-medium)"
                : "var(--primary-medium)",
            },
            [`&.${stepConnectorClasses.error}`]: {
              color: "var(--danger-medium)",
            },
          },
        },
      },
    },
  });

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      left: "calc(-50% + 10px)",
      right: "calc(50% + 10px)",
      top: "1.5vh",
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderTopWidth: 3,
      borderRadius: 1,
      border: "solid 0.1vh var(--grey-light-150)",
      zIndex: "-1",
    },
  }));

  return waitingForSteps ? (
    <LoadingBlock />
  ) : (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%" }}>
        <Stepper
          activeStep={statusPool[currentStatus].activeStep}
          connector={<QontoConnector />}
          alternativeLabel
        >
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel {...setStatusLayout(label)}>
                  {t(statusPool[label]?.stepName)}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </ThemeProvider>
  );
}

export default StepIndicator;
